var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    staticClass: "volunteers-report",
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-card", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" Configuration ")]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "volunteers-report__filters" },
                        [
                          _c("qs-relation-field", {
                            attrs: {
                              item: _vm.filtersItem,
                              label: "Instances",
                              multiple: "",
                              name: "instances",
                              store: _vm.relationDefinition
                            },
                            on: {
                              input: function($event) {
                                _vm.filtersItem.instances = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mb-3" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.selectAllInstances }
                          },
                          [_vm._v("Tout sélectionner")]
                        ),
                        _vm._v(" – "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.unselectAllInstances }
                          },
                          [_vm._v("Ne rien sélectionner")]
                        )
                      ]),
                      _c("qs-date-range-field", {
                        attrs: { name: "dates" },
                        model: {
                          value: _vm.filtersItem.dates,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersItem, "dates", $$v)
                          },
                          expression: "filtersItem.dates"
                        }
                      }),
                      _c("qs-toggle-field", {
                        attrs: { label: "Avec détails" },
                        model: {
                          value: _vm.details,
                          callback: function($$v) {
                            _vm.details = $$v
                          },
                          expression: "details"
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "qs-button",
                        {
                          attrs: {
                            disabled: !_vm.filterValid,
                            loading: _vm.loading,
                            theme: "labase"
                          },
                          on: { click: _vm.generateReport }
                        },
                        [_vm._v("Générer")]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _c("qs-card", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(" Filtres "),
                      _c("v-spacer"),
                      _c(
                        "qs-button",
                        {
                          attrs: {
                            disabled: _vm.filtersItem.instances.length === 0,
                            small: "",
                            theme: "labase"
                          },
                          on: { click: _vm.showFilterNameAndFocusInput }
                        },
                        [_c("v-icon", [_vm._v("mdi-content-save")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c(
                        "v-list",
                        [
                          _vm.showFilterName
                            ? _c(
                                "v-list-item",
                                [
                                  _c("qs-text-field", {
                                    ref: "filterNameInput",
                                    attrs: {
                                      label: "Nom du filtre",
                                      name: "filterName"
                                    },
                                    on: {
                                      keydown: _vm.saveCurrentFilterIfEnter
                                    },
                                    model: {
                                      value: _vm.filterName,
                                      callback: function($$v) {
                                        _vm.filterName = $$v
                                      },
                                      expression: "filterName"
                                    }
                                  }),
                                  _c("qs-button", {
                                    attrs: {
                                      color: "qs-green",
                                      disabled: _vm.filterName.length === 0,
                                      icon: "mdi-check",
                                      theme: "labase"
                                    },
                                    on: { click: _vm.saveCurrentFilter }
                                  }),
                                  _c("qs-button", {
                                    attrs: {
                                      icon: "mdi-close",
                                      theme: "labase"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showFilterName = false
                                        _vm.filterName = ""
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.filtersLoaded
                            ? _vm._l(_vm.filters, function(filter) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: filter.id,
                                    on: {
                                      click: function($event) {
                                        return _vm.setFilter(filter)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(filter.name)
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              })
                            : _c(
                                "v-list-item",
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary"
                                    }
                                  })
                                ],
                                1
                              )
                        ],
                        2
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-card", {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("h5", [
                        _vm._v(
                          "Intentions de participation aux événements par jour par instance"
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _vm.data
                        ? _c(
                            "div",
                            [
                              _c("qs-data-table", {
                                attrs: {
                                  headers: _vm.headers,
                                  tile: "",
                                  items: _vm.datesData,
                                  loading: _vm.loading,
                                  options: {
                                    page: 1,
                                    itemsPerPage: -1
                                  },
                                  total: _vm.datesData.length
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("v-alert", { attrs: { info: "" } }, [
                                _vm._v(
                                  "Configurez et générez le rapport pour afficher des données."
                                )
                              ])
                            ],
                            1
                          )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }