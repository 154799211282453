var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "instance-meta-2022-card" },
    [
      _c("v-card-title", { staticClass: "text-h3 font-weight-bold" }, [
        _vm._v(" Paramètres spéciaux ")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-expansion-panels",
            { attrs: { flat: "", accordion: "" } },
            _vm._l(_vm.forms, function(ref, i) {
              var header = ref.header
              var form = ref.form
              return _c(
                "v-expansion-panel",
                { key: "panel-" + i },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" " + _vm._s(header) + " ")
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("qs-form-builder", {
                        attrs: { form: form, item: _vm.meta },
                        on: {
                          input: function($event) {
                            return _vm.$emit("input", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }