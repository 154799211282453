


























































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsCopyToClipboard from 'qs_vuetify/src/components/QsCopyToClipboard.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import {
  InstanceRole,
} from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import UsersSettingsDialog from '@/components/Dialog/UsersSettingsDialog.vue';

const instanceRoles: any = namespace('instance_roles');
const view: any = namespace('instancesView');

@Component({
  components: {
    QsBooleanIndicator,
    QsCopyToClipboard,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    UsersSettingsDialog,
  },
  head: {
    title(this: InstanceRolesList) {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (this.section) {
        const section = this.$t(`sections.${this.section}`);
        inner = `${section} | ${inner}`;
      } else if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceRolesList extends mixins(
  DataRouteGuards,
  ExportMixin,
  ListMixin,
  NavigationMixin,
) {
  @Prop({ type: String, required: false }) section!: string | null;

  @instanceRoles.Getter('data') instanceRoles!: Array<InstanceRole>;
  @instanceRoles.Getter exportUrl!: string;
  @instanceRoles.Getter('filtersDefinition') instanceRolesFiltersDefinition!: FiltersDefinition;
  @instanceRoles.Getter('loading') instanceRolesLoading!: boolean;
  @instanceRoles.Getter slug!: string;
  @instanceRoles.Getter('total') instanceRolesTotal!: number;

  @view.Getter instanceRolesOptions!: DataTableOptions;
  @view.Getter instanceRolesParams!: RestParams;
  @view.Mutation('instanceRolesOptions') setInstanceRolesOptions!: any;
  @view.Mutation('instanceRolesParams') setInstanceRolesParams!: any;

  setInstanceRolesParam = this.buildSetParam('instance_roles', this.setInstanceRolesParams);

  // eslint-disable-next-line class-methods-use-this
  get actionParams(): RestParams {
    return this.instanceRolesParams;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get tableHeaders(): any[] {
    return [
      { text: 'ID', value: 'id' },
      { text: 'Actif', value: 'is_active', sortable: false },
      this.section ? null : { text: 'Instance', value: 'instance_name' },
      { text: 'Nom', value: 'contact_name' },
      this.section ? null : { text: 'Courriel', value: 'contact.email', sortable: false },
      { text: 'Type', value: 'type_name' },
      {
        text: 'Responsabilités',
        value: 'responsibilities',
        align: 'center',
        sortable: false,
      },
      { text: 'Début', value: 'start_at', align: 'right' },
      { text: 'Fin', value: 'end_at', align: 'right' },
      { text: 'Actions', value: 'actions', sortable: false },
    ].filter((k) => !!k);
  }

  usersSettingsDialogVisible = false;

  get viewParams() {
    return {
      instance_roles: ListMixin.buildListState(
        this.instanceRolesOptions,
        this.instanceRolesParams,
      ),
    };
  }

  mounted() {
    this.$store.commit('instance_roles/exportFields', [
      'instance.name',
      'contact.first_name',
      'contact.last_name',
      'contact.status',
      'contact.v1_contact_id',
      'contact.home_phone',
      'contact.email',
      'instance_role_type.name',
      'responsibilities.name',
    ]);
  }

  async emitArchive(instanceRole: InstanceRole) {
    await this.$store.dispatch('instance_roles/archive', instanceRole.id);

    this.reloadDataRoutesData(['instance_roles.index'], true);
  }

  emitEdit(instanceRole: InstanceRole) {
    if (this.$route.name === 'InstanceRolesList') {
      this.goTo(null, {
        name: 'InstanceRolesDialog',
        params: { id: `${instanceRole.id}` },
      });
    } else {
      this.$emit('edit', instanceRole);
    }
  }

  setActions() {
    this.$store.commit('global/actions', [
      {
        onClick: () => { this.$router.push('roles/new'); },
        color: 'primary',
        disabled: false,
        icon: 'mdi-plus',
      },
      {
        onClick: () => {
          this.usersSettingsDialogVisible = true;
        },
        color: 'info',
        disabled: false,
        icon: 'mdi-cog',
      },
    ]);
  }

  @Watch('routeDataLoaded')
  onInstancesRolesListRouteDataLoadedChanged(loaded: boolean) {
    if (loaded && this.$route.name === 'InstanceRolesList') {
      this.setActions();
    }

    if (loaded && this.instanceRoles.length === 0 && this.instanceRolesOptions.page !== 1) {
      this.setInstanceRolesOptions({
        ...this.instanceRolesOptions,
        page: 1,
      });
    }
  }
}
