



















































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import type { AxiosResponse } from 'axios';

import axios from 'qs_vuetify/src/plugins/axios';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import QsCard from 'qs_vuetify/src/components/QsCard.vue';

import { PersistedInstance, PersistedInstanceEvent } from 'qs_vuetify/src/types/models';

const auth: any = namespace('auth');
const instance_events: any = namespace('instance_events');

@Component({
  components: {
    QsCard,
  },
  filters: {
    icon(name: string): string {
      switch (name) {
        case 'Assemblée générale de fondation':
        case 'Création':
          return 'mdi-plus-circle';
        case 'Dissolution':
        case 'Fermeture':
          return 'mdi-close-circle';
        case 'Reconnaissance d\'Élections Québec':
        case 'Reconnaissance du parti':
          return 'mdi-stamper';
        case 'Suivi':
          return 'mdi-comment';
        default:
          return 'mdi-calendar';
      }
    },
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Home extends mixins(AuthenticationMixin, DataRouteGuards) {
  @auth.Getter loading!: boolean;
  @instance_events.Getter('data') instance_events!: PersistedInstanceEvent[];

  lastAgaItems: PersistedInstance[] = [];
  lastAgaLoaded: boolean = false;
  lastAgaLoading: boolean = false;
  lastAgaTotal: number = 0;

  // eslint-disable-next-line class-methods-use-this
  get shortcuts() {
    return [
      {
        name: 'Rapports',
        children: [
          {
            name: 'Militant-es (Élection 2022)',
            href: '/reports/volunteers',
          },
        ],
      },
    ];
  }

  mounted() {
    this.setup();
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(connected: boolean) {
    if (connected) {
      this.setup();
    }
  }

  async setup() {
    if (!this.userIsSuperadmin) {
      return;
    }

    const promises: Promise<AxiosResponse>[] = [];

    this.lastAgaLoading = true;
    try {
      promises.push(axios.get('/instances', {
        params: {
          fields: [
            'active_coordination',
            'active_representant_officiel',
            'instance_type.name',
            'is_active',
            'last_aga_date',
            'name',
            'region.name',
          ].join(','),
          'instance_type.id': '10,11',
          is_active: true,
          last_aga_date: `:${dayjs().subtract(1, 'year').format('YYYY-MM-DD')}`,
          order: 'name',
          per_page: -1,
        },
      }));

      await Promise.all(promises);

      const { data: { data, total } } = await promises[0];

      this.lastAgaItems = [...data];
      this.lastAgaTotal = total;
      this.lastAgaLoaded = true;
    } finally {
      this.lastAgaLoading = false;
    }
  }
}
