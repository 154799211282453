var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", [
    _c("thead", [
      _c("tr", [
        _c(
          "td",
          [
            _c("v-text-field", {
              attrs: {
                "hide-details": "",
                "prepend-inner-icon": "mdi-magnify"
              },
              model: {
                value: _vm.search,
                callback: function($$v) {
                  _vm.search = $$v
                },
                expression: "search"
              }
            })
          ],
          1
        ),
        _c("td", [_c("v-icon", [_vm._v("mdi-asterisk")])], 1),
        _c("td", [_c("v-icon", [_vm._v("mdi-plus-circle")])], 1),
        _c("td", [_c("v-icon", [_vm._v("mdi-eye")])], 1),
        _c("td", [_c("v-icon", [_vm._v("mdi-pencil")])], 1),
        _c("td", [_c("v-icon", [_vm._v("mdi-delete")])], 1)
      ])
    ]),
    _c(
      "tbody",
      [
        _vm._l(_vm.filteredModels, function(rights, key) {
          return _c("tr", { key: key }, [
            _c("td", [
              _vm.$i18n.te("models." + key + ".name")
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("capitalize")(
                            _vm.$i18n.tc("models." + key + ".name", 2)
                          )
                        ) +
                        " "
                    )
                  ])
                : _c("span", [_vm._v(_vm._s(_vm._f("capitalize")(key)))])
            ]),
            _c(
              "td",
              [
                rights.manage
                  ? _c("v-checkbox", {
                      attrs: { "input-value": _vm.hasRight(rights.manage) },
                      on: {
                        change: function($event) {
                          return _vm.onToggleRight($event, rights.manage)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "td",
              [
                rights.create
                  ? _c("v-checkbox", {
                      attrs: { "input-value": _vm.hasRight(rights.create) },
                      on: {
                        change: function($event) {
                          return _vm.onToggleRight($event, rights.create)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "td",
              [
                rights.retrieve
                  ? _c("v-checkbox", {
                      attrs: { "input-value": _vm.hasRight(rights.retrieve) },
                      on: {
                        change: function($event) {
                          return _vm.onToggleRight($event, rights.retrieve)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "td",
              [
                rights.update
                  ? _c("v-checkbox", {
                      attrs: { "input-value": _vm.hasRight(rights.update) },
                      on: {
                        change: function($event) {
                          return _vm.onToggleRight($event, rights.update)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "td",
              [
                rights.delete
                  ? _c("v-checkbox", {
                      attrs: { "input-value": _vm.hasRight(rights.delete) },
                      on: {
                        change: function($event) {
                          return _vm.onToggleRight($event, rights.delete)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        }),
        _vm._l(_vm.otherRights, function(right) {
          return _c("tr", { key: right.id }, [
            _c("td", [_vm._v(" " + _vm._s(right.slug) + " ")]),
            _c(
              "td",
              { attrs: { colspan: "5" } },
              [
                right
                  ? _c("v-checkbox", {
                      attrs: { "input-value": _vm.hasRight(right) },
                      on: {
                        change: function($event) {
                          return _vm.onToggleRight($event, right)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }