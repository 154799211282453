





























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'qs_vuetify/src/plugins/axios';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Contact, District } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParam, RestParams } from 'qs_vuetify/src/types/states';

const contacts: any = namespace('contacts');
const view: any = namespace('instancesView');

@Component({
  components: {
    QsButton,
    QsDataTable,
    QsFilters,
  },
})
export default class InstanceContactsList extends Vue {
  @Prop({ type: [String, Number], required: true }) instanceId!: string | number;

  @contacts.Getter('data') contacts!: Array<Contact>;
  @contacts.Getter('filtersDefinition') contactsFiltersDefinition!: FiltersDefinition;
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;

  @view.Getter contactsOptions!: DataTableOptions;
  @view.Getter contactsParams!: RestParams;
  @view.Mutation('contactsOptions') setContactsOptions!: any;

  contactsHeaders = [
    { text: 'No de membre', value: 'v1_contact_id' },
    { text: 'Nom', value: 'full_name' },
    { text: 'Statut', value: 'status' },
    { text: 'Actions', value: 'actions' },
  ];

  // eslint-disable-next-line class-methods-use-this
  get labaseURL(): string {
    return process.env.VUE_APP_LABASE_URL;
  }

  get viewParams(): { [slug: string]: RestParams } {
    return {
      contacts: ListMixin.buildListState(this.contactsOptions, this.contactsParams),
    };
  }

  @Emit('filter')
  // eslint-disable-next-line class-methods-use-this
  emitFilterEvent(field: string, value: RestParam) {
    return { field, value };
  }

  @Emit('reload')
  async unadoptContact(contact: Contact) {
    await axios.delete(`/instances/${this.instanceId}/contacts/${contact.id}`);

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  unadoptLabel(district: District | null) {
    if (!district) {
      return 'Désadopter';
    }

    return `Désadopter – retournera le contact à ${district.name}`;
  }

  @Watch('contactsLoading')
  onInstancesContactsListContactsLoadingChanged(loading: boolean) {
    if (!loading) {
      this.onInstancesContactsListRouteDataLoadedChanged(true);
    }
  }

  @Watch('routeDataLoaded')
  onInstancesContactsListRouteDataLoadedChanged(loaded: boolean) {
    if (loaded && this.contacts.length === 0 && this.contactsOptions.page !== 1) {
      this.setContactsOptions({
        ...this.contactsOptions,
        page: 1,
      });
    }
  }
}
