var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "instance-types-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.filtersDefinition,
                  "model-name": _vm.slug,
                  theme: "labase"
                },
                on: { input: _vm.setInstanceTypesParam }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "12", md: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.data,
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  options: _vm.options,
                  "set-options": _vm.setOptions,
                  theme: "labase",
                  total: _vm.total || 0
                },
                on: {
                  edit: function($event) {
                    return _vm.goto("instance_types", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.exclusive",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-boolean-indicator", {
                          attrs: { value: item.exclusive }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.can_delegate",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-boolean-indicator", {
                          attrs: { value: item.can_delegate }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.$route.params.id ? _c("router-view") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }