var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("qs-layout-navigation", {
        attrs: {
          "primary-menu": _vm.primaryMenuItems,
          "show-notifications": _vm.userHas("INSTANCE_NOTIFICATIONS_BETA"),
          theme: "labase",
          "version-link":
            "https://docs.google.com/document/d/1HBDRvizN4_RO1FBS4QZawprLZQhomTeGzxIHwdvUU5c/edit?usp=sharing",
          "version-number": _vm.version
        },
        on: { login: _vm.loadUserOrPrompt }
      }),
      _c(
        "v-main",
        [
          _c("qs-layout-notifications"),
          _c("router-view"),
          _c(
            "qs-confirmation-modal",
            _vm._b(
              {
                on: {
                  "click:confirm": _vm.runConfirmationDialogCallback,
                  "click:cancel": _vm.resetConfirmationDialog
                }
              },
              "qs-confirmation-modal",
              _vm.confirmationDialog,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }