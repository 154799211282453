
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsContactListItem from '@/components/QsContactListItem.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { InstanceRole } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

@Component({
  components: {
    QsContactListItem,
    QsFormBuilder,
  },
})
export default class InstanceRolesForm extends Vue {
  @Prop({ required: false, type: Object, default: null }) error!: ErrorResponse;
  @Prop({ required: true, type: Object }) form!: Form;
  @Prop({ required: true, type: Object }) item!: InstanceRole | null;
  @Prop({ required: true, type: Boolean }) loading!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) showInstance!: boolean;
  @Prop({ required: true, type: String }) slug!: string;

  get formOrder(): string[] {
    const fields = [
      'contact_id',
      'instance_role_type_id',
      'start_at',
      'end_at',
      'responsibilities.id',
    ];

    if (this.showInstance) {
      fields.push('instance_id');
    }

    return fields;
  }
}
