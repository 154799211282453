




































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import SelectableListMixin from 'qs_vuetify/src/mixins/SelectableListMixin';

import { AppNotification, DataTableOptions, ListAction } from 'qs_vuetify/src/types/components';
import { PersistedUser } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import MergeUsersDialog from '@/components/Users/MergeUsersDialog.vue';

const global: any = namespace('global');
const store: any = namespace('users');
const view: any = namespace('usersView');

@Component({
  components: {
    MergeUsersDialog,
    QsButton,
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Users extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ListMixin,
  SelectableListMixin,
) {
  @global.Mutation addNotification!: (notification: AppNotification) => void;

  @store.Getter('data') items!: PersistedUser[];
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Mutation addSelectedItem!: (item: PersistedUser) => void;
  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation removeSelectedItem!: (item: PersistedUser) => void;
  @view.Getter selectedItems!: PersistedUser[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  get actions(): ListAction[] {
    return [
      {
        disabled: this.loading || this.selectedItems.length < 2 || !this.userHas('USERS_DELETE'),
        icon: 'mdi-set-merge',
        label: 'Fusionner les utilisateurs·trices',
        callback: () => {
          this.mergeUsersAction.value = true;
        },
      },
    ];
  }

  headers = [
    {
      text: '',
      value: 'selected',
      sortable: false,
      width: '64px',
    },
    { text: 'ID', value: 'id' },
    { text: 'Contact', value: 'contact' },
    { text: 'Courriel', value: 'email' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  mergeUsersAction: {
    loading: boolean;
    target: PersistedUser | null;
    value: boolean;
  } = {
    loading: false,
    target: null,
    value: false,
  };

  setUsersParam = this.buildSetParam('users', this.setParams);

  get viewParams(): { [key: string]: RestParams } {
    return {
      users: ListMixin.buildListState(this.options, this.params),
    };
  }

  clearMergeUsersAction() {
    this.mergeUsersAction.loading = false;
    this.mergeUsersAction.target = null;
    this.mergeUsersAction.value = false;
  }

  async mergeUsers() {
    if (!this.mergeUsersAction.target) {
      return;
    }

    const targetId = this.mergeUsersAction.target.id;

    this.mergeUsersAction.loading = true;

    try {
      await this.$store.dispatch('users/mergeUsers', {
        data: {
          target_id: targetId,
        },
        params: {
          per_page: -1,
          id: this.selectedItems.map((c) => c.id)
            .filter((c) => c !== targetId)
            .join(','),
        },
      });

      this.setSelectedItems([]);
      this.clearMergeUsersAction();
      this.reloadDataRoutesData(['users.index'], true);
      this.addNotification({
        message: 'Les utilisateurs·trices ont été fusionnés·es avec succès.',
        color: 'success',
      });
    } catch (e) {
      const { error } = this.$store.state.users;
      this.addNotification({
        message: `Erreur lors de la fusion des utilisateurs·trices. La fusion
        n'est peut-être pas possible vers cet utilisateur·trice parce que
        certains éléments fusionnés ne peuvent pas être réassignés pour le
        moment.
        ${(error?.message ? `Message: ${error.message}. ` : '')}
        ${(error?.status ? `Code d'erreur: ${error.status}.` : '')}`,
        color: 'error',
        timeout: -1,
      });
      this.$store.commit('users/error', null);
    } finally {
      this.mergeUsersAction.loading = false;
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(loaded: boolean) {
    this.$emit('updateHead');
    if (loaded && this.items.length === 0) {
      this.setOptions({
        ...this.options,
        page: 1,
      });
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged($route: Route, $oldRoute: Route) {
    if ($oldRoute.params.id && !$route.params.id) {
      this.reloadDataRoutesData();
    }
  }
}
