var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qs-search" },
    [
      _c(
        "div",
        {
          directives: [{ name: "ripple", rawName: "v-ripple" }],
          staticClass: "search-bar",
          attrs: { role: "button", tabindex: "0" },
          on: { click: _vm.setActive }
        },
        [
          _c(
            "div",
            { staticClass: "search-bar__leading-icon" },
            [_c("v-icon", [_vm._v("mdi-filter-variant")])],
            1
          ),
          _c("div", { staticClass: "search-bar__main-content" }, [
            _vm._v(" " + _vm._s(_vm.searchText || "Filtrer les suivis") + " ")
          ]),
          _c(
            "div",
            { staticClass: "search-bar__trailing-icon" },
            [_c("v-icon", [_vm._v("mdi-magnify")])],
            1
          )
        ]
      ),
      _c("v-fade-transition", { attrs: { mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isActive,
                expression: "isActive"
              },
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: {
                  handler: _vm.setInactive,
                  include: _vm.clickOutsideInclude
                },
                expression:
                  "{\n        handler: setInactive,\n        include: clickOutsideInclude\n      }"
              }
            ],
            staticClass: "search-view"
          },
          [
            _c("div", { staticClass: "search-view__header" }, [
              _c(
                "div",
                { staticClass: "search-bar__leading-icon" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "margin-top": "-4px",
                        "margin-left": "-6px"
                      },
                      attrs: { icon: "" },
                      on: { click: _vm.cancelAllFilters }
                    },
                    [_c("v-icon", [_vm._v("mdi-filter-variant-remove")])],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "search-bar__main-content" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchText,
                      expression: "searchText"
                    }
                  ],
                  ref: "searchText",
                  attrs: {
                    id: "searchText",
                    placeholder: _vm.$filters.capitalize(
                      _vm.labelOrNothing("q", "filters") || _vm.labelOrName("q")
                    ),
                    type: "text"
                  },
                  domProps: { value: _vm.searchText },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.updateFilterNow("q", $event.target.value)
                    },
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchText = $event.target.value
                      },
                      function($event) {
                        return _vm.updateFilter("q", $event)
                      }
                    ]
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "search-bar__trailing-icon" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "margin-top": "-4px",
                        "margin-right": "-6px"
                      },
                      attrs: { icon: "" },
                      on: { click: _vm.setInactive }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ]),
            _c("v-divider", {
              staticClass: "my-0",
              attrs: { color: "#c0c9c0" }
            }),
            _c(
              "div",
              { staticClass: "search-view__filters" },
              [
                _c(
                  "v-expansion-panels",
                  { attrs: { accordion: "", flat: "" } },
                  _vm._l(_vm.availableFilters, function(ref, index) {
                    var def = ref.def
                    var label = ref.label
                    var name = ref.name
                    return _c(
                      "v-expansion-panel",
                      {
                        key: name + "_" + index,
                        attrs: {
                          disabled:
                            (def.type === "relation" && !!def.query) ||
                            def.type === "date"
                        }
                      },
                      [
                        _c("v-expansion-panel-header", [
                          _c(
                            "div",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("filterTypeIcon")(def.type)
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm._v(" " + _vm._s(label) + " "),
                              !!_vm.activeEditableFilters[name]
                                ? _c("v-badge", { attrs: { color: "#a3cddb" } })
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _c("v-expansion-panel-content", [
                          !def ||
                          (name === "q" && !_vm.withSearch) ||
                          (name !== "q" && def.type === "text") ||
                          def.type === "number"
                            ? _c(
                                "div",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      clearable: "",
                                      value: _vm.activeFilters[name],
                                      label:
                                        _vm.labelOrNothing(name, "filters") ||
                                        _vm.labelOrName(name),
                                      hint: _vm.descriptionOrNothing(
                                        name,
                                        "filters"
                                      ),
                                      "persistent-hint": !!_vm.descriptionOrNothing(
                                        name,
                                        "filters"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateFilter(name, $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : def.type === "date" || def.type === "datetime"
                            ? _c(
                                "div",
                                [
                                  _c("qs-select-date-interval", {
                                    attrs: {
                                      interval: _vm.activeFilters[name]
                                    },
                                    on: {
                                      "update:interval": function($event) {
                                        return _vm.updateFilter(name, $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : def.type === "boolean"
                            ? _c(
                                "div",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      value: String(_vm.activeFilters[name]),
                                      items: [
                                        {
                                          value: null,
                                          text: ""
                                        },
                                        {
                                          value: "true",
                                          text: "Oui"
                                        },
                                        {
                                          value: "false",
                                          text: "Non"
                                        }
                                      ],
                                      label:
                                        _vm.labelOrNothing(name, "filters") ||
                                        _vm.labelOrName(name),
                                      hint: _vm.descriptionOrNothing(
                                        name,
                                        "filters"
                                      ),
                                      "persistent-hint": !!_vm.descriptionOrNothing(
                                        name,
                                        "filters"
                                      ),
                                      placeholder:
                                        _vm.labelOrNothing(name, "filters") ||
                                        _vm.labelOrName(name)
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateFilter(name, $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : def.type === "enum"
                            ? _c(
                                "div",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      clearable: "",
                                      multiple: !def.maximum || def.maximum > 1,
                                      value: _vm.getFilterValueAsArray(name),
                                      items: _vm.labelsOrValues(
                                        name,
                                        def.values
                                      ),
                                      label:
                                        _vm.labelOrNothing(name, "filters") ||
                                        _vm.labelOrName(name),
                                      hint: _vm.descriptionOrNothing(
                                        name,
                                        "filters"
                                      ),
                                      "persistent-hint": !!_vm.descriptionOrNothing(
                                        name,
                                        "filters"
                                      ),
                                      placeholder:
                                        _vm.labelOrNothing(name, "filters") ||
                                        _vm.labelOrName(name)
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateSelectFilter(
                                          name,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : def.type === "relation"
                            ? _c(
                                "div",
                                [
                                  def.store
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-chip-group",
                                            {
                                              attrs: {
                                                column: "",
                                                multiple:
                                                  !def.maximum ||
                                                  def.maximum > 1,
                                                value: _vm.getFilterValueAsArray(
                                                  name
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.updateFilter(
                                                    name,
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(
                                              _vm.$store.state[def.store.slug][
                                                def.store.key
                                              ],
                                              function(option) {
                                                return _c(
                                                  "v-chip",
                                                  {
                                                    key:
                                                      name +
                                                      "-" +
                                                      index +
                                                      "__" +
                                                      option[def.store.value],
                                                    attrs: {
                                                      filter: "",
                                                      small: "",
                                                      value:
                                                        option[def.store.value]
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          option[def.store.text]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : def.query
                                    ? _c("qs-relation-field", {
                                        attrs: {
                                          disabled: "",
                                          name: name,
                                          label:
                                            _vm.labelOrNothing(
                                              name,
                                              "filters"
                                            ) || _vm.labelOrName(name),
                                          description: _vm.descriptionOrNothing(
                                            name,
                                            "filters"
                                          ),
                                          item: _vm.activeFilters,
                                          query: def.query
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateRelationQueryFilter(
                                              name,
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            _vm.$scopedSlots[name + ".item"]
                                              ? {
                                                  key: "item",
                                                  fn: function(data) {
                                                    return [
                                                      _vm._t(
                                                        name + ".item",
                                                        null,
                                                        null,
                                                        data
                                                      )
                                                    ]
                                                  }
                                                }
                                              : null
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }