

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { PersistedInstance as Instance } from 'qs_vuetify/src/types/models';

import InstanceElectedInfo from './InstanceElectedInfo.vue';

@Component({
  components: {
    InstanceElectedInfo,
  },
})
export default class InstanceCard extends Vue {
  @Prop({ required: true, type: Object }) item!: Instance;
  @Prop({ required: false, type: Boolean, default: true }) showExtraInformation!: boolean;

  get hasExtraInformation(): boolean {
    return this.item.active_coordination?.length > 0
      || this.item.active_representant_officiel?.length > 0;
  }
}
