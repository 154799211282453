































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { InstanceRoleResponsibility } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('instance_role_responsibilities');
const view: any = namespace('instanceRoleResponsibilitiesViews');

@Component({
  components: {
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceRoleResponsibilitiesList
  extends mixins(AuthenticationMixin, DataRouteGuards, ListMixin) {
  @store.Getter data!: Array<InstanceRoleResponsibility>;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  mounted() {
    this.setAction();
  }

  setAction() {
    if (this.userHas('INSTANCE_ROLE_RESPONSIBILITIES_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/instance_role_responsibilities/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      instance_role_responsibilities: ListMixin.buildListState(this.options, this.params),
    };
  }

  setInstanceRoleResponsibilitiesParam = this.buildSetParam('instance_role_responsibilities', this.setParams);

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setAction();
    this.$emit('updateHead');
  }

  @Watch('$route', { deep: true })
  onRouteChanged($route: Route, $oldRoute: Route) {
    if ($oldRoute.params.id && !$route.params.id) {
      this.setAction();
      this.reloadDataRoutesData();
    }
  }

  headers = [
    // { text: 'ID', value: 'id' },
    { text: 'Nom', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
}
