var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    attrs: { "content-class": "petitions-list" },
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                "model-name": _vm.slug,
                order: _vm.filtersOrder,
                theme: "labase"
              },
              on: { input: _vm.setPetitionsParams }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-list", {
              attrs: {
                error: _vm.error,
                items: _vm.data,
                "hide-selection": "",
                loading: _vm.loading,
                "model-name": _vm.slug,
                selection: _vm.selectedItems,
                theme: "labase",
                total: _vm.total
              },
              on: { load: _vm.loadNextPage },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("petitions-card", {
                        attrs: {
                          item: item,
                          to: { name: "PetitionForm", params: { id: item.id } }
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("router-view")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }