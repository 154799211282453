var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        "max-width": "600",
        value: _vm.visible
      },
      on: {
        "click:close": function($event) {
          return _vm.$emit("input", false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Adopter un·e membre ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", depressed: "", tile: "" },
                  on: { click: _vm.adoptContact }
                },
                [_vm._v(" Adopter ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("qs-relation-field", {
        attrs: {
          item: { contact: _vm.adoptedContact },
          label: "Contact",
          name: "contact_id",
          query: _vm.queryDefinition
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("qs-contact-list-item", {
                  attrs: { item: item.contact || item }
                })
              ]
            }
          },
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [_c("qs-contact-list-item", { attrs: { item: item } })]
            }
          }
        ]),
        model: {
          value: _vm.adoptedContact,
          callback: function($$v) {
            _vm.adoptedContact = $$v
          },
          expression: "adoptedContact"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }