var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        "max-width": "600",
        value: _vm.visible
      },
      on: {
        "click:close": function($event) {
          return _vm.$emit("input", false)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("div", { staticClass: "text-center" }, [
                _vm._v(" Paramètres des "),
                _c("br"),
                _vm._v("Rôles et Permissions d'Instances ")
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("qs-date-field", {
        attrs: {
          clearable: "",
          label: "Fin de nouvelle permission par défaut",
          name: "default_end_at",
          "with-time": "",
          value: _vm.defaultEndAt
        },
        on: { input: _vm.setDefaultEndAt }
      }),
      _c("qs-relation-field", {
        attrs: {
          "initial-selection": _vm.defaultRightGroups,
          item: { right_groups: _vm.defaultRightGroups },
          label: "Permissions par défaut",
          multiple: "",
          name: "right_groups",
          store: {
            key: "data",
            slug: "right_groups",
            text: "name",
            value: "id"
          },
          value: _vm.defaultRightGroups
        },
        on: { input: _vm.setDefaultRightGroups }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }