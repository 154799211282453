var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { color: "white", dark: false, value: _vm.value },
      on: {
        "click:close": function($event) {
          return _vm.$emit("click:close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Fusionner des utilisateurs·trices ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-alert",
        {
          staticClass: "body-2 text-left",
          attrs: { color: "error", dark: "", icon: "mdi-information", tile: "" }
        },
        [
          _vm._v(
            " L'Utilisateur·trice sélectionnée héritera de tous les éléments appartenant aux autres utilisateurs·trices et ceux·celles-ci seront supprimées. Cette opération ne peut pas être annulée. "
          )
        ]
      ),
      _c("v-select", {
        staticClass: "mb-3",
        attrs: {
          items: _vm.items,
          hint: "L'utilisateur·trice qu'on veut conserver",
          "item-value": "id",
          label: "Utilisateur·trice à conserver",
          "persistent-hint": "",
          "return-object": "",
          value: _vm.target
        },
        on: {
          change: function($event) {
            return _vm.$emit("input:target", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var attrs = ref.attrs
              var item = ref.item
              var on = ref.on
              return [
                _c(
                  "v-list-item",
                  _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          item.contact
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(item.contact.full_name) + " "
                                ),
                                _c("small", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(item.contact.v1_contact_id) +
                                      ")"
                                  )
                                ])
                              ])
                            : _c("span", [
                                _vm._v("Utilisateur·trice #" + _vm._s(item.id))
                              ])
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(item.email) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-list-item",
                  { attrs: { href: "#" } },
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          item.contact
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(item.contact.full_name) + " "
                                ),
                                _c("small", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(item.contact.v1_contact_id) +
                                      ")"
                                  )
                                ])
                              ])
                            : _c("span", [
                                _vm._v("Utilisateur·trice #" + _vm._s(item.id))
                              ])
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(item.email) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm.target && !_vm.loading
        ? _c(
            "div",
            [
              _c("v-checkbox", {
                attrs: {
                  label:
                    "Je comprends que cette opération ne pourra pas être annulée et\n              que je m'apprête à supprimer " +
                    (_vm.items.length - 1) +
                    "\n              " +
                    _vm.$tc("models.users.name", _vm.items.length - 1) +
                    "."
                },
                model: {
                  value: _vm.agreed,
                  callback: function($$v) {
                    _vm.agreed = $$v
                  },
                  expression: "agreed"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "merge-users-dialog__buttons text-center" },
        [
          _c(
            "qs-button",
            {
              attrs: {
                disabled: !_vm.target || _vm.loading || !_vm.agreed,
                large: "",
                loading: _vm.loading
              },
              on: { click: _vm.emitSave }
            },
            [_vm._v(" Fusionner ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }