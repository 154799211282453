






















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Contact, InstanceUser } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams, RestParam } from 'qs_vuetify/src/types/states';

const instanceUsers: any = namespace('instance_users');
const view: any = namespace('instancesView');

@Component({
  components: {
    QsBooleanIndicator,
    QsDataTable,
    QsDateField,
    QsFilters,
  },
})
export default class InstanceUsersList extends Vue {
  @Prop({ required: true, type: Boolean, default: false }) routeDataLoaded!: boolean;

  @instanceUsers.Getter('data') instanceUsers!: Array<Contact>;
  @instanceUsers.Getter('filtersDefinition') instanceUsersFiltersDefinition!: FiltersDefinition;
  @instanceUsers.Getter('loading') instanceUsersLoading!: boolean;
  @instanceUsers.Getter('total') instanceUsersTotal!: number;

  @view.Getter instanceUsersOptions!: DataTableOptions;
  @view.Getter instanceUsersParams!: RestParams;
  @view.Mutation('instanceUsersOptions') setInstanceUsersOptions!: any;
  @view.Mutation('instanceUsersParams') setInstanceUsersParams!: any;

  tableHeaders = [
    { text: 'Actif', value: 'is_active', sortable: false },
    { text: 'Nom', value: 'contact_name' },
    { text: 'Début', value: 'start_at', align: 'right' },
    { text: 'Fin', value: 'end_at', align: 'right' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  async emitArchive(instanceUser: InstanceUser) {
    await this.$store.dispatch('instance_users/archive', instanceUser);

    this.$emit('reload');
  }

  setParams(params: RestParams) {
    this.$emit('input:filters', params);
  }

  updateFilters(name: string, value: RestParam): void {
    const { instanceUsersParams } = this;
    const newParams: RestParams = { ...instanceUsersParams };
    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }
    newParams.page = 1;

    this.$emit('input:filters', newParams);
  }

  @Watch('routeDataLoaded')
  onInstancesUsersListRouteDataLoadedChanged(loaded: boolean) {
    if (loaded && this.instanceUsers.length === 0 && this.instanceUsersOptions.page !== 1) {
      this.setInstanceUsersOptions({
        ...this.instanceUsersOptions,
        page: 1,
      });
    }
  }
}
