import { render, staticRenderFns } from "./MergeUsersDialog.vue?vue&type=template&id=25807a0f&"
import script from "./MergeUsersDialog.vue?vue&type=script&lang=ts&"
export * from "./MergeUsersDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAlert,VCheckbox,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_vie_associative/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25807a0f')) {
      api.createRecord('25807a0f', component.options)
    } else {
      api.reload('25807a0f', component.options)
    }
    module.hot.accept("./MergeUsersDialog.vue?vue&type=template&id=25807a0f&", function () {
      api.rerender('25807a0f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Users/MergeUsersDialog.vue"
export default component.exports