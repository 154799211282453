import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { InstancesViewsState } from '@/types/states';

export const instancesView: Module<InstancesViewsState, RootState> = {
  namespaced: true,
  state: {
    adoptionModalVisible: false,
    contacts: {
      options: {
        sortBy: ['full_name'],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 10,
      },
      params: {
        fields: [
          'adopted',
          'district.name',
          'email',
          'full_name',
          'status',
          'v1_contact_id',
        ].join(','),
      },
    },
    defaultEndAt: null,
    defaultRightGroups: [],
    instanceEvents: {
      params: {},
    },
    instanceGrants: {
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 25,
      },
      params: {
        is_active: true,
      },
    },
    instanceRoles: {
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
      },
      params: {
        is_active: true,
        '!instance_role_type.id': 6,
      },
    },
    instanceUsers: {
      options: {
        sortBy: ['id'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10,
      },
      params: {
        is_active: true,
      },
    },
    newItemModal: false,
    params: {
      order: 'name',
      page: 1,
      per_page: 20,
    },
    showExtraInformation: true,
  },
  getters: {
    adoptionModalVisible(state) {
      return state.adoptionModalVisible;
    },
    contactsOptions(state) {
      return state.contacts.options;
    },
    contactsParams(state) {
      return state.contacts.params;
    },
    defaultEndAt(state) {
      return state.defaultEndAt;
    },
    defaultRightGroups(state) {
      return state.defaultRightGroups;
    },
    instanceEventsParams(state) {
      return state.instanceEvents.params;
    },
    instanceGrantsOptions(state) {
      return state.instanceGrants.options;
    },
    instanceGrantsParams(state) {
      return state.instanceGrants.params;
    },
    instanceRolesOptions(state) {
      return state.instanceRoles.options;
    },
    instanceRolesParams(state) {
      return state.instanceRoles.params;
    },
    instanceUsersOptions(state) {
      return state.instanceUsers.options;
    },
    instanceUsersParams(state) {
      return state.instanceUsers.params;
    },
    newItemModal(state) {
      return state.newItemModal;
    },
    params(state) {
      return state.params;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
  },
  mutations: {
    adoptionModalVisible(state, adoptionModalVisible) {
      state.adoptionModalVisible = adoptionModalVisible;
    },
    contactsOptions(state, contactsOptions) {
      state.contacts.options = contactsOptions;
    },
    contactsParams(state, contactsParams) {
      state.contacts.params = contactsParams;
    },
    instanceEventsParams(state, instanceEventsParams) {
      state.instanceEvents.params = instanceEventsParams;
    },
    instanceGrantsOptions(state, instanceGrantsOptions) {
      state.instanceGrants.options = instanceGrantsOptions;
    },
    instanceGrantsParams(state, instanceGrantsParams) {
      state.instanceGrants.params = instanceGrantsParams;
    },
    instanceRolesOptions(state, instanceRolesOptions) {
      state.instanceRoles.options = instanceRolesOptions;
    },
    instanceRolesParams(state, instanceRolesParams) {
      state.instanceRoles.params = instanceRolesParams;
    },
    instanceUsersOptions(state, instanceUsersOptions) {
      state.instanceUsers.options = instanceUsersOptions;
    },
    instanceUsersParams(state, instanceUsersParams) {
      state.instanceUsers.params = instanceUsersParams;
    },
    setDefaultEndAt(state, defaultEndAt) {
      state.defaultEndAt = defaultEndAt;
    },
    setDefaultRightGroups(state, defaultRightGroups) {
      state.defaultRightGroups = defaultRightGroups;
    },
    setNewItemModal(state, value) {
      state.newItemModal = value;
    },
    setParams(state, params) {
      state.params = params;
    },
    showExtraInformation(state, value) {
      state.showExtraInformation = value;
    },
    showAdoptionModal(state) {
      state.adoptionModalVisible = true;
    },
  },
};

export default instancesView;
