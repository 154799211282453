var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["qs-instance-stats-card--" + _vm.color] }, [
    _c("div", { staticClass: "qs-instance-stats-card__title" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.$tc(
              "models.contacts.enums.status." + _vm.status.toLowerCase(),
              _vm.count
            )
          ) +
          " "
      )
    ]),
    _c("div", { staticClass: "qs-instance-stats-card__content" }, [
      _c("div", { staticClass: "qs-instance-stats-card__content__count" }, [
        _vm._v(" " + _vm._s(_vm._f("format")(_vm.count)) + " ")
      ]),
      _vm.previousDate
        ? _c(
            "div",
            { staticClass: "qs-instance-stats-card__content__delta" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "#414942" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.arrowDirection(_vm.count, _vm.previousCount)) +
                      " "
                  )
                ]
              ),
              _vm._v(" " + _vm._s(Math.abs(_vm.previousCount - _vm.count))),
              _c("br"),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("timeFromNow")(_vm.previousDate)) +
                    " jours "
                )
              ])
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }