





































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { InstanceRoleResponsibility } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const store: any = namespace('instance_role_responsibilities');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceRoleResponsibilitiesForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter item!: InstanceRoleResponsibility;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @Prop([String, Number]) id!: string | number;

  mounted() {
    this.onRouteChanged();
  }

  closeModal() {
    this.$router.push({ name: 'InstanceRoleResponsibilitiesList' });
  }

  @Watch('item.name')
  onItemNameChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
    this.setAction();
  }

  setAction() {
    if (this.userHas('INSTANCE_ROLE_RESPONSIBILITIES_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/instance_role_responsibilities/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit('global/subtitle', this.item?.name || "Nouvelle responsabilité d'instance");
    } else {
      this.$store.commit('global/subtitle', this.item?.name || `Responsabilité d'instance ${this.item.id}`);
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    await this.submit();

    this.reloadDataRoutesData(['instance_role_responsibilities.index']);

    this.closeModal();
  }
}
