var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "instances-form", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    color: "primary",
                    icon: "",
                    link: "",
                    tile: "",
                    to: { name: "Instances" }
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-menu-left")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "text-h6" }, [
                _vm._v("Retour à la liste")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-1",
                  staticStyle: { "background-color": "transparent" },
                  attrs: { value: _vm.section, vertical: "" }
                },
                [
                  _c("v-tab", { attrs: { to: _vm.tabUrl("summary") } }, [
                    _vm._v("Résumé")
                  ]),
                  _vm.userHas("INSTANCE_EVENTS_RETRIEVE")
                    ? _c("v-tab", { attrs: { to: _vm.tabUrl("events") } }, [
                        _vm._v("Suivis")
                      ])
                    : _vm._e(),
                  _c("v-tab", { attrs: { to: _vm.tabUrl("contacts") } }, [
                    _vm._v("Membres")
                  ]),
                  _vm.userHasAll([
                    "INSTANCE_USERS_RETRIEVE",
                    "INSTANCE_ROLES_RETRIEVE"
                  ])
                    ? _c("v-tab", { attrs: { to: _vm.tabUrl("grants") } }, [
                        _vm._v(" Rôles et permissions ")
                      ])
                    : _vm._e(),
                  _vm.userHas("INSTANCE_ROLES_RETRIEVE")
                    ? _c("v-tab", { attrs: { to: _vm.tabUrl("roles") } }, [
                        _vm._v("Rôles")
                      ])
                    : _vm._e(),
                  _vm.userHas("INSTANCE_USERS_RETRIEVE")
                    ? _c("v-tab", { attrs: { to: _vm.tabUrl("users") } }, [
                        _vm._v("Permissions")
                      ])
                    : _vm._e(),
                  _vm.userHas("INSTANCES_UPDATE")
                    ? _c("v-tab", { attrs: { to: _vm.tabUrl("settings") } }, [
                        _vm._v("Paramètres")
                      ])
                    : _vm._e(),
                  _vm.userHas("INSTANCES_UPDATE")
                    ? _c(
                        "v-tab",
                        { attrs: { to: _vm.tabUrl("election2022") } },
                        [_vm._v("Paramètres spéciaux")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10" } },
            [
              _vm.routeDataLoaded || (_vm.itemReady && !_vm.loading)
                ? _c(
                    "v-tabs-items",
                    {
                      staticClass: "bg-transparent",
                      attrs: { value: _vm.section }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "summary" } },
                        [
                          _c("instance-stats", { staticClass: "mb-4" }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "m3-card m3-surface pa-4" },
                                    [
                                      _c("h3", [_vm._v("Rôles")]),
                                      _vm.statsLoaded &&
                                      _vm.stats.roles &&
                                      _vm.stats.roles.gender &&
                                      _vm.stats.roles.gender.elue &&
                                      !_vm.stats.roles.gender.elue.parity
                                        ? _c("warning-indicator", {
                                            staticClass: "my-2",
                                            attrs: {
                                              color: "none",
                                              text: "Élu·es non-paritaires"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.item &&
                                      _vm.item.active_coordination &&
                                      _vm.item.active_coordination.length > 0
                                        ? _vm._l(
                                            _vm.item.active_coordination,
                                            function(coordo, index) {
                                              return _c(
                                                "instance-elected-info",
                                                {
                                                  key: "coordo_" + index,
                                                  attrs: {
                                                    item: coordo,
                                                    label: "Coordination"
                                                  }
                                                }
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.item &&
                                      _vm.item.active_coordination &&
                                      _vm.item.active_representant_officiel
                                        .length > 0
                                        ? _vm._l(
                                            _vm.item
                                              .active_representant_officiel,
                                            function(ro, index) {
                                              return _c(
                                                "instance-elected-info",
                                                {
                                                  key: "ro_" + index,
                                                  staticClass: "mt-4",
                                                  attrs: {
                                                    item: ro,
                                                    label:
                                                      "Représentant·e officiel·le"
                                                  }
                                                }
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex justify-end mt-4"
                                        },
                                        [
                                          _vm.item
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "#4f6354",
                                                    text: "",
                                                    rounded: "",
                                                    to:
                                                      "/instances/" +
                                                      _vm.item.id +
                                                      "/roles"
                                                  }
                                                },
                                                [_vm._v("Voir tous")]
                                              )
                                            : _vm._e(),
                                          _vm.item
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-2 tertiary",
                                                  attrs: {
                                                    color: "#3b6470",
                                                    depressed: "",
                                                    rounded: "",
                                                    to:
                                                      "/instances/" +
                                                      _vm.item.id +
                                                      "/roles/new"
                                                  }
                                                },
                                                [_vm._v("Nouveau")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    md: "12",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "m3-card m3-surface py-4" },
                                    [
                                      _c("h3", { staticClass: "ml-4" }, [
                                        _vm._v("Derniers suivis")
                                      ]),
                                      _vm.item
                                        ? _c("warning-indicator", {
                                            staticClass: "my-2 ml-4",
                                            attrs: {
                                              color: "none",
                                              icon: _vm.item.is_active
                                                ? "mdi-check"
                                                : "mdi-close",
                                              text: _vm.item.is_active
                                                ? "Instance active"
                                                : "Instance inactive"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.stats
                                        ? _c("qs-instance-events-list", {
                                            attrs: {
                                              items: _vm.stats.last_events
                                            }
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex justify-end mt-4"
                                        },
                                        [
                                          _vm.item &&
                                          _vm.stats &&
                                          _vm.stats.last_events.length > 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "#4f6354",
                                                    text: "",
                                                    rounded: "",
                                                    to:
                                                      "/instances/" +
                                                      _vm.item.id +
                                                      "/events"
                                                  }
                                                },
                                                [_vm._v("Voir tous")]
                                              )
                                            : _vm._e(),
                                          _vm.item
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-2 tertiary",
                                                  attrs: {
                                                    color: "#3b6470",
                                                    depressed: "",
                                                    rounded: "",
                                                    to:
                                                      "/instances/" +
                                                      _vm.item.id +
                                                      "/events/new"
                                                  }
                                                },
                                                [_vm._v("Nouveau")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "events" } },
                        [_c("instance-events-list-detail")],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "contacts" } },
                        [
                          _c("instance-contacts-list", {
                            attrs: { "instance-id": _vm.id },
                            on: {
                              filter: function($event) {
                                return _vm.setContactsParam(
                                  $event.field,
                                  $event.value
                                )
                              },
                              reload: function($event) {
                                return _vm.reloadDataRoutesData(["contacts"])
                              }
                            }
                          }),
                          _vm.item && _vm.item.can_adopt
                            ? _c("member-adoption-dialog", {
                                attrs: { item: _vm.item },
                                model: {
                                  value: _vm.adoptionModalVisible,
                                  callback: function($$v) {
                                    _vm.adoptionModalVisible = $$v
                                  },
                                  expression: "adoptionModalVisible"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "grants" } },
                        [
                          _c("instance-grants-list", {
                            attrs: {
                              section: "grants",
                              "route-data-loaded": _vm.routeDataLoaded
                            },
                            on: {
                              edit: function($event) {
                                return _vm.goto(
                                  "instances",
                                  _vm.item,
                                  "grants",
                                  $event.contact_id,
                                  $event.user_id
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "roles" } },
                        [
                          _c("instance-roles-list", {
                            attrs: {
                              section: "roles",
                              "route-data-loaded": _vm.routeDataLoaded
                            },
                            on: {
                              edit: function($event) {
                                return _vm.goto(
                                  "instances",
                                  _vm.item,
                                  "roles",
                                  $event.id
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "users" } },
                        [
                          _c("instance-users-list", {
                            attrs: { "route-data-loaded": _vm.routeDataLoaded },
                            on: {
                              edit: function($event) {
                                return _vm.goto(
                                  "instances",
                                  _vm.item,
                                  "users",
                                  $event.id
                                )
                              },
                              "input:filters": _vm.setInstanceUsersParams,
                              reload: function($event) {
                                return _vm.reloadDataRoutesData(
                                  ["instance_users.index"],
                                  true
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "settings" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _vm.item
                                    ? _c("qs-form-builder", {
                                        attrs: {
                                          "model-name": _vm.slug,
                                          form: _vm.form,
                                          item: _vm.item,
                                          error: _vm.error,
                                          order: _vm.formOrder
                                        },
                                        on: { input: _vm.syncItem },
                                        scopedSlots: _vm._u(
                                          [
                                            !_vm.userIsSuperadmin
                                              ? {
                                                  key: "filter",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-input v-input--is-label-active v-input--is-dirty\n                    theme--light v-text-field v-text-field--is-booted v-text-field--placeholder"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "v-input__control"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "v-input__slot"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "v-text-field__slot"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        {
                                                                          staticClass:
                                                                            "v-label v-label--active theme--light",
                                                                          staticStyle: {
                                                                            left:
                                                                              "0px",
                                                                            right:
                                                                              "auto",
                                                                            position:
                                                                              "absolute"
                                                                          },
                                                                          attrs: {
                                                                            for:
                                                                              "filter"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Filtre"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          attrs: {
                                                                            name:
                                                                              "filter",
                                                                            id:
                                                                              "filter",
                                                                            type:
                                                                              "text",
                                                                            disabled:
                                                                              ""
                                                                          },
                                                                          domProps: {
                                                                            value:
                                                                              item.filter
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "v-text-field__details"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "v-messages theme--light"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "v-messages__wrapper"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Seuls les administrateurs globaux peuvent modifier le filtre d'une instance. "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              : null
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "election2022" } },
                        [
                          _vm.item && _vm.item.meta
                            ? _c("instance-meta-2022-card", {
                                attrs: { meta: _vm.item.meta },
                                on: { input: _vm.syncMeta }
                              })
                            : _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" }
                              })
                        ],
                        1
                      ),
                      _c("users-settings-dialog", {
                        model: {
                          value: _vm.usersSettingsDialogVisible,
                          callback: function($$v) {
                            _vm.usersSettingsDialogVisible = $$v
                          },
                          expression: "usersSettingsDialogVisible"
                        }
                      }),
                      _c("volunteers-mass-import-dialog", {
                        attrs: { "instance-id": _vm.id },
                        model: {
                          value: _vm.volunteersMassImportDialogVisible,
                          callback: function($$v) {
                            _vm.volunteersMassImportDialogVisible = $$v
                          },
                          expression: "volunteersMassImportDialogVisible"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center align-center",
                      staticStyle: { height: "108px" }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      !_vm.$route.params.eventId ? _c("router-view") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }