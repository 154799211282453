





































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import Fileable from 'qs_vuetify/src/mixins/Fileable';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import BaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsTagChip from 'qs_vuetify/src/components/Tags/QsTagChip.vue';
import QsTagsAutocomplete from 'qs_vuetify/src/components/Tags/QsTagsAutocomplete.vue';

import { ButtonProps, Form } from 'qs_vuetify/src/types/components';
import { PersistedInstance, PersistedPetition, PersistedTag } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RelationQueryDefinition, RestParams } from 'qs_vuetify/src/types/states';

const global: any = namespace('global');
const instances: any = namespace('instances');
const store: any = namespace('petitions');
const tags: any = namespace('tags');
const view: any = namespace('petitionsViews');

@Component({
  components: {
    BaseLayout,
    QsButton,
    QsCard,
    QsFormBuilder,
    QsHtmlEditor,
    QsRelationField,
    QsTagChip,
    QsTagsAutocomplete,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class PetitionForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  Fileable,
  FormMixin,
) {
  @Prop([String, Number]) id!: string | number;

  @global.Mutation addNotification!: any;

  @instances.Getter('loading') instancesLoading!: boolean;

  @store.Getter error!: ErrorResponse;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter form!: Form;
  @store.Getter item!: PersistedPetition;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @tags.Getter('data') tagsData!: PersistedTag[];
  @tags.Getter('loading') tagsLoading!: boolean;

  @view.Getter params!: RestParams;
  @view.Mutation setParams!: any;

  addPetitionTagLoading = false;

  contentForm = {
    title: {
      type: 'text',
      rules: {},
    },
    body: {
      type: 'textarea',
      rules: {},
    },
    callToAction: {
      type: 'text',
      rules: {},
    },
    buttonLabel: {
      type: 'text',
      rules: {},
    },
    secondaryCallsToAction: {
      form: {
        title: {
          type: 'text',
          rules: {},
        },
        body: {
          type: 'textarea',
          rules: {},
        },
        buttonText: {
          type: 'text',
          rules: {},
        },
        link: {
          type: 'text',
          rules: {},
        },
      },
      type: 'array',
      rules: {},
    },
  }

  instanceQueryDefinition: RelationQueryDefinition & { key: string } = {
    key: 'data',
    slug: 'instances',
    text: 'name',
    value: 'id',
    params: {
      fields: [
        'district_id',
        'filter',
        'instance_type_id',
        'name',
        'region_id',
      ].join(','),
    },
  };

  selectedInstance: PersistedInstance | null = null;
  removePetitionTagLoading = false;
  removingPetitionTagId: number | null = null;

  destroyed() {
    this.syncItem(null);
  }

  mounted() {
    this.setup();
  }

  async addPetitionInstance({ id, ...data }: PersistedInstance) {
    try {
      await this.$store.dispatch('instances/update', {
        id,
        data,
        prefix: `/petitions/${this.item.id}`,
      });

      this.addNotification({
        color: 'success',
        message: 'Instance ajoutée',
        timeout: 2500,
      });

      this.reloadDataRoutesData(['petitions.retrieve'], true);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Erreur lors de l'ajout de l'instance",
        timeout: 2500,
      });
    }
  }

  async addPetitionTag({ id, name }: PersistedTag) {
    this.addPetitionTagLoading = true;

    try {
      await this.$store.dispatch('tags/update', {
        id,
        data: { name },
        prefix: `/petitions/${this.item.id}`,
      });

      this.addNotification({
        color: 'success',
        message: 'Étiquette ajoutée',
        timeout: 2500,
      });

      this.reloadDataRoutesData(['petitions.retrieve'], true);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de l\'ajout de l\'étiquette',
        timeout: 2500,
      });
    } finally {
      this.addPetitionTagLoading = false;
    }
  }

  async removePetitionInstance({ id }: PersistedInstance) {
    try {
      await this.$store.dispatch('instances/destroy', {
        id,
        prefix: `/petitions/${this.item.id}`,
      });

      this.addNotification({
        color: 'success',
        message: 'Instance retirée',
        timeout: 2500,
      });

      this.reloadDataRoutesData(['instances']);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors du retrait de l\'instance',
        timeout: 2500,
      });
    }
  }

  async removePetitionTag({ id }: PersistedTag) {
    this.removePetitionTagLoading = true;
    this.removingPetitionTagId = id;

    try {
      await this.$store.dispatch('tags/destroy', {
        id,
        prefix: `/petitions/${this.item.id}`,
      });

      this.addNotification({
        color: 'success',
        message: 'Étiquette retirée',
        timeout: 2500,
      });

      this.reloadDataRoutesData(['petitions.retrieve'], true);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors du retrait de l\'étiquette',
        timeout: 2500,
      });
    } finally {
      this.removePetitionTagLoading = false;
    }
  }

  afterSave() {
    this.addNotification({
      color: 'success',
      message: 'Enregistré avec succès',
      timeout: 2500,
    });
  }

  afterUploadSuccess() {
    this.syncItem({
      ...this.item,
      image: {
        id: this.fileId,
      },
    });

    this.submit();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoaded() {
    this.setup();
  }

  setActions() {
    const actions: ButtonProps[] = [];
    if (this.userHas('PETITIONS_UPDATE')) {
      actions.push(
        {
          color: 'primary',
          disabled: !this.itemReady,
          icon: 'mdi-content-save',
          onClick: this.submit,
          tooltip: 'Enregistrer',
        },
      );
    }

    if (this.itemReady) {
      const baseURL = process.env.VUE_APP_PETITIONS_URL;
      actions.push(
        {
          color: 'info',
          disabled: !this.item || !baseURL,
          href: `${baseURL}${this.item.slug}`,
          icon: 'mdi-open-in-new',
          tooltip: 'Ouvrir la pétition dans une nouvelle fenêtre',
        },
      );
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    if (this.itemReady) {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  setup() {
    this.setActions();
    this.setGlobalSubtitle();
  }
}
