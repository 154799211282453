var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "home", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "subtitle font-weight-bold" }, [
              _vm._v("👋 Bienvenue sur Assos!")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.user
                ? _c(
                    "qs-card",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [_vm._v(" Raccourcis ")]
                            },
                            proxy: true
                          },
                          _vm.user && _vm.user.contact
                            ? {
                                key: "subtitle",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.user.contact.full_name) +
                                        " "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("v-treeview", {
                        staticClass: "qs-assos-shortcuts",
                        attrs: {
                          items: _vm.shortcuts,
                          "open-all": "",
                          "open-on-click": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function(ref) {
                                var item = ref.item
                                var open = ref.open
                                return [
                                  item.children
                                    ? _c("v-icon", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              open
                                                ? "mdi-folder-open"
                                                : "mdi-folder"
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("v-icon", [
                                        _vm._v(" mdi-home-assistant ")
                                      ])
                                ]
                              }
                            },
                            {
                              key: "label",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.href
                                    ? _c(
                                        "router-link",
                                        { attrs: { to: item.href } },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    : [_vm._v(" " + _vm._s(item.name) + " ")]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2729630265
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "qs-card",
                {
                  staticStyle: { "margin-top": "1px" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [_vm._v(" Derniers suivis ")]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.instance_events.length > 0
                    ? _c(
                        "v-list",
                        _vm._l(_vm.instance_events, function(event) {
                          return _c(
                            "v-list-item",
                            {
                              key: event.id,
                              staticClass: "qs-instance-events-list__item",
                              attrs: {
                                to:
                                  "/instances/" +
                                  event.instance.id +
                                  "/events/" +
                                  event.id
                              }
                            },
                            [
                              event.instance_event_type
                                ? _c(
                                    "v-list-item-avatar",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#414942" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("icon")(
                                                event.instance_event_type.name
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item-content",
                                [
                                  event.instance_event_type
                                    ? _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "overline qs-text-blue"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(event.instance.name))
                                          ]),
                                          _vm._v(
                                            " · " +
                                              _vm._s(
                                                event.instance_event_type.name
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  event.comment
                                    ? _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "qs-text-blue" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(event.comment) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  event.created_by_user
                                    ? _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "caption qs-text-blue" },
                                        [
                                          _vm._v(" Consigné par "),
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    event.created_by_user
                                                      .contact_name ||
                                                      "Utilisateur"
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action-text",
                                { staticClass: "qs-text-blue" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(event.date)) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.userIsSuperadmin,
                  expression: "userIsSuperadmin"
                }
              ],
              attrs: { cols: "6" }
            },
            [
              _vm.lastAgaLoaded
                ? _c("qs-card", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pt-1 mr-2",
                                  attrs: {
                                    color: _vm.lastAgaTotal
                                      ? "error"
                                      : "success",
                                    "x-small": ""
                                  }
                                },
                                [_vm._v(" mdi-checkbox-blank-circle ")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "models.instances.counted_name",
                                      _vm.lastAgaTotal
                                    )
                                  ) +
                                  " à surveiller "
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "subtitle",
                          fn: function() {
                            return [
                              _vm._v(
                                " N'ayant pas tenu ou prévu d'AGA dans la dernière année "
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "v-list",
                                _vm._l(_vm.lastAgaItems, function(item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.id,
                                      attrs: {
                                        to: {
                                          name: "InstancesForm",
                                          params: {
                                            id: item.id,
                                            section: "events"
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-1 font-weight-bold text-uppercase"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          ),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.instance_type.name
                                                ) +
                                                " | " +
                                                _vm._s(item.region.name) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-action-text", [
                                        item.last_aga_date
                                          ? _c("span", [
                                              _vm._v(" Dernière AGA le"),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      item.last_aga_date
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v("Aucun AGA consignée")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _vm.lastAgaLoading
                                ? _c("v-skeleton-loader", {
                                    attrs: {
                                      type: "list-item-avatar-two-line@5",
                                      tile: ""
                                    }
                                  })
                                : _vm.lastAgaItems.length < 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      staticStyle: { height: "30vh" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "body-2 px-6 text-center"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "qs-light-blue",
                                                large: ""
                                              }
                                            },
                                            [_vm._v("mdi-rocket")]
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " Toutes les instances sont en règle 💯 "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      436934759
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }