var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { "content-class": "new-rocketchat-channels-form", value: true },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Nouveau canal ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", depressed: "", tile: "" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("qs-form-builder", {
        attrs: {
          error: _vm.error,
          form: _vm.form,
          item: _vm.item,
          "model-name": _vm.slug,
          order: _vm.formOrder
        },
        on: { input: _vm.syncItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }