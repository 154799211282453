







































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsDateRangeField from 'qs_vuetify/src/components/Fields/QsDateRangeField.vue';
import QsTextField from 'qs_vuetify/src/components/Fields/QsTextField.vue';
import QsToggleField from 'qs_vuetify/src/components/Fields/QsToggleField.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import axios from 'qs_vuetify/src/plugins/axios';

import { StoreQueryDefinition } from 'qs_vuetify/src/types/states';
import { Filter, PersistedInstance } from 'qs_vuetify/src/types/models';

const filters = namespace('filters');
const view = namespace('volunteersReportView');

@Component({
  components: {
    QsBaseLayout,
    QsButton,
    QsCard,
    QsDataTable,
    QsDateRangeField,
    QsTextField,
    QsToggleField,
    QsRelationField,
  },
  head: {
    title(this: InstancesForm) {
      const { title } = this.$store.state.global;
      return {
        inner: `${title || this.$route.meta.title}`,
      };
    },
  },
})
export default class InstancesForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @filters.Getter('data') filters!: Filter[];
  @filters.Getter('loaded') filtersLoaded!: Filter[];

  @view.Getter filtersItem!: {
    dates: string[];
    instances: PersistedInstance[];
  };

  data: {
    id: number;
    name: string;
    events: number;
    intention_absent: Record<string, number>;
    intention_maybe: Record<string, number>;
    intention_present: Record<string, number>;
    intention_absent_volunteers: Record<string, number>;
    intention_maybe_volunteers: Record<string, number>;
    intention_present_volunteers: Record<string, number>;
    registrations: number;
  }[] | null = null;

  details = false;
  filterName = '';
  loading = false;

  relationDefinition: StoreQueryDefinition = {
    key: 'user.inherited_instances',
    slug: 'auth',
    text: 'name',
    value: 'id',
  };

  showFilterName = false;

  get datesData(): Record<string, any>[] {
    const datesData: Record<string, string | number>[] = [];

    if (!this.data) {
      return [];
    }

    this.data.forEach((i) => {
      Object.keys(i.intention_absent).forEach((date, index) => {
        if (!datesData[index]) {
          datesData[index] = {
            date,
          };
        }
        if (this.details) {
          datesData[index][i.id] = `${i.intention_present_volunteers[date] + i.intention_maybe_volunteers[date]}`
            + ` militant·es présent·es ou peut-être (${i.intention_absent_volunteers[date]} militant·es absent·es)`
            + ` / ${i.intention_present[date] + i.intention_maybe[date]} présents ou peut-être (${i.intention_absent[date]} absents)`;
        } else {
          datesData[index][i.id] = `${i.intention_present_volunteers[date] + i.intention_maybe_volunteers[date]}`
            + ` (${i.intention_absent_volunteers[date]})`
            + ` / ${i.intention_present[date] + i.intention_maybe[date]} (${i.intention_absent[date]})`;
        }
      });
    });

    return datesData;
  }

  get filterValid(): boolean {
    return this.filtersItem.instances.length > 0 && this.filtersItem.dates.length === 2;
  }

  get headers(): Record<string, any>[] {
    if (!this.data) {
      return [
        { text: 'Date', value: 'date' },
      ];
    }

    return [
      { sortable: false, text: 'Date', value: 'date' },
      ...this.data.map((i) => ({
        sortable: false,
        text: i.name,
        value: `${i.id}`,
      })),
    ];
  }

  get instancesById(): Record<string, PersistedInstance> {
    return this.$store.state.auth.user.inherited_instances
      .reduce((acc: Record<string, PersistedInstance>, instance: PersistedInstance) => {
        acc[`${instance.id}`] = instance;
        return acc;
      }, ({} as Record<string, PersistedInstance>));
  }

  async generateReport() {
    this.loading = true;
    try {
      const { data: { data } } = await axios.get(
        '/instances/events/stats',
        {
          params: {
            id: this.filtersItem.instances.map((i) => i.id).join(','),
            'events.start_at': this.filtersItem.dates.join(':'),
            per_page: '*',
            order: 'name',
          },
        },
      );
      this.data = data;
    } finally {
      this.loading = false;
    }
  }

  async saveCurrentFilter() {
    if (this.filterName.length > 0) {
      await this.$store.dispatch('filters/create', {
        data: {
          name: this.filterName,
          filter: {
            id: this.filtersItem.instances.map((i) => i.id).join(','),
          },
          private: true,
          repository: 'Instance',
        },
        params: {},
      });

      this.showFilterName = false;
      this.filterName = '';

      this.reloadDataRoutesData(['filters.index']);
    }
  }

  selectAllInstances() {
    this.filtersItem.instances.push(...this.$store.state.auth.user.inherited_instances);
  }

  setFilter(filter: Filter) {
    this.unselectAllInstances();

    if (filter.filter && typeof filter.filter.id === 'string') {
      filter.filter.id.split(',').forEach((id) => {
        this.filtersItem.instances.push({ ...this.instancesById[id] });
      });
    }
  }

  showFilterNameAndFocusInput() {
    this.showFilterName = true;

    this.$nextTick(() => (this.$refs.filterNameInput as QsTextField).focus());
  }

  unselectAllInstances() {
    this.filtersItem.instances.splice(0, this.filtersItem.instances.length);
  }

  saveCurrentFilterIfEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.saveCurrentFilter();
    }
  }
}
