var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: [
        "base-item-card",
        "d-flex",
        "flex-no-wrap",
        "call-campaign-card",
        { "base-item-card call-campaign-card--archived": !!_vm.item.deleted_at }
      ],
      attrs: { flat: "", tile: "", to: _vm.to }
    },
    [
      _vm.item && _vm.item.image
        ? _c("v-img", {
            attrs: {
              "aspect-ratio": "1.9047",
              "max-width": "200",
              "min-width": "200",
              src: _vm.item.image.sizes.thumbnail
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex-grow-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex pb-1" },
            [
              _c(
                "div",
                { staticClass: "flex-grow text-truncate align-self-start" },
                [
                  _c(
                    "h2",
                    { staticClass: "text-h5 font-weight-bold my-0" },
                    [
                      _vm._v(" " + _vm._s(_vm.item.name) + " "),
                      _vm.item.newmode
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                color: "#C81E1D",
                                dark: "",
                                "x-small": ""
                              }
                            },
                            [_vm._v(" New/Mode ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.item.instances && _vm.item.instances.length
                    ? _c("p", { staticClass: "body-1 mt-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.item.instances
                                .map(function(i) {
                                  return i.name
                                })
                                .join(", ")
                            ) +
                            " "
                        )
                      ])
                    : _c("p", { staticClass: "body-1 mt-0" }, [
                        _vm._v(" Aucune instance ")
                      ])
                ]
              ),
              _c("v-spacer"),
              _c(
                "div",
                { staticClass: "flex-shrink align-self-start mt-1 text-right" },
                [
                  _vm.item.created_at === _vm.item.updated_at
                    ? _c(
                        "p",
                        { staticClass: "body-2" },
                        [
                          _vm._v(" Créée "),
                          _vm.item.created_by_user
                            ? [
                                _vm._v("par "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.item.created_by_user.contact_name
                                    )
                                  )
                                ]),
                                _c("br")
                              ]
                            : _vm._e(),
                          _vm._v(
                            " le " +
                              _vm._s(_vm._f("datetime")(_vm.item.created_at)) +
                              " "
                          )
                        ],
                        2
                      )
                    : _vm.item.deleted_at
                    ? _c(
                        "p",
                        { staticClass: "body-2" },
                        [
                          _vm._v(" Archivée "),
                          _vm.item.deleted_by_user
                            ? [
                                _vm._v("par "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.item.deleted_by_user.contact_name
                                    )
                                  )
                                ]),
                                _c("br")
                              ]
                            : _vm._e(),
                          _vm._v(
                            " le " +
                              _vm._s(_vm._f("datetime")(_vm.item.deleted_at)) +
                              " "
                          )
                        ],
                        2
                      )
                    : _c(
                        "p",
                        { staticClass: "body-2" },
                        [
                          _vm._v(" Dernière modification "),
                          _vm.item.updated_by_user
                            ? [
                                _vm._v("par "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.item.updated_by_user.contact_name
                                    )
                                  )
                                ]),
                                _c("br")
                              ]
                            : _vm._e(),
                          _vm._v(
                            " le " +
                              _vm._s(_vm._f("datetime")(_vm.item.updated_at)) +
                              " "
                          )
                        ],
                        2
                      )
                ]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            _vm._l(_vm.item.tags, function(tag) {
              return _c(
                "v-chip",
                {
                  key: tag.id,
                  staticClass: "mb-1 mr-1",
                  attrs: { color: "qs-blue", dark: "", label: "", small: "" }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2 mb-n1", attrs: { "x-small": "" } },
                    [_vm._v("mdi-tag")]
                  ),
                  _vm._v(" " + _vm._s(tag.name) + " ")
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }