var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "instance-events-modal",
        dark: false,
        height: "60vh",
        "max-width": "800",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.itemReady
                ? _c("span", [_vm._v(_vm._s(_vm.$store.state.global.subtitle))])
                : _c("span", [_vm._v("Chargement...")])
            ]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$store.state.global.title || _vm.$route.meta.title
                  ) +
                  " "
              )
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "#3b6470",
                    depressed: "",
                    disabled:
                      (!_vm.isNew && !_vm.hasChanged) || !_vm.routeDataLoaded,
                    loading: _vm.loading,
                    rounded: ""
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.item
        ? _c("qs-form-builder", {
            ref: "form",
            attrs: {
              "model-name": _vm.slug,
              form: _vm.form,
              item: _vm.item,
              error: _vm.error,
              order: ["instance_event_type_id", "comment", "date"]
            },
            on: {
              validation: function($event) {
                _vm.formValid = $event
              },
              input: _vm.syncItem
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _vm.instanceEventTypeHasForm
                        ? _c(
                            "div",
                            [
                              _c("p", { staticClass: "text-subtitle-2 mb-0" }, [
                                _vm._v("Informations complémentaires")
                              ]),
                              _c("p", { staticClass: "text-subtitle" }, [
                                _vm._v(_vm._s(_vm.instanceEventType.name))
                              ]),
                              _c("qs-form-builder", {
                                attrs: {
                                  "model-name": _vm.slug,
                                  form: _vm.instanceEventType.form_definition,
                                  item: _vm.item.meta
                                },
                                on: { input: _vm.syncMeta }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2807845668
            )
          })
        : _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "#006d3f" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }