var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "users", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.filtersDefinition,
                  "model-name": _vm.slug,
                  theme: "labase"
                },
                on: { input: _vm.setUsersParam },
                scopedSlots: _vm._u([
                  {
                    key: "contact.id.item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "v-list-item__content" }, [
                          _c("div", { staticClass: "v-list-item__title" }, [
                            _vm._v(_vm._s(item.full_name))
                          ]),
                          _c("div", { staticClass: "v-list-item__subtitle" }, [
                            _vm._v(" " + _vm._s(item.v1_contact_id) + " "),
                            item.email
                              ? _c("span", [_vm._v("· " + _vm._s(item.email))])
                              : _vm._e()
                          ])
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "12", md: "9" } },
            [
              _c(
                "div",
                { staticClass: "users__top-bar mb-3" },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "", outlined: "", tile: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex" },
                        [
                          _vm.selectedItems.length > 0 || _vm.items.length > 0
                            ? _c("v-checkbox", {
                                staticClass: "mt-1",
                                attrs: {
                                  "hide-details": "",
                                  "input-value": _vm.allSelected,
                                  "true-value": true,
                                  "false-value": false,
                                  indeterminate: _vm.someSelected
                                },
                                on: { change: _vm.selectAll }
                              })
                            : _vm._e(),
                          _vm.selectedItems.length > 0
                            ? _c(
                                "span",
                                { staticClass: "users__top-bar__text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("number")(
                                          _vm.selectedItems.length
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "models.users.selected",
                                          _vm.selectedItems.length
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c("div", { staticClass: "users__top-bar__text" }, [
                            _c(
                              "span",
                              [
                                _vm.loading
                                  ? _c("v-progress-circular", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        indeterminate: "",
                                        color: "primary",
                                        size: "16"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.items.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("number")(_vm.items.length)
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "models.users.name",
                                              _vm.items.length
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.total > 0
                                  ? _c("span", [_vm._v(" sur ")])
                                  : _vm._e(),
                                _vm.total > 0
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm._f("number")(_vm.total))
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "ml-6" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "qs-button",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "users__actions-button",
                                                  attrs: {
                                                    color: "qs-blue",
                                                    text: "",
                                                    tile: ""
                                                  }
                                                },
                                                "qs-button",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-2 mr-2" },
                                                [_vm._v("Actions")]
                                              ),
                                              _c("v-icon", [
                                                _vm._v("mdi-menu-down")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.actions, function(ref, index) {
                                      var callback = ref.callback
                                      var disabled = ref.disabled
                                      var icon = ref.icon
                                      var label = ref.label
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: "qs-list__action-" + index,
                                          attrs: { disabled: disabled },
                                          on: { click: callback }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "qs-light-blue"
                                                  }
                                                },
                                                [_vm._v(_vm._s(icon))]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-content", [
                                            _vm._v(" " + _vm._s(label) + " ")
                                          ])
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.$slots.settings
                            ? _c(
                                "div",
                                { staticClass: "ml-6" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        bottom: "",
                                        "offset-y": "",
                                        "close-on-content-click": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "qs-button",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "qs-list__settings-button",
                                                        attrs: {
                                                          color: "qs-blue",
                                                          text: "",
                                                          tile: ""
                                                        }
                                                      },
                                                      "qs-button",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-cog")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3591930323
                                      )
                                    },
                                    [_vm._t("settings")],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("qs-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  options: _vm.options,
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  "set-options": _vm.setOptions,
                  tile: "",
                  total: _vm.total || 0
                },
                on: {
                  edit: function($event) {
                    return _vm.goto("users", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.contact",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        !!item.contact
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(item.contact.full_name) + " "
                              ),
                              _c("small", [
                                _vm._v(
                                  "(" + _vm._s(item.contact.v1_contact_id) + ")"
                                )
                              ])
                            ])
                          : _c("span", [
                              _c("em", [_vm._v("Pas de contact associé")])
                            ])
                      ]
                    }
                  },
                  {
                    key: "item.selected",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("v-checkbox", {
                          staticClass: "flex-shrink align-self-start mt-1",
                          attrs: {
                            "false-value": false,
                            "hide-details": "",
                            "input-value": _vm.isSelected(item),
                            "true-value": true
                          },
                          on: {
                            change: function($event) {
                              return _vm.updateSelectedItems($event, item)
                            },
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.$route.params.id ? _c("router-view") : _vm._e(),
      _c(
        "merge-users-dialog",
        _vm._b(
          {
            attrs: { items: _vm.selectedItems },
            on: {
              "click:close": _vm.clearMergeUsersAction,
              "click:save": _vm.mergeUsers,
              "input:target": function($event) {
                _vm.mergeUsersAction.target = $event
              }
            }
          },
          "merge-users-dialog",
          _vm.mergeUsersAction,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }