var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statsLoaded
    ? _c(
        "div",
        { staticClass: "instance-stats" },
        [
          _c("qs-instance-stats-card", {
            attrs: {
              color: "green",
              count: _vm.stats.contacts.MER,
              "previous-count": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.meta.contacts.MER
                : _vm.lastStatsEvent,
              "previous-date": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.created_at
                : _vm.lastStatsEvent,
              status: "MER"
            }
          }),
          _c("qs-instance-stats-card", {
            attrs: {
              color: "yellow",
              count: _vm.stats.contacts.MEC,
              "previous-count": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.meta.contacts.MEC
                : _vm.lastStatsEvent,
              "previous-date": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.created_at
                : _vm.lastStatsEvent,
              status: "MEC"
            }
          }),
          _c("qs-instance-stats-card", {
            attrs: {
              color: "red",
              count: _vm.stats.contacts.EXM,
              "previous-count": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.meta.contacts.EXM
                : _vm.lastStatsEvent,
              "previous-date": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.created_at
                : _vm.lastStatsEvent,
              status: "EXM"
            }
          }),
          _c("qs-instance-stats-card", {
            attrs: {
              color: "medium-blue",
              count: _vm.stats.contacts.CTC,
              "previous-count": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.meta.contacts.CTC
                : _vm.lastStatsEvent,
              "previous-date": _vm.lastStatsEvent
                ? _vm.lastStatsEvent.created_at
                : _vm.lastStatsEvent,
              status: "CTC"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "d-flex justify-center align-center",
          staticStyle: { height: "108px" }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }