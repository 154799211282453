var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qs-instance-events-list" },
    [
      _vm.items.length > 0
        ? _c(
            "v-list",
            _vm._l(_vm.items, function(event) {
              return _c(
                "v-list-item",
                { key: event.id, staticClass: "qs-instance-events-list__item" },
                [
                  event.instance_event_type
                    ? _c(
                        "v-list-item-avatar",
                        { staticClass: "font-weight-bold" },
                        [
                          _c("v-icon", [
                            _vm._v(
                              _vm._s(
                                _vm._f("icon")(event.instance_event_type.name)
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item-content",
                    [
                      event.instance_event_type
                        ? _c(
                            "v-list-item-title",
                            { staticClass: "overline qs-text-blue" },
                            [
                              _vm._v(
                                " " + _vm._s(event.instance_event_type.name)
                              )
                            ]
                          )
                        : _vm._e(),
                      event.comment
                        ? _c(
                            "v-list-item-subtitle",
                            { staticClass: "qs-text-blue" },
                            [_vm._v(" " + _vm._s(event.comment) + " ")]
                          )
                        : _vm._e(),
                      event.created_by_user
                        ? _c(
                            "v-list-item-subtitle",
                            { staticClass: "caption qs-text-blue" },
                            [
                              _vm._v(" Consigné par "),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      event.created_by_user.contact_name ||
                                        "Utilisateur"
                                    )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action-text",
                    { staticClass: "qs-text-blue" },
                    [_vm._v(" " + _vm._s(_vm._f("date")(event.date)) + " ")]
                  )
                ],
                1
              )
            }),
            1
          )
        : _c(
            "div",
            {
              staticClass: "d-flex flex-column justify-center align-center",
              staticStyle: { height: "40vh" }
            },
            [
              _c("v-icon", { attrs: { color: "#dce5db", "x-large": "" } }, [
                _vm._v("mdi-comment-alert-outline")
              ]),
              _c("p", [_vm._v("Aucun suivi sur cette instance")])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }