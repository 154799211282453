


























































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import QsSelectDateInterval from 'qs_vuetify/src/components/Filters/QsSelectDateInterval.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { Filter } from 'qs_vuetify/src/types/models';
import { Form } from 'qs_vuetify/src/types/components';
import { normalizeString } from 'qs_vuetify/src/plugins/helpers';
import {
  FiltersDefinition,
  RelationQueryDefinition,
  RestParams,
  StoreQueryDefinition,
} from 'qs_vuetify/src/types/states';

interface ActiveAvailableFilter {
  key: string;
  value: any;
}

interface AvailableFilter {
  label: string;
  name: string;
  def: any;
}

@Component({
  components: {
    QsSelectDateInterval,
    QsRelationField,
  },
  filters: {
    filterTypeIcon(filterType: string) {
      switch (filterType) {
        case 'boolean':
          return 'mdi-toggle-switch';
        case 'text':
          return 'mdi-form-textbox';
        case 'enum':
          return 'mdi-form-select';
        case 'date':
          return 'mdi-calendar';
        case 'datetime':
          return 'mdi-calendar';
        case 'relation':
          return 'mdi-card-search-outline';
        case 'number':
          return 'mdi-counter';

        default:
          return 'mdi-filter';
      }
    },
  },
})
export default class QsSearch extends mixins(I18nMixin) {
  @Prop({ required: true, type: Object, default: () => ({}) }) activeFilters!: RestParams;
  @Prop({ required: false, type: Boolean, default: false }) dense!: boolean;
  @Prop({ required: false, type: Array, default: () => [] }) filters!: Filter[];
  @Prop({ required: true, type: Object, default: () => ({}) }) filtersDefinition!: FiltersDefinition;
  @Prop({ required: false, type: Array, default: () => [] }) hiddenFilterSlugs!: string[];
  @Prop({ required: false, type: Boolean, default: false }) keepOpen!: boolean;
  @Prop({ required: true, type: String }) modelName!: string;
  @Prop({ required: false, type: Array, default: () => [] }) order!: string[];
  @Prop({ required: false, type: Object, default: () => ({}) }) schemas!: Record<string, Form>;
  @Prop({ required: false, type: String, default: '' }) title!: string;
  @Prop({ required: false, type: Boolean, default: true }) withSearch!: boolean;

  allFilter: RestParams = {};
  dateRangeRegexp: RegExp;
  editedFilter = null;
  isActive: boolean = false;
  updateFilter: any = null;
  updateRelationQueryFilter = null;
  updateDateRangeFilter = null;
  search = null;
  searchText: string = '';

  updateFilterNow = (name: string, value: any) => {
    this.$emit('input', name, value);
  };

  constructor() {
    super();

    this.dateRangeRegexp = /((\d{4}-\d{2}-\d{2})?( ?\d{2}:\d{2}:\d{2})?)?(:((\d{4}-\d{2}-\d{2})?( ?(\d{2}:\d{2}:\d{2})?)))?/;

    this.updateFilter = this.$helpers.debounce((name: string, value: any) => {
      let preparedValue = value;
      if (Array.isArray(value)) {
        preparedValue = value.join(',');
      }

      if (this.activeFilters[name] !== preparedValue) {
        this.updateFilterNow(name, preparedValue);
      }
    }, 500);

    this.updateRelationQueryFilter = this.$helpers.debounce((name: string, value: any) => {
      if (!value) {
        this.$emit('input', name, value);
      } else {
        this.$emit('input', name, value.id);
      }
    }, 500);

    this.updateDateRangeFilter = this.$helpers.debounce(
      (index: number, name: string, value: any) => {
        const currentFilter = `${this.activeFilters[name]}` || ':';
        const parts = currentFilter.match(this.dateRangeRegexp);

        let fromPart = '';
        let toPart = '';
        if (parts) {
          fromPart = parts[1] || '';
          toPart = parts[5] || '';
        }
        switch (index) {
          case 0:
            this.$emit('input', name, `${value || ''}:${toPart}`);
            break;
          case 1:
            this.$emit('input', name, `${fromPart}:${value || ''}`);
            break;
          default:
            break;
        }
      },
      500,
    );

    this.search = this.$helpers.debounce((
      name: string,
      query: RelationQueryDefinition,
      value: any,
    ) => {
      const lastValue = this.$store.state[query.slug]?.lastSearchQuery;
      if (value && value.length >= 3 && value !== lastValue) {
        this.$store.dispatch(`${query.slug}/search`, { q: value, params: query.params });
      }
    }, 500);
  }

  restrictedParams = [
    'accessible_from',
    'fields',
    'order',
    'page',
    'per_page',
    'q',
  ];

  get activeEditableFilters(): Array<ActiveAvailableFilter> {
    const filters: Array<ActiveAvailableFilter> = [];
    const target = this.order.length === 0
      ? Object.keys(this.activeFilters)
      : this.order.filter((o) => Object.keys(this.activeFilters).includes(o));

    return target
      .filter((f) => !this.restrictedParams.includes(f))
      .filter((f) => !this.hiddenFilterSlugs.includes(f))
      .reduce((acc, key) => {
        acc.push({
          key,
          value: this.activeFilters[key],
        });

        return acc;
      }, filters);
  }

  get availableFilters(): Array<AvailableFilter> {
    const filters: Array<AvailableFilter> = [];
    const target = this.order.length === 0 ? Object.keys(this.filtersDefinition) : this.order;

    return target
      .filter((f) => !this.restrictedParams.includes(f))
      .filter((f) => Object.keys(this.filtersDefinition).includes(f))
      .reduce((acc, name) => {
        acc.push({
          label: this.labelOrNothing(name, 'filters') || this.labelOrName(name),
          name,
          def: this.filtersDefinition[name] || {
            type: 'not_found',
          },
        });
        return acc;
      }, filters)
      .sort((a: AvailableFilter, b: AvailableFilter): number => {
        const normalizedA = normalizeString(a.label);
        const normalizedB = normalizeString(b.label);

        if (normalizedA === normalizedB) {
          return 0;
        }

        return normalizedA < normalizedB ? -1 : 1;
      });
  }

  get currentFilterInFiltersOrNone(): RestParams | null {
    if (Object.keys(this.activeFilters).length === 0) {
      return this.allFilter;
    }

    const activeFiltersJson = JSON.stringify(this.activeFilters);
    for (let i = 0, len = this.filters.length; i < len; i += 1) {
      if (JSON.stringify(this.filters[i].filter) === activeFiltersJson) {
        return this.filters[i].filter || null;
      }
    }

    return null;
  }

  get hasFilter() {
    return Object.keys(this.filtersDefinition).length > 0;
  }

  get onlyHasSearch(): boolean {
    return Object.keys(this.filtersDefinition).length === 1 && !!this.filtersDefinition.q;
  }

  get searchData() {
    return Object.values(this.filtersDefinition).reduce((acc, k, idx) => {
      if (k.query) {
        acc[k.query.slug] = [
          ...this.$store.state[k.query.slug].search,
        ];
        const name = Object.keys(this.filtersDefinition)[idx];

        if (this.activeFilters[name]) {
          acc[k.query.slug].push({
            [k.query.value]: this.activeFilters[name],
            [k.query.text]: this.activeFilters[name],
          });
        }
      }

      if (k.store) {
        const name = Object.keys(this.filtersDefinition)[idx];

        if (!this.$store.state[k.store.slug]) {
          return acc;
        }

        acc[k.store?.slug] = this.$store.state[k.store.slug].data.find((i: any) => {
          if (k.store?.value) {
            return i[k.store?.value] === this.activeFilters[name];
          }

          return false;
        });
      }

      return acc;
    }, ({} as Record<string, any>));
  }

  cancelAllFilters() {
    this.activeEditableFilters.forEach(({ key }) => {
      this.$emit('input', key, null);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  clickOutsideInclude() {
    return [document.querySelector('.search-bar')];
  }

  getDateRangePart(index: number, value: string | null): string {
    if (!value) {
      return '';
    }

    const parts = value.match(this.dateRangeRegexp);

    if (!parts) {
      return '';
    }

    switch (index) {
      case 0:
        return parts[1] || '';
      case 1:
        return parts[5] || '';
      default: // Should not happen
        return '';
    }
  }

  getFilterValueAsArray(name: string) {
    const filter: {
      type: 'text' | 'query' | 'enum' | 'relation';
      query?: RelationQueryDefinition;
      store?: StoreQueryDefinition;
      values?: string[];
    } = this.filtersDefinition[name];

    const value: string = (this.activeFilters[name] as string);

    if (filter && value) {
      if (filter.type === 'relation') {
        return value.split(',').map((v) => parseInt(v, 10)).filter((v) => !Number.isNaN(v));
      }

      return value.split(',');
    }

    return [value];
  }

  setActive() {
    if (!this.isActive) {
      this.isActive = true;

      this.$nextTick(() => {
        (this.$refs.searchText as any).focus();
      });
    }
  }

  setInactive() {
    if (this.isActive) {
      this.isActive = false;
    }
  }

  updateSelectFilter(name: string, value: string | string[]) {
    if (this.updateFilter && Array.isArray(value)) {
      this.updateFilter(name, value.join(','));
      return;
    }

    if (this.updateFilter && typeof value === 'string') {
      this.updateFilter(name, value);
    }
  }
}
