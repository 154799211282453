var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        tile: "",
        to: _vm.item.deleted_at
          ? null
          : { name: "RocketchatChannelsForm", params: { id: _vm.item.id } }
      }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex" },
        [
          _c("span", { staticClass: "text-h3 font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.item.name) + " ")
          ]),
          _c("v-spacer"),
          _vm.item.deleted_at && _vm.restorable
            ? _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var hover = ref.hover
                        return [
                          _c("qs-button", {
                            attrs: {
                              color: hover ? "error" : "qs-light-blue",
                              icon: hover
                                ? "mdi-archive-arrow-up"
                                : "mdi-archive",
                              label: "Restaurer",
                              tile: "",
                              theme: "labase"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("click:restore")
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3113574462
                )
              })
            : _c(
                "v-icon",
                { staticClass: "mr-4", attrs: { color: "qs-light-blue" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.item.public ? "mdi-lock-open" : "mdi-lock") +
                      " "
                  )
                ]
              )
        ],
        1
      ),
      _c("v-card-subtitle", { staticClass: "caption" }, [
        _vm._v(
          " Canal " +
            _vm._s(_vm.item.public ? "public" : "privé") +
            " à synchronisation " +
            _vm._s(
              _vm.item.sync_add_only ? "unidirectionnelle" : "bidirectionnelle"
            ) +
            " "
        )
      ]),
      _c("v-card-text", { staticClass: "body-2" }, [
        _vm._v(" " + _vm._s(_vm.item.description) + " ")
      ]),
      _c(
        "v-card-subtitle",
        [
          _c(
            "v-chip",
            {
              staticClass: "mr-2",
              attrs: {
                color: _vm.item.users_count ? "success" : "error",
                small: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.item.users_count) + " participant·es ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }