var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { "content-class": "new-petitions-form", value: true },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Nouvelle pétition ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                { attrs: { theme: "labase" }, on: { click: _vm.submitForm } },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.item
        ? _c("qs-form-builder", {
            attrs: {
              errors: _vm.error,
              form: _vm.form,
              item: _vm.item,
              "model-name": _vm.slug,
              order: ["name"]
            },
            on: { input: _vm.syncItem }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }