import { render, staticRenderFns } from "./Instances.vue?vue&type=template&id=0934b17a&"
import script from "./Instances.vue?vue&type=script&lang=ts&"
export * from "./Instances.vue?vue&type=script&lang=ts&"
import style0 from "./Instances.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCol,VContainer,VList,VListItem,VRow,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_vie_associative/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0934b17a')) {
      api.createRecord('0934b17a', component.options)
    } else {
      api.reload('0934b17a', component.options)
    }
    module.hot.accept("./Instances.vue?vue&type=template&id=0934b17a&", function () {
      api.rerender('0934b17a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Instances/Instances.vue"
export default component.exports