




















































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';

import dayjs from 'qs_vuetify/src/plugins/dayjs';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsCombinedRightsTable from '@/components/QsCombinedRightsTable.vue';
import QsContactListItem from '@/components/QsContactListItem.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { Form, SelectItem, DataTableOptions } from 'qs_vuetify/src/types/components';
import {
  Contact,
  Instance,
  InstanceUser,
  PersistedRight,
  RightGroup,
  User,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

type CombinedRights = { [key: string]: { [key: string]: PersistedRight } };

const global: any = namespace('global');
const instances: any = namespace('instances');
const instanceUsers: any = namespace('instance_users');
const rightGroups: any = namespace('right_groups');
const rights: any = namespace('rights');
const view: any = namespace('instancesView');

@Component({
  components: {
    QsActionModal,
    QsBooleanIndicator,
    QsCombinedRightsTable,
    QsContactListItem,
    QsFormBuilder,
    QsRelationField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceUsersForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Mutation addNotification!: Function;
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @instances.Getter('item') instance!: Instance | null;

  @instanceUsers.Getter error!: ErrorResponse;
  @instanceUsers.Getter form!: Form;
  @instanceUsers.Getter item!: InstanceUser;
  @instanceUsers.Getter loading!: boolean;
  @instanceUsers.Getter slug!: string;
  @instanceUsers.Mutation('item') syncItem!: any

  @rightGroups.Getter('data') rightGroups!: Array<RightGroup>;
  @rights.Getter('data') rights!: Array<PersistedRight>;

  @view.Getter instanceUsersOptions!: DataTableOptions;
  @view.Getter instanceUsersParams!: RestParams;

  @Prop([String, Number]) id!: string | number;
  @Prop([String, Number]) instanceUserId!: string | number;
  @Prop([String, Number]) section!: string | number;

  tab = 0;
  user!: User;
  formOrder = [
    'start_at',
    'end_at',
  ];

  rightRelationFieldDefinition: any = {
    query: {
      key: 'data',
      slug: 'rights',
      text: 'description',
      value: 'id',
    },
  };

  contact: Contact | null = null;

  queryDefinition: any = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      emails: 1,
      fields: [
        'district.name',
        'email',
        'full_name',
        'searchable_text',
        'status',
        'user.id',
        'v1_contact_id',
      ].join(','),
    },
  };

  search: (func: Function, wait: number, immediate?: boolean) => void;

  mounted() {
    this.onRouteChanged();
  }

  constructor() {
    super();

    this.search = this.$helpers.debounce((value: any) => {
      const lastValue = this.$store.state.contacts.lastSearchQuery;
      if (value && value.length >= 3 && value !== lastValue) {
        this.$store.dispatch('contacts/search', { q: value });
      }
    }, 500);
  }

  get contacts(): Array<SelectItem> {
    return this.$store.state.contacts.search.map((item: Contact) => ({
      value: item.id,
      text: item.full_name,
    }));
  }

  get itemReady(): boolean {
    if (this.instanceUserId === 'new') {
      return !!this.item;
    }

    if (typeof this.instanceUserId === 'string') {
      return this.item?.id === parseInt(this.instanceUserId, 10);
    }

    return this.item?.id === this.instanceUserId;
  }

  get rightIds(): number[] {
    if (this.item && this.item.rights) {
      return (this.item.rights as Array<PersistedRight>).map((r: PersistedRight) => r.id);
    }

    return [];
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'instance_users.index': {
        ...ListMixin.buildListState(this.instanceUsersOptions, this.instanceUsersParams),
      },
      instance_users: {
        fields: [
          '*',
          'rights.*',
          'user.contact.district.name',
          'user.contact.email',
          'user.contact.full_name',
          'user.contact.searchable_text',
          'user.contact.status',
          'user.contact.v1_contact_id',
        ].join(','),
      },
    };
  }

  @Watch('itemReady')
  onItemReady(ready: boolean) {
    if (ready) {
      this.item.instance_id = parseInt(this.id.toString(), 10);
      this.setGlobalSubtitle();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('contact')
  onContactChanged(contact: Contact | null) {
    if (contact) {
      if (contact.user?.id) {
        this.syncItem({
          ...this.item,
          user_id: contact.user.id,
          user: {
            contact,
            id: contact.user.id,
          },
        });
      } else {
        this.syncItem({
          ...this.item,
          user_id: null,
          user: {
            contact,
            email: contact.email,
            id: null,
          },
        });
      }
    }
  }

  closeModal() {
    this.$store.commit(`${this.slug}/item`, null);

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else if (this.$route.name === 'InstanceUsersForm') {
      this.$router.push({
        name: 'InstancesForm',
        params: {
          id: `${this.instance?.id}`,
          section: 'users',
        },
      });
    }
  }

  endRights(): void {
    const now = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    if (this.item) {
      this.item.end_at = now;
    }
    this.submitForm();
  }

  onToggleRights(included: boolean, newRights: PersistedRight[]) {
    const newRightIds: number[] = [...(new Set(newRights.map((r) => r.id)))];

    if (included) {
      this.syncItem({
        ...this.item,
        rights: this.rightIds.concat(newRightIds).map((id) => ({ id })),
      });
    } else {
      this.syncItem({
        ...this.item,
        rights: this.rightIds
          .filter((rId: number) => !newRightIds.includes(rId))
          .map((id) => ({ id })),
      });
    }
  }

  onToggleRight(included: boolean, newRight: PersistedRight) {
    this.onToggleRights(included, [newRight]);
  }

  hasAllRights(rights: PersistedRight[]) { // eslint-disable-line no-shadow
    return rights
      .map((right) => right.id || 0)
      .every((id) => this.rightIds.includes(id));
  }

  hasSomeRights(rights: PersistedRight[]) { // eslint-disable-line no-shadow
    return rights
      .map((right) => right.id || 0)
      .some((id) => this.rightIds.includes(id));
  }

  hasRight(right: PersistedRight) {
    return this.hasAllRights([right]);
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit(
        'global/subtitle', this.item?.user?.contact?.full_name
        || 'Nouvel utilisateur',
      );
    } else {
      this.$store.commit(
        'global/subtitle', this.item?.user?.contact?.full_name
        || `Utilisateur #${this.item.id}`,
      );
    }
    this.$emit('updateHead');
  }

  async createUser() {
    const data: RestParams = {};

    if (this.contact) {
      const { id, email } = this.contact;
      data.contact_id = id;
      data.email = String(email);
    } else if (this.item.user?.contact) {
      const { id, email } = this.item.user.contact;
      data.contact_id = id;
      data.email = String(email);
    } else {
      return;
    }

    try {
      await this.$store.dispatch('users/create', {
        data,
        params: {},
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Ce contact n'a pas d'utilisateur associé"
          + " et vous n'avez pas les permissions requises pour le créer. Pour utiliser"
          + " les outils de Québec solidaire, il faut s'être connecté au moins une fois sur"
          + ' Profil ou La Base, ou avoir un compte utilisateur valide.',
        timeout: -1,
      });
      return;
    }


    const user = this.$store.state.users.item;
    this.syncItem({
      ...this.item,
      user_id: user.id,
      user,
    });
  }

  async submitForm() {
    if (!this.item.user_id) {
      await this.createUser();
    }

    await this.submit();

    await this.$store.commit('instance_users/lastLoadedAt', null);

    this.closeModal();

    this.reloadDataRoutesData(['instance_users']);
  }
}
