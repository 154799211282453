





































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { InstanceRoleType } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('instance_types');
const view: any = namespace('instanceTypesViews');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('instance_types/data', []);
    this.$store.commit('instance_types/loaded', false);
    next();
  },
  components: {
    QsBooleanIndicator,
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceTypesList
  extends mixins(AuthenticationMixin, DataRouteGuards, ListMixin) {
  @store.Getter data!: Array<InstanceRoleType>;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  mounted() {
    this.setAction();
  }

  setAction() {
    if (this.userHas('INSTANCE_TYPES_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/instance_types/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      instance_types: ListMixin.buildListState(this.options, this.params),
    };
  }

  setInstanceTypesParam = this.buildSetParam('instance_types', this.setParams);

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setAction();
    this.$emit('updateHead');
  }

  @Watch('$route', { deep: true })
  onRouteChanged($route: Route, $oldRoute: Route) {
    if ($oldRoute.params.id && !$route.params.id) {
      this.setAction();
      this.reloadDataRoutesData();
    }
  }

  headers = [
    // { text: 'ID', value: 'id' },
    { text: 'Nom', value: 'name' },
    { text: 'Exclusive', value: 'exclusive' },
    { text: 'Peut déléguer', value: 'can_delegate' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
}
