





































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { InstanceEvent } from 'qs_vuetify/src/types/models';

@Component({
  filters: {
    icon(name: string): string {
      switch (name) {
        case 'Assemblée générale de fondation':
        case 'Création':
          return 'mdi-plus-circle';
        case 'Dissolution':
        case 'Fermeture':
          return 'mdi-close-circle';
        case 'Reconnaissance d\'Élections Québec':
        case 'Reconnaissance du parti':
          return 'mdi-stamper';
        case 'Suivi':
          return 'mdi-comment';
        default:
          return 'mdi-calendar';
      }
    },
  },
})
export default class QsInstanceEventsList extends Vue {
  @Prop({ required: true, type: Array }) items!: Array<InstanceEvent>;
}
