import type { RouteConfig } from 'vue-router';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

const InstanceRolesList = () => import('@/views/InstanceRoles/InstanceRolesList.vue');
const InstanceRolesDialog = () => import('@/views/InstanceRoles/InstanceRolesDialog.vue');

const instanceRoles: RouteConfig[] = [
  {
    path: '/roles',
    name: 'InstanceRolesList',
    component: InstanceRolesList,
    meta: {
      data: {
        instance_role_responsibilities: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.instance_role_responsibilities.lastLoadedAt;
            },
            params: {
              per_page: '*',
            },
          },
        },
        instance_role_types: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.instance_role_types.lastLoadedAt;
            },
            params: {
              per_page: '*',
            },
          },
        },
        instance_roles: {
          index: {
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.instancesView.instanceRoles;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
            params: {
              fields: [
                'id',
                'contact_name',
                'contact.email',
                'end_at',
                'instance_name',
                'is_active',
                'responsibilities.name',
                'start_at',
                'type_name',
              ].join(','),
            },
          },
        },
      },
      title: "Rôles d'instance",
      menus: ['primary'],
      requires: ['INSTANCE_ROLES_RETRIEVE'],
    },
    children: [
      {
        path: ':id',
        name: 'InstanceRolesDialog',
        component: InstanceRolesDialog,
        props: true,
        meta: {
          data: {
            instance_role_types: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_role_types.lastLoadedAt;
                },
                params: {
                  per_page: '*',
                },
              },
            },
            instance_roles: {
              index: {
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.instancesView.instanceRoles;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
                params: {
                  fields: [
                    'id',
                    'contact_name',
                    'contact.email',
                    'end_at',
                    'instance_name',
                    'is_active',
                    'responsibilities.name',
                    'start_at',
                    'type_name',
                  ].join(','),
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                params: {
                  fields: [
                    'contact.age',
                    'contact.birthdate',
                    'contact.district.name',
                    'contact.email',
                    'contact.full_name',
                    'contact.postal_code',
                    'contact.status',
                    'contact.v1_contact_id',
                    'created_at',
                    'default_instance.name',
                    'email',
                    'active_instance_users.instance.name',
                    'active_instance_users.start_at',
                    'active_instance_users.end_at',
                    'active_instance_users.rights_slugs',
                    'superadmin',
                  ].join(','),
                },
              },
            },
          },
          title: "Rôle d'instance",
          menus: [],
          requires: ['INSTANCE_ROLES_RETRIEVE'],
        },
      },
    ],
  },
];

export default instanceRoles;
