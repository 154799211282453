export default {
  sections: {
    contacts: 'Membres',
    events: 'Suivis',
    grants: 'Rôles et permissions',
    election2022: 'Élection 2022',
    roles: 'Rôles',
    settings: 'Paramètres',
    summary: 'Résumé',
    users: 'Permissions',
  },
  grants: {
    instance_roles: {
      add_new: 'Ajouter un rôle',
      inactive: 'Rôles inactifs',
      no_active: 'Aucun rôle actif',
      title: 'Rôles',
    },
    instance_users: {
      add_new: 'Ajouter une permission',
      inactive: 'Permissions inactives',
      no_active: 'Aucune permission active',
      title: 'Permissions',
    },
  },
};
