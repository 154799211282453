






























import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { RightGroup } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('right_groups');
const view: any = namespace('rightGroupsViews');

@Component({
  components: {
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class RightGroups
  extends mixins(AuthenticationMixin, DataRouteGuards, ListMixin) {
  @store.Getter data!: Array<RightGroup>;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  mounted() {
    this.setAction();
  }

  setAction() {
    if (this.userHas('RIGHT_GROUPS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/right_groups/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      right_groups: ListMixin.buildListState(this.options, this.params),
    };
  }

  setRightGroupsParam = this.buildSetParam('right_groups', this.setParams);

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setAction();
    this.$emit('updateHead');
  }

  @Watch('$route', { deep: true })
  onRouteChanged($route: Route, $oldRoute: Route) {
    if ($oldRoute.params.id && !$route.params.id) {
      this.setAction();
      this.reloadDataRoutesData();
    }
  }

  headers = [
    // { text: 'ID', value: 'id' },
    { text: 'Nom', value: 'name' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
}
