



































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { Right } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const rights: any = namespace('rights');
const view: any = namespace('rightsViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class RightsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @rights.Getter error!: ErrorResponse;
  @rights.Getter item!: Right;
  @rights.Getter form!: Form;
  @rights.Getter loading!: boolean;
  @rights.Getter slug!: string;
  @rights.Mutation('item') syncItem!: any

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      rights: ListMixin.buildListState(this.options, this.params),
    };
  }

  mounted() {
    this.onRouteChanged();
  }

  closeModal() {
    this.$router.push({ name: 'RightsList' });
  }

  setAction() {
    if (this.userHas('RIGHTS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/rights/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit('global/subtitle', this.item?.slug || 'Nouveau droit');
    } else {
      this.$store.commit('global/subtitle', this.item?.slug || `Droit #${this.item.id}`);
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    await this.submit();

    this.reloadDataRoutesData(['rights.index']);

    this.closeModal();
  }

  @Watch('item.slug')
  onItemNameChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
    this.setAction();
  }
}
