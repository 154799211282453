

























































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import { InstanceEvent } from 'qs_vuetify/src/types/models';

import WarningIndicator from '@/components/Instances/WarningIndicator.vue';

const instanceEvents: any = namespace('instance_events');

@Component({
  components: {
    WarningIndicator,
  },
})
export default class InstanceEventsDetail extends mixins(
  DataRouteGuards,
) {
  @instanceEvents.Getter('item') instanceEvent!: InstanceEvent;

  get hasMetaMinutesContacts(): boolean {
    if (this.instanceEvent && this.instanceEvent.meta && this.instanceEvent.meta.contacts) {
      return true;
    }

    return false;
  }

  get hasMetaMinutesLink(): boolean {
    if (this.instanceEvent && this.instanceEvent.meta && this.instanceEvent.meta.minutes_link) {
      return true;
    }

    return false;
  }

  get hasMetaQuorum(): boolean {
    if (this.instanceEvent && this.instanceEvent.meta && this.instanceEvent.meta.quorum) {
      return true;
    }

    return false;
  }
}
