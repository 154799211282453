


































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { normalizeString } from 'qs_vuetify/src/plugins/helpers';

import { PersistedRight } from 'qs_vuetify/src/types/models';

type CombinedRights = { [key: string]: { [key: string]: PersistedRight } };

@Component
export default class QsCombinedRightsTable extends Vue {
  @Prop({ required: true, type: Array }) rights!: PersistedRight[];
  @Prop({ required: true, type: Array }) value!: PersistedRight[]

  combinedRights: CombinedRights = {};
  otherRights: Array<PersistedRight> = [];
  search: string = '';

  mounted() {
    this.assembleCombinedRights();
  }

  get filteredModels(): CombinedRights {
    const filter = new RegExp(normalizeString(this.search), 'i');

    return Object.keys(this.combinedRights)
      .filter((key) => {
        if (this.$i18n.te(`models.${key}.name`)) {
          return normalizeString(this.$i18n.tc(`models.${key}.name`, 2)).match(filter);
        }

        return `models.${key}.name`.match(filter);
      })
      .reduce((acc, key) => {
        acc[key] = this.combinedRights[key];
        return acc;
      }, {} as CombinedRights);
  }

  get rightIds(): number[] {
    if (this.value) {
      return (this.value as Array<PersistedRight>).map((r: PersistedRight) => r.id);
    }

    return [];
  }

  @Watch('rights')
  onRightsChanged(val: PersistedRight[]) {
    if (val) {
      this.assembleCombinedRights();
    }
  }

  assembleCombinedRights(): void {
    if (Object.keys(this.combinedRights).length === 0) {
      this.combinedRights = this.rights
        .sort((a, b) => {
          const aMatches = a.slug.match(/(\w+)_\w+/);
          let aTranslation;
          if (aMatches) {
            const aName = aMatches[1].toLowerCase();
            aTranslation = this.$i18n.te(`models.${aName}.name`)
              ? this.$i18n.tc(`models.${aName}.name`, 2).toLowerCase()
              : aName;
          } else {
            aTranslation = a.slug.toLowerCase();
          }

          const bMatches = b.slug.match(/(\w+)_\w+/);
          let bTranslation;
          if (bMatches) {
            const bName = bMatches[1].toLowerCase();
            bTranslation = this.$i18n.te(`models.${bName}.name`)
              ? this.$i18n.tc(`models.${bName}.name`, 2).toLowerCase()
              : bName;
          } else {
            bTranslation = b.slug.toLowerCase();
          }

          return aTranslation > bTranslation ? 1 : -1;
        })
        .reduce((acc, r) => {
          const actions = ['CREATE', 'RETRIEVE', 'UPDATE', 'DELETE', 'MANAGE'];

          for (let i = 0, len = actions.length; i < len; i += 1) {
            const matches = r.slug.match(new RegExp(`(.+)_${actions[i]}`));
            if (matches) {
              const matchSlug = matches[1].toLowerCase();
              if (!acc[matchSlug]) {
                acc[matchSlug] = {};
              }

              acc[matchSlug][actions[i].toLowerCase()] = r;

              return acc;
            }
          }

          this.otherRights.push(r);

          return acc;
        }, {} as CombinedRights);
    }
  }

  hasAllRights(rights: PersistedRight[]) { // eslint-disable-line no-shadow
    return rights
      .map((right) => right.id || 0)
      .every((id) => this.rightIds.includes(id));
  }

  hasRight(right: PersistedRight) {
    return this.hasAllRights([right]);
  }

  onToggleRights(included: boolean, newRights: PersistedRight[]) {
    const newRightIds: number[] = [...(new Set(newRights.map((r) => r.id)))];

    if (included) {
      this.$emit(
        'input',
        this.rightIds.concat(newRightIds).map((id) => ({ id })),
      );
    } else {
      this.$emit(
        'input',
        this.rightIds
          .filter((rId: number) => !newRightIds.includes(rId))
          .map((id) => ({ id })),
      );
    }
  }

  onToggleRight(included: boolean, newRight: PersistedRight) {
    this.onToggleRights(included, [newRight]);
  }
}
