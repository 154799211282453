








































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsContactListItem from '@/components/QsContactListItem.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import { Contact, Model } from 'qs_vuetify/src/types/models';
import { RelationQueryDefinition, RestParams } from 'qs_vuetify/src/types/states';

const view: any = namespace('instancesView');

@Component({
  components: {
    QsActionModal,
    QsContactListItem,
    QsRelationField,
  },
})
export default class MemberAdoptionDialog extends mixins(ErrorMixin) {
  @Prop({ required: true, type: Object }) item!: Model;
  @Prop({ required: true, type: Boolean }) value!: boolean;
  @view.Getter contactsParams!: RestParams;

  get visible(): boolean {
    return this.value;
  }

  set visible(val: boolean) {
    this.$emit('input', val);
  }

  adoptedContact: Contact | null = null;
  queryDefinition: RelationQueryDefinition & { key: string } = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      fields: [
        'district.name',
        'email',
        'full_name',
        'searchable_text',
        'status',
        'v1_contact_id',
      ].join(','),
    },
  };

  async adoptContact() {
    if (!this.adoptedContact) {
      return;
    }

    await axios.put(`/contacts/${this.adoptedContact.id}/instances/${this.item.id}`, {
      adopted: true,
    }, {
      params: {
        fields: 'id',
      },
    });

    await this.$store.dispatch('contacts/index', {
      'instances.id': this.item.id,
      ...this.contactsParams,
    });

    this.visible = false;
  }
}
