















































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsBaseItemCard from 'qs_vuetify/src/components/QsBaseItemCard.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import { RocketchatChannel } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsButton,
  },
})
export default class RocketchatChannelsCard extends QsBaseItemCard<RocketchatChannel> {
  @Prop({ required: false, type: Boolean, default: false }) restorable!: boolean;
}
