var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instance-events-detail m3-card m3-surface" },
    [
      _vm.routeDataLoaded
        ? _c(
            "div",
            [
              _c("div", { staticClass: "instance-events-detail__title-row" }, [
                _c(
                  "div",
                  { staticClass: "instance-events-detail__title-row__content" },
                  [
                    _vm.instanceEvent && _vm.instanceEvent.instance_event_type
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "instance-events-detail__title-row__content__title"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.instanceEvent.instance_event_type.name
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "instance-events-detail__title-row__content__subtitlte"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("date")(_vm.instanceEvent.date)) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "instance-events-detail__title-row__actions" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { color: "#191c1a", icon: "" } },
                      [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "instance-events-detail__timestamps-row" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "instance-events-detail__timestamps-row__created"
                    },
                    [
                      _vm._v(
                        " Consigné le " +
                          _vm._s(_vm._f("date")(_vm.instanceEvent.created_at)) +
                          " par "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.instanceEvent.created_by_user.contact_name)
                        )
                      ])
                    ]
                  ),
                  _vm.instanceEvent.created_at !== _vm.instanceEvent.updated_at
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "instance-events-detail__timestamps-row__updated"
                        },
                        [
                          _vm._v(
                            " Mise à jour le " +
                              _vm._s(
                                _vm._f("date")(_vm.instanceEvent.updated_at)
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _c("v-divider", { attrs: { color: "#c0c9c0" } }),
              _c(
                "div",
                { staticClass: "instance-events-detail__main-content" },
                [
                  _vm.hasMetaQuorum
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "instance-events-detail__main-content__quorum"
                        },
                        [
                          _c("warning-indicator", {
                            attrs: {
                              color: "none",
                              icon: _vm.instanceEvent.meta.quorum
                                ? "mdi-check"
                                : "mdi-close",
                              text: _vm.instanceEvent.meta.quorum
                                ? "L'assemblée a obtenu quorum"
                                : "L'assemblée n'a pas obtenu quorum"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "instance-events-detail__main-content__comment"
                    },
                    [_c("p", [_vm._v(_vm._s(_vm.instanceEvent.comment))])]
                  ),
                  _vm.hasMetaMinutesContacts
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "instance-events-detail__main-content__stats"
                        },
                        [
                          _c("h5", [
                            _vm._v("Statistiques au moment de la consignation")
                          ]),
                          _c(
                            "div",
                            { staticClass: "stats-container" },
                            _vm._l(["mer", "mec", "exm", "ctc"], function(
                              status
                            ) {
                              return _c(
                                "div",
                                {
                                  key: "stats-item--" + status,
                                  staticClass: "stats-item"
                                },
                                [
                                  _c("span", { staticClass: "count" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("number")(
                                          _vm.instanceEvent.meta.contacts[
                                            status.toUpperCase()
                                          ]
                                        )
                                      )
                                    )
                                  ]),
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$tc(
                                            "models.contacts.enums.status." +
                                              status,
                                            _vm.instanceEvent.meta.contacts[
                                              status.toUpperCase()
                                            ]
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.hasMetaMinutesLink
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "instance-events-detail__main-content__minutes-link"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.instanceEvent.meta.minutes_link,
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-paperclip")]),
                              _vm._v(" Procès-verbal de l'assemblée ")
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "skeleton-loader px-4 py-2" },
            [
              _c("v-skeleton-loader", { attrs: { type: "heading" } }),
              _c("v-skeleton-loader", {
                staticClass: "mt-2",
                attrs: { type: "text", "max-width": "120" }
              }),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("v-spacer"),
                  _c("v-skeleton-loader", {
                    staticClass: "mt-2",
                    attrs: { type: "chip" }
                  })
                ],
                1
              ),
              _c("v-divider", { attrs: { color: "#c0c9c0" } }),
              _c("v-skeleton-loader", {
                staticClass: "mt-1",
                attrs: { type: "chip" }
              }),
              _c("v-skeleton-loader", {
                staticClass: "mt-2",
                attrs: { type: "paragraph" }
              }),
              _c("v-skeleton-loader", {
                staticClass: "mt-2",
                attrs: { type: "image", height: "120" }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }