










































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsCopyToClipboard from 'qs_vuetify/src/components/QsCopyToClipboard.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import {
  InstanceGrant,
} from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import UsersSettingsDialog from '@/components/Dialog/UsersSettingsDialog.vue';

const instanceGrants: any = namespace('instance_grants');
const view: any = namespace('instancesView');

@Component({
  components: {
    QsBooleanIndicator,
    QsCopyToClipboard,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    UsersSettingsDialog,
  },
  head: {
    title(this: InstanceGrantsList) {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (this.section) {
        const section = this.$t(`sections.${this.section}`);
        inner = `${section} | ${inner}`;
      } else if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceGrantsList extends mixins(
  DataRouteGuards,
  ExportMixin,
  ListMixin,
) {
  @Prop({ type: String, required: false }) section!: string | null;

  @instanceGrants.Getter('data') instanceGrants!: InstanceGrant[];
  @instanceGrants.Getter exportUrl!: string;
  @instanceGrants.Getter('filtersDefinition') instanceGrantsFiltersDefinition!: FiltersDefinition;
  @instanceGrants.Getter('loading') instanceGrantsLoading!: boolean;
  @instanceGrants.Getter slug!: string;
  @instanceGrants.Getter('total') instanceGrantsTotal!: number;

  @view.Getter instanceGrantsOptions!: DataTableOptions;
  @view.Getter instanceGrantsParams!: RestParams;
  @view.Mutation('instanceGrantsOptions') setInstanceGrantsOptions!: any;
  @view.Mutation('instanceGrantsParams') setInstanceGrantsParams!: any;

  setInstanceGrantsParam = this.buildSetParam('instance_grants', this.setInstanceGrantsParams);

  // eslint-disable-next-line class-methods-use-this
  get actionParams(): RestParams {
    return this.instanceGrantsParams;
  }

  get tableHeaders(): any[] {
    return [
      { text: 'Actif', value: 'is_active', sortable: false },
      this.section ? null : { text: 'Instance', value: 'instance.name', sortable: false },
      { text: 'Nom', value: 'contact.full_name', sortable: false },
      { text: 'Courriel', value: 'user.email', sortable: false },
      this.section ? null : { text: 'Courriel', value: 'contact.email', sortable: false },
      { text: 'Types', value: 'types', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ].filter((k) => !!k);
  }

  usersSettingsDialogVisible = false;

  get viewParams() {
    return {
      instance_grants: ListMixin.buildListState(
        this.instanceGrantsOptions,
        this.instanceGrantsParams,
      ),
    };
  }

  @Watch('routeDataLoaded')
  onInstancesGrantsListRouteDataLoadedChanged(loaded: boolean) {
    if (loaded && this.instanceGrants.length === 0 && this.instanceGrantsOptions.page !== 1) {
      this.setInstanceGrantsOptions({
        ...this.instanceGrantsOptions,
        page: 1,
      });
    }
  }
}
