import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';
import axios from 'qs_vuetify/src/plugins/axios';
import type { AxiosResponse } from 'axios';
import { Instance } from 'qs_vuetify/src/types/models';
import { RestParams, RootState } from 'qs_vuetify/src/types/states';

import { InstancesRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
  CancelablePromise,
} from 'qs_vuetify/src/plugins/rest';

export const instancesState: InstancesRestState = {
  ...buildRestState<Instance>('instances', {}),
  stats: null,
  statsAjax: null,
  statsLastLoadedAt: null,
  statsLoaded: false,
};

export const getters: GetterTree<InstancesRestState, RootState> = {
  ...buildRestGetters<Instance, InstancesRestState>(),
  stats(state) {
    return state.stats;
  },
  statsAjax(state) {
    return state.statsAjax;
  },
  statsLastLoadedAt(state) {
    return state.statsLastLoadedAt;
  },
  statsLoaded(state) {
    return state.statsLoaded;
  },
};

export const actions: ActionTree<InstancesRestState, RootState> = {
  ...buildRestActions<Instance, InstancesRestState>(),
  async stats({ commit, state }, params: RestParams) {
    commit('statsLoaded', false);
    commit('error', null);

    const {
      id,
      prefix,
      ...rest
    } = params;
    const url = `${prefix || state.prefix}/${state.path}/${id}/stats`;

    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();

      const ajax: CancelablePromise<AxiosResponse<any>> = axios.get(url, {
        cancelToken: source.token,
        params: {
          ...rest,
        },
      });

      ajax.cancel = source.cancel;

      commit('statsAjax', ajax);

      const { data } = await ajax;

      commit('stats', data);
      commit('statsLastLoadedAt', new Date());

      commit('statsAjax', null);

      commit('statsLoaded', true);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e.response && e.response.data) {
        commit('error', {
          ...e.response.data,
          request: {
            params,
            url: `POST ${url}`,
          },
        });
      }

      throw e;
    }
  },
};

export const mutations: MutationTree<InstancesRestState> = {
  ...buildRestMutations<Instance, InstancesRestState>(),
  stats(state, stats) {
    state.stats = stats;
  },
  statsAjax(state, statsAjax) {
    state.statsAjax = statsAjax;
  },
  statsLoaded(state, statsLoaded) {
    state.statsLoaded = statsLoaded;
  },
  statsLastLoadedAt(state, statsLastLoadedAt) {
    state.statsLastLoadedAt = statsLastLoadedAt;
  },
  statsReset(state) {
    state.stats = null;
    state.statsLoaded = false;
    state.statsLastLoadedAt = null;
  },
};

export const instances: Module<InstancesRestState, RootState> = {
  namespaced: true,
  state: instancesState,
  getters,
  actions,
  mutations,
};
