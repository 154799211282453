































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import { Petition } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { Form } from 'qs_vuetify/src/types/components';

const global: any = namespace('global');
const store: any = namespace('petitions');
const view: any = namespace('petitionsViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
  },
})
export default class NewPetitionDialog extends mixins(
  AuthenticationMixin,
  FormMixin,
) {
  @global.Mutation addNotification!: any;

  @store.Getter error!: ErrorResponse;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter form!: Form;
  @store.Getter item!: Petition;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter params!: RestParams;
  @view.Mutation setParams!: any;

  @Prop([String, Number]) id!: string | number;

  mounted() {
    this.setAction();
  }

  closeModal() {
    this.$router.push({ name: 'Petitions' });
  }

  setAction() {
    if (this.userHas('PETITIONS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push({ name: 'NewPetitionDialog' });
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  async submitForm() {
    await this.submit();
  }
}
