














































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import BaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsContactCard from 'qs_vuetify/src/components/Contacts/QsContactCard.vue';
import QsFilterModal from 'qs_vuetify/src/components/Dialog/QsFilterModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import {
  PersistedRocketchatChannel as Channel,
  PersistedContact as Contact,
  PersistedContact,
} from 'qs_vuetify/src/types/models';
import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const contacts: any = namespace('contacts');
const contactsViews: any = namespace('contactsViews');
const global: any = namespace('global');
const store: any = namespace('rocketchat_channels');

@Component({
  components: {
    BaseLayout,
    QsButton,
    QsCard,
    QsContactCard,
    QsFilterModal,
    QsFormBuilder,
    QsList,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },

  },
})
export default class RocketchatChannelsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @contacts.Getter('data') contactsData!: PersistedContact[];
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;
  @contacts.Action('index') contactsIndex!: any;
  @contacts.Action('loadPage') contactsLoadPage!: any;

  @contactsViews.Getter('options') contactsOptions!: DataTableOptions;
  @contactsViews.Mutation('setOptions') setContactsOptions!: any;

  @global.Mutation addNotification!: Function;

  @store.Getter('contacts') data!: Contact[];
  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter initialItem!: string;
  @store.Getter item!: Channel;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter syncing!: boolean;
  @store.Mutation setDestroying!: any;
  @store.Mutation('item') syncItem!: any
  @store.Action('contacts') getContacts!: any;
  @store.Action('contactsLoadPage') getNextContactsPage!: any;
  @store.Action destroy!: any;
  @store.Action syncChannel!: any;

  @Prop({ required: true, type: [Number, String] }) id!: number | string;

  filtersModal = false;
  formOrder = [
    'name',
    'description',
    'public',
    'sync_add_only',
  ];

  viewParams = {
    rocketchat_channels: {
      fields: '*',
    },
  };

  mounted() {
    this.onRouteChanged();
  }

  get paramsFilter() {
    if (this.item && Object.keys(this.item.filter).length < 1) {
      return {
        id: -1,
      };
    }

    if (this.item) {
      return this.item.filter;
    }

    return null;
  }

  async actionArchive() {
    this.setDestroying(true);
    try {
      await this.destroy(this.id);

      this.addNotification({
        color: 'success',
        message: 'Canal archivé',
      });
      this.$router.push({ name: 'RocketchatChannelsList' });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de l\'archivage du canal',
      });
    }
    setInterval(() => this.setDestroying(false), 1000);
  }

  async actionSync() {
    try {
      await this.syncChannel(this.id);

      this.addNotification({
        color: 'success',
        message: 'Synchronisation terminée',
      });
    } catch (e) {
      this.addNotification({
        color: 'errror',
        message: 'Erreur lors de la synchronisation',
      });
    }
  }

  afterSave() {
    this.addNotification({
      color: 'success',
      message: 'Canal sauvegardé avec succès',
    });
  }

  loadNextPage() {
    this.setContactsOptions({
      ...this.contactsOptions,
      page: this.contactsOptions.page + 1,
    });
    this.contactsLoadPage({
      ...this.paramsFilter,
      fields: [
        'district.name',
        'first_name',
        'last_name',
        'status',
        'v1_contact_id',
      ].join(','),
      page: this.contactsOptions.page,
    });
  }

  @Watch('item', { deep: true })
  onChannelChanged(newItem: Channel, oldItem: Channel) {
    if (newItem && oldItem) {
      const oldFilter = JSON.stringify(oldItem.filter);
      const newFilter = JSON.stringify(newItem.filter);

      if (oldFilter !== newFilter) {
        this.onRouteChanged();
      }
    } else if (newItem) {
      this.onRouteChanged();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
    this.setGlobalSubtitle();
    this.setContactsOptions({
      ...this.contactsOptions,
      page: 1,
    });
    this.contactsIndex({
      ...this.paramsFilter,
      fields: [
        'district.name',
        'first_name',
        'last_name',
        'status',
        'v1_contact_id',
      ].join(','),
    });
  }

  resetFilters(): void {
    const initialItem = JSON.parse(this.initialItem);
    this.syncItem({
      ...this.item,
      filter: initialItem.filter,
    });
  }

  setAction() {
    if (this.userHas('ROCKETCHAT_CHANNELS_UPDATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.submit();
          },
          color: 'primary',
          icon: 'mdi-content-save',
          loading: { getterPath: 'rocketchat_channels/loading', negative: false },
        },
        {
          onClick: () => {
            this.actionSync();
          },
          color: 'warning',
          icon: 'mdi-refresh',
          loading: { getterPath: 'rocketchat_channels/syncing', negative: false },
        },
        {
          onClick: () => {
            this.actionArchive();
          },
          color: 'error',
          icon: 'mdi-archive',
          loading: { getterPath: 'rocketchat_channels/destroying', negative: false },
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit('global/subtitle', this.item?.name || `Canal #${this.item.id}`);
    }
    this.$emit('updateHead');
  }

  updateItemFilter(filter: RestParams): void {
    this.syncItem({
      ...this.item,
      filter: filter.filter,
    });
  }
}
