




























































































import Component from 'vue-class-component';

import QsBaseItemCard from 'qs_vuetify/src/components/QsBaseItemCard.vue';

import { Petition } from 'qs_vuetify/src/types/models';

@Component
export default class PetitionsCard extends QsBaseItemCard<Petition> {}
