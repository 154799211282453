



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

interface Grant {
  end_at: string;
  id: number | string;
  is_active: boolean;
  start_at: string;
}

@Component({
  components: {
    QsButton,
  },
})
export default class GrantsList extends Vue {
  @Prop({ required: false, type: Boolean, default: true }) ready!: boolean;
  @Prop({ required: false, type: Array, default: () => [] }) items!: Grant[];
  @Prop({ required: true, type: String }) modelName!: string;

  get actives(): Grant[] {
    return this.items.filter((i: Grant) => !!i.is_active);
  }

  get inactives(): Grant[] {
    return this.items.filter((i: Grant) => !i.is_active);
  }

  label(key: string) {
    if (this.$i18n.te(`grants.${this.modelName}.${key}`)) {
      return this.$i18n.t(`grants.${this.modelName}.${key}`);
    }

    return key;
  }
}
