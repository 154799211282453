

























































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import axios from 'qs_vuetify/src/plugins/axios';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { Form } from 'qs_vuetify/src/types/components';
import {
  PersistedInstanceUser,
  PersistedRight,
  User,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import QsContactListItem from '@/components/QsContactListItem.vue';

const global: any = namespace('global');
const store: any = namespace('users');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsContactListItem,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class UsersForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @global.Mutation addNotification!: Function;

  @store.Getter error!: ErrorResponse;
  @store.Getter item!: User;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @Prop([String, Number]) id!: string | number;

  get formOrder(): string[] {
    const fields = [
      'accepted_confidentiality_agreement',
      'contact_id',
      'default_instance_id',
      'email',
    ];

    if (this.userIsSuperadmin) {
      fields.push('superadmin');
    }

    return fields;
  }

  instanceUserLoading: number | null = null;

  tab: number = 0;

  mounted() {
    this.onRouteChanged();
  }

  closeModal() {
    this.$router.push({ name: 'Users' });
  }

  async endRights(instanceUser: PersistedInstanceUser) {
    this.instanceUserLoading = instanceUser.id;
    try {
      await axios.put(`/instances/${instanceUser.instance.id}/instance_users/${instanceUser.id}`, {
        user_id: this.item.id,
        end_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      });
      this.addNotification({
        color: 'success',
        message: `Les droits de ${this.item.full_name} dans ${instanceUser.instance.name}`
         + 'ont été terminés',
      });
      this.reloadDataRoutesData(['users.retrieve']);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Une erreur est survenue en mettant fin aux droits',
      });
    } finally {
      this.instanceUserLoading = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  hasAllRights(currentRights: PersistedRight[], rights: PersistedRight[]) {
    const rightIds = (currentRights as PersistedRight[]).map((r: PersistedRight) => r.id);
    return rights
      .map((right) => right.id || 0)
      .every((id) => rightIds.includes(id));
  }

  // eslint-disable-next-line class-methods-use-this
  hasSomeRights(currentRights: PersistedRight[], rights: PersistedRight[]) {
    const rightIds = (currentRights as PersistedRight[]).map((r: PersistedRight) => r.id);
    return rights
      .map((right) => right.id || 0)
      .some((id) => rightIds.includes(id));
  }

  isInstanceUserLoading(instanceUser: PersistedInstanceUser): boolean {
    return this.instanceUserLoading === instanceUser.id;
  }

  @Watch('item.slug')
  onItemNameChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit('global/subtitle', this.item?.slug || 'Nouvel utilisateur');
    } else {
      this.$store.commit('global/subtitle', this.item?.contact?.full_name || `Utilisateur #${this.item.id}`);
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    const {
      item: {
        contact_id,
        default_instance_id,
        email,
        id,
        superadmin,
      },
    } = this;
    const submittedData = {
      contact_id,
      default_instance_id,
      email,
      id,
      superadmin,
    };
    this.syncItem(submittedData);

    await this.submit();

    this.reloadDataRoutesData(['users.index']);

    this.closeModal();
  }
}
