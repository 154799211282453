import {
  ActionTree,
  Module,
} from 'vuex';
import axios from 'qs_vuetify/src/plugins/axios';
import type { AxiosResponse } from 'axios';
import { InstanceGrant } from 'qs_vuetify/src/types/models';
import { RestParams, RootState } from 'qs_vuetify/src/types/states';

import { InstanceGrantsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
  CancelablePromise,
} from 'qs_vuetify/src/plugins/rest';

export const instanceGrantsState: InstanceGrantsRestState = {
  ...buildRestState<InstanceGrant>('instance_grants', {}),
  filters: [],
  filtersLoaded: false,
  stats: null,
  statsAjax: null,
  statsLastLoadedAt: null,
  statsLoaded: false,
};

export const getters = buildRestGetters<InstanceGrant, InstanceGrantsRestState>();

export const actions: ActionTree<InstanceGrantsRestState, RootState> = {
  ...buildRestActions<InstanceGrant, InstanceGrantsRestState>(),
  async retrieveByContactId({ commit, dispatch, state }, params: RestParams) {
    commit('loaded', false);
    commit('error', null);

    const {
      id,
      prefix,
      ...rest
    } = params;
    const url = `${typeof prefix === 'string' ? prefix : (state.prefix || '')}/`
      + `${state.path}`;

    try {
      await dispatch('options', prefix);

      const { CancelToken } = axios;
      const source = CancelToken.source();

      const ajax: CancelablePromise<AxiosResponse<any>> = axios.get(url, {
        cancelToken: source.token,
        params: {
          ...rest,
          contact_id: id,
        },
      });

      ajax.cancel = source.cancel;

      commit('ajax', ajax);

      const { data: { data } } = await ajax;

      if (data.length !== 1) {
        commit('error', {
          status: 404,
          code: 'not_found',
          request: {
            params,
            url: `GET ${url}`,
          },
        });
        commit('loaded', true);
        commit('ajax', null);
        return;
      }

      commit('item', data[0]);
      commit('initialItem', JSON.stringify(data[0]));

      commit('loaded', true);
      commit('ajax', null);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      commit('ajax', null);

      const error: any = e;

      if (error.response && error.response.data) {
        commit('error', {
          ...error.response.data,
          request: {
            params,
            url: `GET ${url}`,
          },
        });
      }

      throw e;
    }
  },
};

export const mutations = buildRestMutations<InstanceGrant, InstanceGrantsRestState>();

export const instanceGrants: Module<InstanceGrantsRestState, RootState> = {
  namespaced: true,
  state: instanceGrantsState,
  getters,
  actions,
  mutations,
};
