var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "instance-grants-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { class: { "no-gutters": _vm.section } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "div",
                { class: { "mb-3": !_vm.section } },
                [
                  _c("qs-export-menu", {
                    on: {
                      export: function($event) {
                        return _vm.exportAs($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm.instanceGrantsFiltersDefinition
                ? _c("qs-filters", {
                    attrs: {
                      "active-filters": _vm.instanceGrantsParams,
                      dense: !!_vm.section,
                      "filters-definition": _vm.instanceGrantsFiltersDefinition,
                      "model-name": "instance_grants",
                      theme: "labase"
                    },
                    on: { input: _vm.setInstanceGrantsParam }
                  })
                : _vm._e(),
              _c(
                "v-alert",
                { staticClass: "caption", attrs: { type: "info", tile: "" } },
                [
                  _vm._v(
                    " Attention: cette section n'inclut pas les militant·es d'instance. Consultez la section "
                  ),
                  _c("span", { staticClass: "no-wrap" }, [_vm._v("« Rôles »")]),
                  _vm._v(" pour les afficher. ")
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  headers: _vm.tableHeaders,
                  items: _vm.instanceGrants,
                  loading: _vm.instanceGrantsLoading,
                  options: _vm.instanceGrantsOptions,
                  "set-options": _vm.setInstanceGrantsOptions,
                  tile: "",
                  total: _vm.instanceGrantsTotal || 0
                },
                on: {
                  edit: function($event) {
                    return _vm.$emit("edit", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.instance.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(" " + _vm._s(item.instance.name) + " ")]
                    }
                  },
                  {
                    key: "item.types",
                    fn: function(ref) {
                      var item = ref.item
                      return _vm._l(item.types, function(type) {
                        return _c("span", { key: type }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("titleize")(
                                  _vm.$t(
                                    "models.instance_grants.enums.types." + type
                                  )
                                )
                              )
                          ),
                          _c("br")
                        ])
                      })
                    }
                  },
                  {
                    key: "item.contact.full_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.contact
                          ? _c("span", [
                              _vm._v(" " + _vm._s(item.contact.full_name) + " ")
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.contact.email",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.contact
                          ? _c(
                              "span",
                              [
                                _vm._v(" " + _vm._s(item.contact.email) + " "),
                                item.contact.email
                                  ? _c("qs-copy-to-clipboard", {
                                      attrs: {
                                        size: "x-small",
                                        text: item.contact.email
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.is_active",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-boolean-indicator", {
                          attrs: { value: item.is_active }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("users-settings-dialog", {
        attrs: { view: "section" },
        model: {
          value: _vm.usersSettingsDialogVisible,
          callback: function($$v) {
            _vm.usersSettingsDialogVisible = $$v
          },
          expression: "usersSettingsDialogVisible"
        }
      }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }