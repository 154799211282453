































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Right } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const rights: any = namespace('rights');
const view: any = namespace('rightsViews');

@Component({
  components: {
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class RightsList
  extends mixins(AuthenticationMixin, DataRouteGuards, ListMixin) {
  @rights.Getter data!: Array<Right>;
  @rights.Getter filtersDefinition!: FiltersDefinition;
  @rights.Getter loading!: boolean;
  @rights.Getter total!: number;
  @rights.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  mounted() {
    this.setAction();
  }

  setAction() {
    if (this.userHas('RIGHTS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/rights/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      rights: ListMixin.buildListState(this.options, this.params),
    };
  }

  setRightsParam = this.buildSetParam('rights', this.setParams);

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setAction();
    this.$emit('updateHead');
  }

  @Watch('$route', { deep: true })
  onRouteChanged($route: Route, $oldRoute: Route) {
    if ($oldRoute.params.id && !$route.params.id) {
      this.setAction();
      this.reloadDataRoutesData();
    }
  }

  headers = [
    // { text: 'ID', value: 'id' },
    { text: 'Nom système', value: 'slug' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
}
