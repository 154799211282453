var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { sm: "12", md: "3" } },
        [
          _c("qs-filters", {
            attrs: {
              "active-filters": _vm.contactsParams,
              dense: "",
              "filters-definition": _vm.contactsFiltersDefinition,
              "model-name": "contacts",
              title: "Filtres",
              theme: "labase"
            },
            on: { input: _vm.emitFilterEvent }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { sm: "12", md: "9" } },
        [
          _c("qs-data-table", {
            attrs: {
              headers: _vm.contactsHeaders,
              options: _vm.contactsOptions,
              "set-options": _vm.setContactsOptions,
              total: _vm.contactsTotal || 0,
              loading: _vm.contactsLoading,
              items: _vm.contacts
            },
            scopedSlots: _vm._u([
              {
                key: "item.full_name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.full_name
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.labaseURL + "/contacts/" + item.id,
                                target: "_blank"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.full_name) + " ")]
                          )
                        ]
                      : item.email
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.labaseURL + "/contacts/" + item.id,
                                target: "_blank"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.email) + " ")]
                          )
                        ]
                      : [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.labaseURL + "/contacts/" + item.id,
                                target: "_blank"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.v1_contact_id) + " ")]
                          )
                        ]
                  ]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("status")(item.status, "contacts")) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("qs-button", {
                          attrs: {
                            color: "error",
                            disabled: !item.adopted,
                            icon: "mdi-link-off",
                            label: _vm.unadoptLabel(item.district),
                            small: "",
                            theme: "labase"
                          },
                          on: {
                            click: function($event) {
                              return _vm.unadoptContact(item)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }