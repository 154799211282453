var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "instance-users-form",
        dark: false,
        height: "70vh",
        value: true,
        "max-width": "800"
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm.item && _vm.itemReady
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isNew
                            ? "Ajouter un utilisateur"
                            : "Modifier un utilisateur"
                        )
                      )
                    ])
                  : _c("span", [_vm._v("Chargement...")])
              ]
            },
            proxy: true
          },
          _vm.item && _vm.itemReady && !_vm.isNew
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _c("qs-boolean-indicator", {
                      staticClass: "mr-2",
                      attrs: { value: _vm.item.is_active }
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.item.user.contact.full_name) + " | "
                    ),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(
                        _vm._s(_vm.item.is_active ? "Actif" : "Inactif") +
                          " depuis le " +
                          _vm._s(
                            _vm._f("date")(
                              _vm.item.is_active
                                ? _vm.item.start_at
                                : _vm.item.end_at
                            )
                          )
                      )
                    ])
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      depressed: "",
                      disabled: !_vm.itemReady,
                      loading: _vm.loading,
                      tile: ""
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" Enregistrer ")]
                ),
                !_vm.isNew
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "error",
                          depressed: "",
                          loading: _vm.loading,
                          tile: ""
                        },
                        on: { click: _vm.endRights }
                      },
                      [_vm._v(" Mettre fin ")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.item && _vm.itemReady
        ? _c(
            "div",
            [
              _c("qs-relation-field", {
                attrs: {
                  "initial-selection": _vm.item.user
                    ? _vm.item.user.contact
                    : null,
                  item: _vm.item,
                  label: "Contact",
                  name: "user_id",
                  query: _vm.queryDefinition
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item
                            ? _c("qs-contact-list-item", {
                                attrs: { item: item.contact || item }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item
                            ? _c("qs-contact-list-item", {
                                attrs: { item: item.contact }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3083704937
                ),
                model: {
                  value: _vm.contact,
                  callback: function($$v) {
                    _vm.contact = $$v
                  },
                  expression: "contact"
                }
              }),
              _c("qs-form-builder", {
                attrs: {
                  error: _vm.error,
                  form: _vm.form,
                  item: _vm.item,
                  "model-name": _vm.slug,
                  order: _vm.formOrder
                },
                on: { input: _vm.syncItem }
              }),
              _c(
                "div",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { right: "" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", [_vm._v("Groupes de permissions")]),
                      _c("v-tab", [_vm._v("Permissions")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c("v-simple-table", [
                        _c(
                          "tbody",
                          _vm._l(_vm.rightGroups, function(rightGroup) {
                            return _c("tr", { key: rightGroup.id }, [
                              _c("td", [
                                _c("p", { staticClass: "text-subtitle mb-0" }, [
                                  _vm._v(" " + _vm._s(rightGroup.name) + " ")
                                ]),
                                _c("div", { staticClass: "caption" }, [
                                  _vm._v(
                                    " " + _vm._s(rightGroup.description) + " "
                                  )
                                ])
                              ]),
                              _c(
                                "td",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      indeterminate:
                                        !_vm.hasAllRights(rightGroup.rights) &&
                                        _vm.hasSomeRights(rightGroup.rights),
                                      "input-value": _vm.hasSomeRights(
                                        rightGroup.rights
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onToggleRights(
                                          $event,
                                          rightGroup.rights
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c("qs-combined-rights-table", {
                        attrs: { rights: _vm.rights },
                        model: {
                          value: _vm.item.rights,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "rights", $$v)
                          },
                          expression: "item.rights"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "50vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }