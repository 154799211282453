var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grants-list" }, [
    _c(
      "p",
      { staticClass: "d-flex title" },
      [
        _vm._v(" " + _vm._s(_vm.label("title")) + " "),
        _c("v-spacer"),
        _vm._t("button")
      ],
      2
    ),
    _vm.actives.length
      ? _c(
          "div",
          [
            _c(
              "v-list",
              _vm._l(_vm.actives, function(active) {
                return _c(
                  "v-list-item",
                  { key: active.id },
                  [_vm._t("active-list-item", null, null, { item: active })],
                  2
                )
              }),
              1
            )
          ],
          1
        )
      : _vm.ready
      ? _c(
          "div",
          {
            staticClass:
              "d-flex justify-center align-center flex-column mt-6 mb-6"
          },
          [
            _c("v-icon", { attrs: { color: "qs-light-blue", large: "" } }, [
              _vm._v("mdi-playlist-remove")
            ]),
            _c("span", { staticClass: "mb-6 caption" }, [
              _vm._v(_vm._s(_vm.label("no_active")))
            ])
          ],
          1
        )
      : _c("div", [
          _c("p", { staticClass: "muted text-center" }, [
            _vm._v(" Sélectionnez un Contact. ")
          ])
        ]),
    _vm.inactives.length
      ? _c(
          "div",
          [
            _c("hr"),
            _c("h3", [_vm._v(_vm._s(_vm.label("inactive")))]),
            _c(
              "v-list",
              [
                _vm._l(_vm.inactives, function(inactive) {
                  return [
                    _c(
                      "div",
                      { key: inactive.id },
                      [
                        _c(
                          "v-list-item",
                          [
                            _vm._t("inactive-list-item", null, null, {
                              item: inactive
                            })
                          ],
                          2
                        ),
                        _c("v-divider", { attrs: { inset: "" } })
                      ],
                      1
                    )
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }