var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    attrs: { "content-class": "rocketchat-channels-list" },
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                "model-name": _vm.slug,
                theme: "labase"
              },
              on: { input: _vm.setRocketchatChannelsParams }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-list", {
              attrs: {
                "hide-selection": "",
                items: _vm.data,
                loading: _vm.loading,
                "model-name": _vm.slug,
                selection: _vm.selectedItems,
                tile: "",
                theme: "labase",
                total: _vm.total
              },
              on: { load: _vm.loadNextPage },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("rocketchat-channels-card", {
                        attrs: {
                          item: item,
                          restorable: _vm.userHas("ROCKETCHAT_CHANNELS_UPDATE")
                        },
                        on: {
                          "click:restore": function($event) {
                            return _vm.restoreItem(item)
                          }
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }