import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'qs_vuetify/src/plugins/axios';

import AuthModule from 'qs_vuetify/src/store/auth/index';
import GlobalViewModule from 'qs_vuetify/src/store/views/global';
import ComponentModule from 'qs_vuetify/src/store/components/index';

import {
  Contact,
  District,
  InstanceEvent,
  InstanceEventType,
  InstanceRoleResponsibility,
  InstanceRoleType,
  InstanceType,
  NationalEvent,
  Petition,
  Region,
  Right,
  RightGroup,
  RocketchatChannel,
  Tag,
  User,
} from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import buildStoreModule from 'qs_vuetify/src/store/buildStoreModule';
import buildViewsStoreModule from 'qs_vuetify/src/store/views/buildViewsStoreModule';

import { InstancesViewsState } from '@/types/states';

import { filters } from './filters';
import { instances } from './instances';
import { instanceGrants } from './instanceGrants';
import { instanceRoles } from './instanceRoles';
import { instanceUsers } from './instanceUsers';
import { rocketchatChannels } from './rocketchatChannels';

import instancesView from './views/instancesView';
import volunteersReportView from './views/volunteersReportView';

declare const VERSION: string;

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
  key: 'qs_assos',
  storage: window.localStorage,
  reducer(state) {
    return {
      auth: {
        user: state.auth?.user,
      },
      instancesView: {
        defaultEndAt: (state.instancesView as InstancesViewsState).defaultEndAt,
      },
      nationalEventsView: state.nationalEventsView,
      usersView: state.usersView,
      volunteersReportView: state.volunteersReportView,
      global: {
        ...state.global,
        notifications: [],
        previousLocation: null,
      },
    };
  },
});

const vuexCookie = new VuexPersistence<RootState>({
  key: 'qs_auth_v2',
  restoreState: (key) => Cookies.getJSON(key),
  saveState: async (key, state) => {
    await Cookies.set(key, state, {
      expires: 3,
      secure: process.env.NODE_ENV !== 'development',
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      path: '/',
      sameSite: 'Lax',
    });
  },
  reducer(state) {
    return {
      auth: {
        token: state.auth?.token,
        refreshToken: state.auth?.refreshToken,
      },
    };
  },
});


const storeOptions: StoreOptions<RootState> = {
  state: {
    theme: 'labase',
    version: VERSION,
  },
  modules: {
    auth: AuthModule,
    components: ComponentModule,
    contacts: buildStoreModule<Contact>('contacts'),
    contactsViews: buildViewsStoreModule<Contact>(),
    districts: buildStoreModule<District>('districts'),
    filters,
    global: GlobalViewModule,
    instances,
    instancesView,
    instance_events: buildStoreModule<InstanceEvent>('instance_events'),
    instance_event_types: buildStoreModule<InstanceEventType>('instance_event_types'),
    instanceEventTypesViews: buildViewsStoreModule<InstanceEventType>(),
    instance_grants: instanceGrants,
    instance_roles: instanceRoles,
    instance_role_responsibilities: buildStoreModule<InstanceRoleResponsibility>(
      'instance_role_responsibilities',
    ),
    instanceRoleResponsibilitiesViews: buildViewsStoreModule<InstanceRoleResponsibility>(),
    instance_role_types: buildStoreModule<InstanceRoleType>('instance_role_types'),
    instanceRoleTypesViews: buildViewsStoreModule<InstanceRoleType>(),
    instance_types: buildStoreModule<InstanceType>('instance_types'),
    instanceTypesViews: buildViewsStoreModule<InstanceType>(),
    instance_users: instanceUsers,
    nationalEventsView: buildViewsStoreModule<NationalEvent>(),
    national_events: buildStoreModule<Contact>('national_events', [], {
      path: 'national_events/events',
    }),
    petitions: buildStoreModule<Petition>('petitions'),
    petitionsViews: buildViewsStoreModule<Petition>({
      fields: [
        '*',
        'created_by_user.contact_name',
        'image',
        'instances.name',
        'tags.name',
        'updated_by_user.contact_name',
      ].join(','),
    }, {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    }),
    regions: buildStoreModule<Region>('regions'),
    rights: buildStoreModule<Right>('rights'),
    rightsViews: buildViewsStoreModule<Right>({
      fields: [
        '*',
        'groups.name',
      ].join(','),
    }, {
      sortBy: ['slug'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 20,
    }),
    right_groups: buildStoreModule<RightGroup>('right_groups'),
    rightGroupsViews: buildViewsStoreModule<RightGroup>({}, {
      sortBy: ['name'],
      sortDesc: [false],
      page: 1,
      itemsPerPage: 20,
    }),
    rocketchat_channels: rocketchatChannels,
    rocketchatChannelsViews: buildViewsStoreModule<RocketchatChannel>(),
    tags: buildStoreModule<Tag>('tags'),
    users: buildStoreModule<User>('users', ['merge']),
    usersView: buildViewsStoreModule<User>(),
    volunteersReportView,
  },
  plugins: [vuexLocal.plugin, vuexCookie.plugin],
};

const store = new Vuex.Store(storeOptions);

axios.interceptors.request.use((config) => {
  if (store.state.auth && store.state.auth.token) {
    // eslint-disable-next-line
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  return config;
});

export default store;
