import type { RouteConfig } from 'vue-router';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

const Users = () => import('@/views/Users/Users.vue');
const UsersForm = () => import('@/views/Users/UsersForm.vue');

const users: RouteConfig[] = [
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      data: {
        users: {
          index: {
            params: {
              fields: 'contact.full_name,contact.v1_contact_id,email,superadmin',
            },
          },
        },
      },
      title: 'Utilisateurs',
      menus: ['primary'],
      requires: ['USERS_RETRIEVE'],
    },
    children: [
      {
        path: ':id',
        name: 'UsersForm',
        component: UsersForm,
        props: true,
        meta: {
          data: {
            users: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.users.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.usersView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                params: {
                  fields: [
                    'contact.age',
                    'contact.birthdate',
                    'contact.district.name',
                    'contact.email',
                    'contact.full_name',
                    'contact.postal_code',
                    'contact.status',
                    'contact.v1_contact_id',
                    'created_at',
                    'default_instance.name',
                    'email',
                    'active_instance_users.instance.name',
                    'active_instance_users.start_at',
                    'active_instance_users.end_at',
                    'active_instance_users.rights_slugs',
                    'superadmin',
                  ].join(','),
                },
              },
            },
          },
          title: 'Utilisateur',
          menus: [],
          requires: ['USERS_RETRIEVE'],
        },
      },
    ],
  },
];

export default users;
