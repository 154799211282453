import {
  ActionTree,
  Module,
} from 'vuex';

import axios from 'qs_vuetify/src/plugins/axios';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import { InstanceRole } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { InstanceRolesRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const InstanceRolesState: InstanceRolesRestState = buildRestState<InstanceRole>('instance_roles', {});

export const getters = buildRestGetters<InstanceRole, InstanceRolesRestState>();

export const actions: ActionTree<InstanceRolesRestState, RootState> = {
  ...buildRestActions<InstanceRole, InstanceRolesRestState>(),
  archive({ state }, id: number) {
    return axios.put(`${state.path}/${id}`, {
      id,
      end_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    });
  },
};

export const mutations = buildRestMutations<InstanceRole, InstanceRolesRestState>();

export const instanceRoles: Module<InstanceRolesRestState, RootState> = {
  namespaced: true,
  state: InstanceRolesState,
  getters,
  actions,
  mutations,
};
