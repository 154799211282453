var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instance-roles-form" },
    [
      _vm.item
        ? _c("qs-form-builder", {
            attrs: {
              "model-name": _vm.slug,
              form: _vm.form,
              item: _vm.item,
              error: _vm.error,
              order: _vm.formOrder
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "contact_id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "contact_id.selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                }
              ],
              null,
              false,
              2143680124
            )
          })
        : _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }