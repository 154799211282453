
































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import { RocketchatChannel as Channel } from 'qs_vuetify/src/types/models';
import { Form } from 'qs_vuetify/src/types/components';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const store: any = namespace('rocketchat_channels');

@Component({
  components: {
    QsActionModal,
    QsFormBuilder,
  },
})
export default class NewRocketchatChannelForm extends mixins(DataRouteGuards, FormMixin) {
  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter item!: Channel;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @Prop({ required: true, type: [Number, String] }) id!: number | string;

  formOrder = [
    'name',
    'description',
    'public',
    'sync_add_only',
  ]

  closeModal() {
    this.$router.push({ name: 'RocketchatChannelsList' });
  }
}
