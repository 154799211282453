

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import dayjs from 'qs_vuetify/src/plugins/dayjs';

@Component({
  filters: {
    format(count: number): string {
      return new Intl.NumberFormat('fr-CA').format(count);
    },
    timeFromNow(date: string): string {
      return dayjs().diff(date, 'day').toString();
    },
  },
})

export default class QsInstanceStatsCard extends Vue {
  @Prop({ required: false, type: String, default: 'light-grey' }) color!: string;
  @Prop({ required: true, type: Number, default: 0 }) count!: number;
  @Prop({ required: false, type: Number, default: 0 }) previousCount?: number;
  @Prop({
    required: false,
    type: String,
    default: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  }) previousDate?: string;

  @Prop({ required: true, type: String, default: 'CTC' }) status!: string;

  // eslint-disable-next-line class-methods-use-this
  arrowDirection(current: number, previous: number): string {
    if (current > previous) {
      return 'mdi-arrow-up';
    }

    if (current < previous) {
      return 'mdi-arrow-down';
    }

    return 'mdi-minus';
  }
}
