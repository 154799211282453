var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "instance-card",
      attrs: {
        tile: "",
        to: {
          name: "InstancesForm",
          params: { id: _vm.item.id, section: "summary" }
        }
      }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-start" },
        [
          false
            ? _c("v-checkbox", {
                staticClass: "flex-shrink align-self-start mt-1",
                attrs: { "input-value": _vm.selected },
                on: {
                  change: function($event) {
                    return _vm.$emit("check", $event)
                  },
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              })
            : _vm._e(),
          _c("div", { staticClass: "flex-grow" }, [
            _c("h1", { staticClass: "text-h2 mb-0" }, [
              _vm._v(" " + _vm._s(_vm.item.name) + " ")
            ]),
            _c("p", { staticClass: "subtitle mb-0 font-weight-bold" }, [
              _vm.item.instance_type
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.item.instance_type.name) + " ")
                  ])
                : _vm._e()
            ]),
            _vm.item.region
              ? _c("p", { staticClass: "body-2" }, [
                  _vm._v(_vm._s(_vm.item.region.name))
                ])
              : _vm._e()
          ]),
          _c("v-spacer"),
          _c("div", { staticClass: "flex-shrink" }, [
            _vm._v(
              " " + _vm._s(_vm.item.is_active ? "Active" : "Inactive") + " "
            )
          ])
        ],
        1
      ),
      _vm.hasExtraInformation && _vm.showExtraInformation
        ? _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _vm._l(_vm.item.active_coordination, function(coordo) {
                    return _c(
                      "v-col",
                      {
                        key: _vm.item.id + "_coordo_" + coordo.id,
                        attrs: { cols: "4" }
                      },
                      [
                        _c("instance-elected-info", {
                          attrs: { item: coordo, label: "Coordination" }
                        })
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.item.active_representant_officiel, function(ro) {
                    return _c(
                      "v-col",
                      {
                        key: _vm.item.id + "_ro_" + ro.id,
                        attrs: { cols: "4" }
                      },
                      [
                        _c("instance-elected-info", {
                          attrs: {
                            item: ro,
                            label: "Représentant·e officiel·le"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }