












































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsListFilters from 'qs_vuetify/src/components/QsListFilters.vue';

import { Filter, PersistedInstance } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import InstanceCard from '@/components/Instances/InstanceCard.vue';

const store: any = namespace('instances');
const view: any = namespace('instancesView');

@Component({
  components: {
    InstanceCard,
    QsFilters,
    QsList,
    QsListFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Instances extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  LazyListMixin,
  ListMixin,
) {
  @store.Getter data!: PersistedInstance[];
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter filters!: Filter[];
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;

  @view.Getter params!: RestParams;
  @view.Mutation setParams!: any;

  defaultParams = {
    per_page: 20,
    order: 'name',
    fields: [
      'active_coordination.contact.email',
      'active_coordination.contact.full_name',
      'active_coordination.contact.home_phone',
      'active_representant_officiel.contact.email',
      'active_representant_officiel.contact.full_name',
      'active_representant_officiel.contact.home_phone',
      'is_active',
      'instance_type.name',
      'name',
      'region_id',
      'region.name',
    ].join(','),
  }

  viewStoreName = 'instancesView';

  mounted() {
    this.$store.commit('instances/exportFields', [
      'active_coordination.contact.email',
      'active_coordination.contact.full_name',
      'active_coordination.contact.home_phone',
      'active_representant_officiel.contact.email',
      'active_representant_officiel.contact.full_name',
      'active_representant_officiel.contact.home_phone',
      'is_active',
      'instance_type.name',
      'name',
      'region_id',
      'region.name',
    ]);

    if (this.data.length === 0) {
      this.setParams({
        ...this.defaultParams,
        ...this.params,
        page: 1,
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onInstanceIdChangedCleanUp() {
    // Noop: Assos ne gère pas le contexte d'instance
  }

  get viewParams() {
    const instances: RestParams = {
      ...this.defaultParams,
      ...this.params,
    };

    if (!this.user?.superadmin) {
      instances['inherited_instance_users.user_id'] = this.user?.id || null;
    }

    return {
      'instances.index': instances,
    };
  }

  @Watch('$route', { deep: true })
  onInstancesRouteChanged() {
    this.reloadDataRoutesData();
  }

  @Watch('routeDataLoaded')
  onInstancesRouteDataLoadedChanged() {
    this.$store.commit('global/reset');
    this.$emit('updateHead');
  }
}
