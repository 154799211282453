var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instance-events-list-detail" }, [
    _c(
      "div",
      { staticClass: "instance-events-list-detail__list" },
      [
        _c("qs-search", {
          attrs: {
            "active-filters": _vm.instanceEventsParams,
            "filters-definition": _vm.instanceEventsFiltersDefinition,
            "model-name": "instance_events"
          },
          on: { input: _vm.setInstanceEventsParam }
        }),
        _vm.instanceEventsLoading
          ? _vm._l(3, function(n) {
              return _c(
                "div",
                {
                  key: "sl-" + n,
                  staticClass: "m3-card m3-surface skeleton-loader mt-2"
                },
                [
                  _c("v-skeleton-loader", {
                    attrs: { type: "list-item-avatar-two-line" }
                  }),
                  _c(
                    "div",
                    { staticClass: "px-4" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mt-2",
                        attrs: { type: "heading" }
                      }),
                      _c("v-skeleton-loader", {
                        staticClass: "mt-2",
                        attrs: { type: "sentences" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            })
          : _vm.instanceEvents.length > 0
          ? _vm._l(_vm.instanceEvents, function(ref) {
              var comment = ref.comment
              var created_by_user = ref.created_by_user
              var date = ref.date
              var id = ref.id
              var instance_event_type = ref.instance_event_type
              return _c(
                "div",
                {
                  key: "instance-event-" + id,
                  staticClass:
                    "instance-events-list-detail__list-item m3-card m3-surface",
                  attrs: { role: "button", tabindex: "0" }
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/instances/" + _vm.$route.params.id + "/events/" + id
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "list-item__title" },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: "#d1e8d5", size: "42" } },
                            [
                              _c("v-icon", { attrs: { color: "#4f6354" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("icon")(instance_event_type.name)
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "list-item__title__info" }, [
                            _c(
                              "div",
                              { staticClass: "list-item__title__top-line" },
                              [_vm._v(_vm._s(_vm._f("date")(date)))]
                            ),
                            created_by_user && created_by_user.contact
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "list-item__title__bottom-line"
                                  },
                                  [
                                    _vm._v(
                                      " Consigné par " +
                                        _vm._s(
                                          created_by_user.contact.full_name
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "list-item__content" }, [
                        _c("h3", [_vm._v(_vm._s(instance_event_type.name))]),
                        _c("p", [_vm._v(_vm._s(comment))])
                      ])
                    ]
                  )
                ],
                1
              )
            })
          : _c(
              "div",
              {
                staticClass:
                  "m3-card m3-surface empty-state d-flex flex-column justify-center align-center mt-2"
              },
              [
                _c("v-icon", { attrs: { color: "#dce5db", "x-large": "" } }, [
                  _vm._v("mdi-comment-alert-outline")
                ]),
                _c("p", [_vm._v("Aucun suivi correspondant au filtre")])
              ],
              1
            )
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "instance-events-list-detail__detail" },
      [
        _vm.$route.params.eventId && _vm.$route.params.eventId !== "new"
          ? _c("router-view")
          : [
              _c("div", { staticClass: "m3-card m3-surface empty-state" }, [
                _c("h3", [_vm._v("Suivis")]),
                _c("p", [
                  _vm._v(
                    " Les suivis permettent de consigner les jalons importants d'une instance, ainsi que certaines informations contextuelles, comme le quorum d'une assemblée ou le lien vers le procès-verbal. "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    " Certains types de suivi (i.e. Assemblées de fondation, fermeture) déterminent le statut (actif ou inactif) de l'instance. "
                  )
                ])
              ])
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }