import type { RouteConfig } from 'vue-router';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import i18n from 'qs_vuetify/src/plugins/i18n';
import { capitalize } from 'qs_vuetify/src/plugins/filters';

const NewPetitionDialog = () => import('@/views/Petitions/NewPetitionDialog.vue');
const Petitions = () => import('@/views/Petitions/Petitions.vue');
const PetitionForm = () => import('@/views/Petitions/PetitionForm.vue');

const petitions: RouteConfig[] = [
  {
    path: '/petitions',
    name: 'Petitions',
    component: Petitions,
    meta: {
      auth: true,
      data: {
        petitions: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.petitions.lastLoadedAt;
            },
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.petitionsViews;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
          },
        },
      },
      menus: ['primary'],
      title: capitalize(i18n.tc('models.petitions.name', 2)),
      requires: ['PETITIONS_RETRIEVE'],
    },
    children: [
      {
        path: 'new',
        name: 'NewPetitionDialog',
        component: NewPetitionDialog,
        props: () => ({ id: 'new' }),
        meta: {
          auth: true,
          data: {
            petitions: {
              loadEmpty: {},
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.petitions.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.petitionsViews;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
            },
          },
          title: `${capitalize(i18n.tc('models.petitions.new', 1))} ${i18n.tc('models.petitions.name', 1)}`,
          requires: ['PETITIONS_CREATE'],
        },
      },
    ],
  },
  {
    path: '/petitions/:id',
    name: 'PetitionForm',
    component: PetitionForm,
    props: true,
    meta: {
      auth: true,
      data: {
        petitions: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
        tags: {
          index: {
            conditional(vm: Vue) {
              return vm.$store.state.tags.lastLoadedAt === null;
            },
            params: {
              fields: 'name,instance_name',
              order: 'name',
              per_page: '*',
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.petitions.name', 1)),
      submenu: false,
      requires: ['PETITIONS_RETRIEVE'],
    },
  },
];

export default petitions;
