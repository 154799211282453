var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "national-events-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { class: { "no-gutters": _vm.section } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _vm.filtersDefinition
                ? _c("qs-filters", {
                    attrs: {
                      "active-filters": _vm.params,
                      dense: !!_vm.section,
                      "filters-definition": _vm.filtersDefinitionExceptUuids,
                      "model-name": "national_events",
                      theme: "labase"
                    },
                    on: { input: _vm.setParam }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  actions: ["archive", "edit", "restore"],
                  headers: _vm.tableHeaders,
                  items: _vm.data,
                  loading: _vm.loading,
                  options: _vm.options,
                  "set-options": _vm.setOptions,
                  tile: "",
                  total: _vm.total || 0
                },
                on: {
                  archive: _vm.emitArchive,
                  edit: _vm.emitEdit,
                  restore: _vm.emitRestore
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.status",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.deleted_at
                          ? _c("div", [_vm._v(" Archivé ")])
                          : _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getNationalEventStatusLabel(item.status)
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  },
                  {
                    key: "item.type",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getNationalEventTypeLabel(item.type)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }