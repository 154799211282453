var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { staticClass: "px-0" },
    [
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [
            _vm._v(" " + _vm._s(_vm.item.full_name) + " ")
          ]),
          _c("v-list-item-subtitle", [
            _vm._v(" " + _vm._s(_vm.item.v1_contact_id) + " "),
            _vm.item.email
              ? _c("span", [_vm._v("· " + _vm._s(_vm.item.email))])
              : _vm._e()
          ]),
          _vm.item.birthdate
            ? _c("v-list-item-subtitle", [
                _vm._v(" " + _vm._s(_vm._f("date")(_vm.item.birthdate)) + " "),
                _vm.item.age
                  ? _c("span", [_vm._v("(" + _vm._s(_vm.item.age) + " ans)")])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-list-item-action-text",
        { staticClass: "text-right" },
        [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm._f("status")(_vm.item.status)))
          ]),
          _c("br"),
          _vm.item.district
            ? _c("span", [_vm._v(_vm._s(_vm.item.district.name))])
            : _c("span", { staticClass: "text-italic" }, [
                _vm._v("Circonscription manquante")
              ]),
          _vm.item.postal_code
            ? [_c("br"), _vm._v(_vm._s(_vm.item.postal_code) + " ")]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }