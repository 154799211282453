


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsCopyToClipboard from 'qs_vuetify/src/components/QsCopyToClipboard.vue';

interface ElectedInfo {
  contact: {
    email: string | null;
    full_name: string;
    home_phone: string;
  };
}

@Component({
  components: {
    QsCopyToClipboard,
  },
})
export default class InstanceElectedInfo extends Vue {
  @Prop({ required: true, type: Object }) item!: ElectedInfo;
  @Prop({ required: false, type: String, default: 'Élu·e' }) label!: string;
}
