









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import { PersistedRightGroup } from 'qs_vuetify/src/types/models';

const view: any = namespace('instancesView');

@Component({
  components: {
    QsActionModal,
    QsDateField,
    QsRelationField,
  },
})
export default class UsersSettingsDialog extends Vue {
  @Prop({ required: true, type: Boolean }) value!: boolean;

  @view.Getter defaultEndAt!: string | null;
  @view.Getter defaultRightGroups!: PersistedRightGroup[];
  @view.Mutation setDefaultEndAt!: any;
  @view.Mutation setDefaultRightGroups!: any;

  get visible(): boolean {
    return this.value;
  }

  set visible(val: boolean) {
    this.$emit('input', val);
  }
}
