












































































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsSplitButton from 'qs_vuetify/src/components/Buttons/QsSplitButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import { DataTableOptions, Form, GenericFormField } from 'qs_vuetify/src/types/components';
import {
  Instance,
  InstanceGrant,
  InstanceRoleType,
  PersistedRight,
  PersistedRightGroup,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RelationQueryDefinition } from 'qs_vuetify/src/types/states';

import GrantsList from '@/components/GrantsList.vue';
import QsContactListItem from '@/components/QsContactListItem.vue';

const global: any = namespace('global');
const instances: any = namespace('instances');
const instanceGrants: any = namespace('instance_grants');
const instanceRoles: any = namespace('instance_roles');
const instanceUsers: any = namespace('instance_users');
const view: any = namespace('instancesView');

@Component({
  components: {
    GrantsList,
    QsActionModal,
    QsBooleanIndicator,
    QsButton,
    QsContactListItem,
    QsDataTable,
    QsFormBuilder,
    QsRelationField,
    QsSplitButton,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceGrantsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  NavigationMixin,
) {
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @instances.Getter('item') instance!: Instance | null;

  @instanceGrants.Getter error!: ErrorResponse;
  @instanceGrants.Getter form!: Form;
  @instanceGrants.Getter item!: InstanceGrant | null;
  @instanceGrants.Getter loading!: boolean;
  @instanceGrants.Getter slug!: string;
  @instanceGrants.Mutation('item') syncItem!: any

  @instanceRoles.Getter('loading') instanceRolesLoading!: boolean;

  @instanceUsers.Getter('loading') instanceUsersLoading!: boolean;

  @view.Getter defaultRightGroups!: PersistedRightGroup[];
  @view.Getter instanceRolesOptions!: DataTableOptions;
  @view.Getter instanceUsersOptions!: DataTableOptions;
  @view.Mutation('instanceRolesOptions') setInstanceRolesOptions!: any;
  @view.Mutation('instanceUsersOptions') setInstanceUsersOptions!: any;

  @Prop([String, Number]) id!: string | number;
  @Prop([String, Number]) contactId!: string | number;
  @Prop([String, Number]) userId?: string | number;
  @Prop([String, Number]) section!: string | number;

  get grantForm(): Form {
    const contactIdField: GenericFormField = (this.form.contact_id as GenericFormField);

    return {
      ...this.form,
      contact_id: {
        ...contactIdField,
        query: {
          ...(contactIdField.query as RelationQueryDefinition),
          params: {
            ...(contactIdField?.query?.params || {}),
            fields: `${contactIdField?.query?.params?.fields || 'id'},email`,
          },
        },
      },
    };
  }

  get formOrder(): string[] {
    const fields = [
      'contact_id',
    ];

    if (!this.instance) {
      fields.push('instance_id');
    }

    return fields;
  }

  get itemReady(): boolean {
    if (this.contactId === 'new') {
      return !!this.item;
    }

    if (typeof this.contactId === 'string') {
      return this.item?.contact_id === parseInt(this.contactId, 10);
    }

    if (this.userId) {
      return this.item?.contact_id === this.contactId && this.item?.user_id === this.userId;
    }

    return this.item?.contact_id === this.contactId;
  }

  instanceUserTableHeaders = [
    { text: 'Actif', value: 'is_active', sortable: false },
    { text: 'Droits', value: 'rights_count', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  roleTableHeaders = [
    { text: 'Actif', value: 'is_active', sortable: false },
    { text: 'Type', value: 'type_name', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  mounted() {
    this.onRouteChanged();
  }

  goToNew(grantType: 'roles' | 'users', specific: 'elue' | 'electoral' | null = null) {
    if (grantType === 'roles' && this.item) {
      let instance_role_type_id = null;
      let instance_role_type = null;

      if (specific === 'elue') {
        instance_role_type_id = 5;
        instance_role_type = this.$store
          .getters['instance_role_types/data']
          .find((t: InstanceRoleType) => t.id === 5);
      }

      if (specific === 'electoral') {
        instance_role_type_id = 4;
        instance_role_type = this.$store
          .getters['instance_role_types/data']
          .find((t: InstanceRoleType) => t.id === 2);
      }

      this.$store.commit('instance_roles/item', {
        ...this.$store.getters['instance_roles/item'],
        contact_id: this.item.contact_id,
        contact: this.item.contact,
        instance_grant_id: this.item.id ? this.item.id : 'new',
        instance: this.instance,
        instance_id: this.id,
        instance_role_type,
        instance_role_type_id,
      });

      this.goTo(null, {
        name: 'InstancesInstanceRolesDialog',
        params: { id: `${this.id}`, roleId: 'new' },
      });
    }

    if (grantType === 'users' && this.item) {
      this.$store.commit('instance_users/item', {
        ...this.$store.getters['instance_users/item'],
        user_id: this.item.contact?.user ? this.item.contact.user.id : null,
        user: {
          contact: this.item.contact,
          email: this.item.contact?.user ? this.item.contact.user.email : this.item.contact?.email,
          id: this.item.contact?.user ? this.item.contact.user.id : null,
        },
        contact_id: this.item.contact_id,
        contact: this.item.contact,
        instance_grant_id: this.item.id ? this.item.id : 'new',
        instance: this.instance,
        instance_id: this.id,
        rights: this.defaultRightGroups.length > 0
          ? this.defaultRightGroups
            .reduce((acc: PersistedRight[], rg) => {
              acc.push(...rg.rights);
              return acc;
            }, ([] as PersistedRight[]))
          : [],
      });

      this.goTo(null, {
        name: 'InstanceUsersForm',
        params: { id: `${this.id}`, instanceUserId: 'new' },
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  hasAllRights(rightGroupRights: PersistedRight[], userRights: PersistedRight[]) { // eslint-disable-line no-shadow
    return rightGroupRights
      .map((right) => right.id || 0)
      .every((id) => userRights.map((right) => right.id || 0).includes(id));
  }

  hasRightGroups(rights: PersistedRight[]) {
    const rightGroups: { name: string; rights: PersistedRight[] }[] = this.$store.getters['right_groups/data'];
    const fullRightGroups: string[] = [];

    rightGroups.reduce((acc, rightGroup) => {
      if (rightGroup.rights && this.hasAllRights(rightGroup.rights, rights)) {
        acc.push(rightGroup.name);
      }

      return acc;
    }, fullRightGroups);

    return fullRightGroups;
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('itemReady')
  onItemReady() {
    this.setGlobalSubtitle();
  }

  @Watch('$route.path')
  onRouteChanged() {
    this.setGlobalSubtitle();
  }

  closeModal() {
    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else {
      this.$store.commit(`${this.slug}/item`, null);

      this.$router.push({
        name: 'InstancesForm',
        params: {
          id: `${this.instance?.id}`,
          section: 'grants',
        },
      });
    }
  }

  setGlobalSubtitle() {
    if (this.$route.name === 'InstancesInstanceGrantsDialog') {
      if (!this.itemReady) {
        this.$store.commit('global/subtitle', 'Chargement...');
      } else if (this.isNew) {
        this.$store.commit(
          'global/subtitle', this.item?.contact?.full_name
          || 'Nouveau rôle et permissions',
        );
      } else {
        this.$store.commit(
          'global/subtitle', this.item?.contact?.full_name
          || `Rôle et permissions #${this.item?.id || '?'}`,
        );
      }
      this.$emit('updateHead');
    }
  }

  updateItem(newItem: InstanceGrant) {
    this.syncItem(newItem);

    if (`${newItem.contact_id}` !== this.contactId) {
      if (newItem.contact_id === null) {
        if (this.$route.params.contactId !== 'new') {
          this.$router.replace({
            name: 'InstancesInstanceGrantsDialog',
            params: {
              id: String(this.id),
              contactId: 'new',
            },
          });
        }
        return;
      }

      if (this.$route.params.contactId !== String(newItem.contact_id)) {
        if (newItem.contact?.user?.id) {
          this.$router.replace({
            name: 'InstancesInstanceGrantsDialog',
            params: {
              id: String(this.id),
              contactId: String(newItem.contact_id),
              userId: String(newItem.contact.user.id),
            },
          });
          return;
        }

        this.$router.replace({
          name: 'InstancesInstanceGrantsDialog',
          params: {
            id: String(this.id),
            contactId: String(newItem.contact_id),
          },
        });
      }
    }
  }
}
