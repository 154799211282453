import { Module } from 'vuex';
import { RootState } from 'qs_vuetify/src/types/states';

import { VolunteersReportViewState } from '@/types/states';

export const volunteersReportView: Module<VolunteersReportViewState, RootState> = {
  namespaced: true,
  state: {
    filtersItem: {
      dates: [],
      instances: [],
    },
  },
  getters: {
    filtersItem(state) {
      return state.filtersItem;
    },
  },
  mutations: {
    filtersItem(state, filtersItem) {
      state.filtersItem = filtersItem;
    },
  },
};

export default volunteersReportView;
