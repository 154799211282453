




































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import OptionsLazyLoadable from 'qs_vuetify/src/mixins/OptionsLazyLoadable';

import BaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import RocketchatChannelsCard from '@/components/RocketchatChannelsCard.vue';

import {
  PersistedRocketchatChannel as Channel,
  Filter,
  PersistedRocketchatChannel,
} from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { DataTableOptions } from 'qs_vuetify/src/types/components';

const global: any = namespace('global');
const store: any = namespace('rocketchat_channels');
const view: any = namespace('rocketchatChannelsViews');

@Component({
  components: {
    BaseLayout,
    QsList,
    QsFilters,
    RocketchatChannelsCard,
  },
})
export default class RocketchatChannelsList extends mixins(
  AuthenticationMixin,
  OptionsLazyLoadable,
) {
  @global.Mutation addNotification!: any;

  @store.Getter data!: Array<Channel>;
  @store.Getter filters!: Array<Filter>;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter filtersLoaded!: boolean;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;
  @store.Action restore!: any;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedRocketchatChannel[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  setRocketchatChannelsParams = this.buildSetParam('rocketchat_channels', this.setParams);

  mounted() {
    this.setAction();
    this.$store.commit('rocketchat_channels/item', null);
    this.$store.commit('rocketchat_channels/contacts', []);
    this.$store.dispatch('components/tags');
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      rocketchat_channels: {
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  setAction() {
    if (this.userHas('ROCKETCHAT_CHANNELS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/rocketchat_channels/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
  }

  @Watch('selectedFilterIndex')
  onSelectedFilterIndexChanged(selectedFilterIndex: number) {
    this.resetData();
    this.resetFilters();

    if (selectedFilterIndex > 1) {
      this.setParams({
        ...this.params,
        ...this.filters[selectedFilterIndex - 2].filter,
      });
    }

    this.loadFirstPage();
  }

  resetFilters = () => {
    this.setParams({
      fields: '*,users_count',
      order: 'name',
      per_page: 20,
    });
  }

  async restoreItem(item: Channel) {
    const i = this.data.indexOf(item);
    const data = [
      ...this.data,
    ];
    data[i].loading = true;
    this.$store.commit('rocketchat_channels/data', data);
    try {
      await this.restore(item.id);

      this.addNotification({
        color: 'success',
        message: 'Canal restauré',
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de la restauration du canal',
      });
      this.loadFirstPage();
    }
  }
}
