var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        "content-class": "instance-grants-form",
        height: "80vh",
        value: true,
        "max-width": "100%"
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm.item && _vm.isNew
                  ? _c("span", [_vm._v("Créer des droits et permissions")])
                  : _vm.item && !_vm.isNew
                  ? _c("span", [_vm._v("Modifier les rôles et permissions")])
                  : _c("span", [_vm._v("Chargement...")])
              ]
            },
            proxy: true
          },
          _vm.instance && _vm.itemReady
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm.isNew
                      ? _c("span", [_vm._v(_vm._s(_vm.instance.name))])
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm.item.contact.full_name) +
                              " | " +
                              _vm._s(_vm.instance.name)
                          )
                        ])
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.routeDataLoaded
        ? _c(
            "div",
            { staticClass: "instance-grants-form__content" },
            [
              _vm.item
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("qs-form-builder", {
                            attrs: {
                              disabled: !_vm.isNew,
                              "model-name": _vm.slug,
                              form: _vm.grantForm,
                              item: _vm.item,
                              error: _vm.error,
                              order: _vm.formOrder
                            },
                            on: { input: _vm.updateItem },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "contact_id.item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("qs-contact-list-item", {
                                        attrs: { item: item }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "contact_id.selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("qs-contact-list-item", {
                                        attrs: { item: item }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2143680124
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("grants-list", {
                            staticClass: "instance-grants-form__roles",
                            attrs: {
                              items: _vm.item.instance_roles,
                              "model-name": "instance_roles",
                              ready: !_vm.isNew || !!_vm.item.contact_id
                            },
                            on: {
                              "click:new": function($event) {
                                return _vm.goToNew("roles", $event)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button",
                                  fn: function() {
                                    return [
                                      !_vm.isNew || !!_vm.item.contact_id
                                        ? _c(
                                            "qs-split-button",
                                            {
                                              attrs: {
                                                theme: "labase",
                                                small: ""
                                              },
                                              on: {
                                                "click:main": function($event) {
                                                  return _vm.goToNew(
                                                    "roles",
                                                    null
                                                  )
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "main-button",
                                                    fn: function() {
                                                      return [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-plus")
                                                        ])
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                2445379101
                                              )
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.goToNew(
                                                            "roles",
                                                            "elue"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Élu·e ")]
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.goToNew(
                                                            "roles",
                                                            "electoral"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Comité électoral "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.goToNew(
                                                            "roles",
                                                            null
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Autre ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "active-list-item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " + _vm._s(item.type_name) + " "
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.responsibilities
                                                    .map(function(r) {
                                                      return r.name
                                                    })
                                                    .join(", ")
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-action-text", [
                                            _vm._v(
                                              " Depuis le " +
                                                _vm._s(
                                                  _vm._f("date")(item.start_at)
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("qs-button", {
                                            attrs: {
                                              color: "info",
                                              icon: "mdi-pencil",
                                              label: "Modifier le rôle",
                                              small: "",
                                              theme: "labase"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goTo(null, {
                                                  name:
                                                    "InstancesInstanceRolesDialog",
                                                  params: {
                                                    id: item.instance_id,
                                                    roleId: item.id
                                                  }
                                                })
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "inactive-list-item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " + _vm._s(item.type_name) + " "
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.responsibilities
                                                    .map(function(r) {
                                                      return r.name
                                                    })
                                                    .join(", ")
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-action-text", [
                                        _vm._v(
                                          " Du " +
                                            _vm._s(
                                              _vm._f("date")(item.start_at)
                                            ) +
                                            " au " +
                                            _vm._s(
                                              _vm._f("date")(item.end_at)
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              468428637
                            )
                          }),
                          _vm.instanceRolesLoading ||
                          _vm.instanceUsersLoading ||
                          _vm.loading
                            ? _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("grants-list", {
                            class: {
                              "instance-grants-form__users": true,
                              "instance-grants-form__users--disabled":
                                _vm.item &&
                                _vm.item.contact &&
                                !_vm.item.contact.email
                            },
                            attrs: {
                              items: _vm.item.instance_users,
                              "model-name": "instance_users",
                              ready: !_vm.isNew || !!_vm.item.contact_id
                            },
                            on: {
                              "click:new": function($event) {
                                return _vm.goToNew("users", $event)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button",
                                  fn: function() {
                                    return [
                                      !_vm.isNew || !!_vm.item.contact_id
                                        ? _c(
                                            "qs-button",
                                            {
                                              attrs: {
                                                theme: "labase",
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.goToNew("users")
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "active-list-item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("v-list-item-content", [
                                        _c(
                                          "div",
                                          _vm._l(
                                            _vm.hasRightGroups(item.rights),
                                            function(group, index) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: "group_" + index,
                                                  attrs: {
                                                    color: "qs-light-blue",
                                                    "x-small": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(group) + " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ]),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-action-text", [
                                            _vm._v(
                                              " Depuis le " +
                                                _vm._s(
                                                  _vm._f("date")(item.start_at)
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("qs-button", {
                                            attrs: {
                                              color: "info",
                                              icon: "mdi-pencil",
                                              label: "Modifier la permission",
                                              small: "",
                                              theme: "labase"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goTo(null, {
                                                  name: "InstanceUsersForm",
                                                  params: {
                                                    id: item.instance_id,
                                                    instanceUserId: item.id
                                                  }
                                                })
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "inactive-list-item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("v-list-item-content", [
                                        _c("div", [
                                          item.rights.length === 0
                                            ? _c("em", [
                                                _vm._v("Aucune permission")
                                              ])
                                            : _vm._e(),
                                          _c(
                                            "ul",
                                            _vm._l(
                                              _vm.hasRightGroups(item.rights),
                                              function(group, index) {
                                                return _c(
                                                  "li",
                                                  { key: "group_" + index },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(group) + " "
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-list-item-action-text", [
                                            _vm._v(
                                              " Du " +
                                                _vm._s(
                                                  _vm._f("date")(item.start_at)
                                                ) +
                                                " au " +
                                                _vm._s(
                                                  _vm._f("date")(item.end_at)
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3091720047
                            )
                          }),
                          _vm.item.contact && !_vm.item.contact.email
                            ? _c("v-alert", { attrs: { type: "warning" } }, [
                                _vm._v(
                                  " Ce contact n'a pas de courriel. Il faut un courriel valide pour avoir un compte d'utilisateur·trice, ce qui est un préalable à l'affectation de permissions. "
                                )
                              ])
                            : _vm._e(),
                          _vm.instanceUsersLoading || _vm.loading
                            ? _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }