






































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import OptionsLazyLoadable from 'qs_vuetify/src/mixins/OptionsLazyLoadable';

import BaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import PetitionsCard from '@/components/PetitionsCard.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import { PersistedPetition } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Watch } from 'vue-property-decorator';

const store: any = namespace('petitions');
const view: any = namespace('petitionsViews');

@Component({
  components: {
    BaseLayout,
    PetitionsCard,
    QsFilters,
    QsList,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Petitions extends mixins(
  AuthenticationMixin,
  ListMixin,
  OptionsLazyLoadable,
) {
  @store.Getter data!: Array<PersistedPetition>;
  @store.Getter error!: ErrorResponse;
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition | null;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedPetition[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  setPetitionsParams = this.buildSetParam('petitions', this.setParams);

  filtersOrder = [
    'tags.id',
    'instances.id',
    'created_at',
    'updated_at',
    'created_by_user.id',
    'updated_by_user.id',
  ];

  get viewParams(): { [key: string]: RestParams } {
    return {
      petitions: ListMixin.buildListState(this.options, this.params),
    };
  }

  mounted() {
    this.setAction();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
    this.reloadDataRoutesData(['petitions']);
  }

  setAction() {
    if (this.userHas('PETITIONS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push({ name: 'NewPetitionDialog' });
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }
}
