var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { value: true },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.itemReady
                ? _c("span", [_vm._v(_vm._s(_vm.$store.state.global.subtitle))])
                : _c("span", [_vm._v("Chargement...")])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: { large: "", loading: _vm.loading, theme: "labase" },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.itemReady
        ? _c("qs-form-builder", {
            attrs: {
              "model-name": _vm.slug,
              form: _vm.form,
              item: _vm.item,
              error: _vm.error
            },
            on: { input: _vm.syncItem }
          })
        : _c("v-skeleton-loader", {
            attrs: { type: "card-heading, image", tile: "" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }