var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "instance-role-types-form",
        dark: false,
        height: "60vh",
        "max-width": "800",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.itemReady
                ? _c("span", [_vm._v(_vm._s(_vm.$store.state.global.subtitle))])
                : _c("span", [_vm._v("Chargement...")])
            ]
          },
          proxy: true
        },
        {
          key: "prepend",
          fn: function() {
            return [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", [_vm._v("Utilisateur")]),
                  _c("v-tab", [_vm._v("Permissions")])
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    color: "primary",
                    disabled:
                      (!_vm.isNew && !_vm.hasChanged) ||
                      !_vm.routeDataLoaded ||
                      _vm.loading,
                    large: "",
                    loading: _vm.loading,
                    theme: "labase"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.itemReady
        ? _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                [
                  _vm.item.created_at
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "body-2 mt-2",
                          attrs: {
                            border: "left",
                            color: "qs-light-blue lighten-1",
                            tile: ""
                          }
                        },
                        [
                          _vm._v(
                            " Utilisateur actif depuis le " +
                              _vm._s(_vm._f("date")(_vm.item.created_at)) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("qs-form-builder", {
                    attrs: {
                      error: _vm.error,
                      form: _vm.form,
                      item: _vm.item,
                      "model-name": _vm.slug,
                      order: _vm.formOrder
                    },
                    on: { input: _vm.syncItem },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "contact_id.item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("qs-contact-list-item", {
                                attrs: { item: item }
                              })
                            ]
                          }
                        },
                        {
                          key: "contact_id.selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("qs-contact-list-item", {
                                attrs: { item: item }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2143680124
                    )
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _vm.item && _vm.item.active_instance_users.length < 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column align-center justify-center",
                          staticStyle: { height: "50vh" }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-playlist-remove")]),
                          _c("span", { staticClass: "body-1" }, [
                            _vm._v("Aucune permission active")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-expansion-panels",
                    { attrs: { flat: "", tile: "" } },
                    _vm._l(_vm.item.active_instance_users, function(
                      instance_user
                    ) {
                      return _c(
                        "v-expansion-panel",
                        { key: instance_user.id },
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("qs-button", {
                                  attrs: {
                                    color: "error",
                                    disabled: _vm.isInstanceUserLoading(
                                      instance_user
                                    ),
                                    icon: "mdi-archive",
                                    label: "Mettre fin aux permissions",
                                    loading: _vm.isInstanceUserLoading(
                                      instance_user
                                    ),
                                    small: "",
                                    theme: "labase"
                                  },
                                  on: {
                                    click: [
                                      function($event) {
                                        return _vm.endRights(instance_user)
                                      },
                                      function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                      }
                                    ]
                                  }
                                }),
                                _c("qs-button", {
                                  attrs: {
                                    color: "info",
                                    icon: "mdi-eye",
                                    label: "Consulter le détail",
                                    small: "",
                                    theme: "labase"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "InstanceUsersForm",
                                        params: {
                                          id: instance_user.instance.id,
                                          instanceUserId: instance_user.id
                                        }
                                      })
                                    }
                                  }
                                }),
                                _c("div", [
                                  _c("span", { staticClass: "body-1" }, [
                                    _vm._v(_vm._s(instance_user.instance.name))
                                  ]),
                                  _c("br"),
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      " Du " +
                                        _vm._s(
                                          _vm._f("date")(instance_user.start_at)
                                        ) +
                                        " au " +
                                        _vm._s(
                                          _vm._f("date")(instance_user.end_at)
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          ]),
                          _c("v-expansion-panel-content", [
                            _c("pre", [
                              _vm._v(_vm._s(instance_user.rights_slugs))
                            ])
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "50vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }