


































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCombinedRightsTable from '@/components/QsCombinedRightsTable.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { RightGroup, Right } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('right_groups');
const rights: any = namespace('rights');
const view: any = namespace('rightGroupsViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsCombinedRightsTable,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class RightGroupDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter item!: RightGroup;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @rights.Getter('data') rights!: Array<Right>;

  @Prop([String, Number]) id!: string | number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;


  mounted() {
    this.onRouteChanged();
  }

  closeModal() {
    this.$router.push({ name: 'RightGroups' });
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      right_groups: ListMixin.buildListState(this.options, this.params),
    };
  }

  @Watch('item.name')
  onItemNameChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
    this.setAction();
  }

  setAction() {
    if (this.userHas('RIGHT_GROUPS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/instance_role_types/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit('global/subtitle', this.item?.name || 'Nouveau groupe de droits');
    } else {
      this.$store.commit('global/subtitle', this.item?.name || `Groupe de droits #${this.item.id}`);
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    await this.submit();

    this.reloadDataRoutesData(['right_groups.index']);

    this.closeModal();
  }
}
