var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "instance-roles-dialog",
        dark: false,
        height: "70vh",
        value: true,
        "max-width": "800"
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm.item && _vm.itemReady
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isNew ? "Ajouter un rôle" : "Modifier un rôle"
                        )
                      )
                    ])
                  : _c("span", [_vm._v("Chargement...")])
              ]
            },
            proxy: true
          },
          _vm.item && _vm.itemReady && !_vm.isNew
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _c("qs-boolean-indicator", {
                      staticClass: "mr-2",
                      attrs: { value: _vm.item.is_active }
                    }),
                    _vm._v(" " + _vm._s(_vm.item.contact_name) + " | "),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(
                        _vm._s(_vm.item.is_active ? "Actif" : "Inactif") +
                          " depuis le " +
                          _vm._s(
                            _vm._f("date")(
                              _vm.item.is_active
                                ? _vm.item.start_at
                                : _vm.item.end_at
                            )
                          )
                      )
                    ])
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "qs-button",
                  {
                    attrs: {
                      color: "primary",
                      depressed: "",
                      disabled: !_vm.itemReady || _vm.loading,
                      loading: _vm.loading,
                      tile: ""
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" Enregistrer ")]
                ),
                !_vm.isNew
                  ? _c(
                      "qs-button",
                      {
                        attrs: {
                          color: "error",
                          disabled: !_vm.itemReady || _vm.loading,
                          depressed: "",
                          loading: _vm.loading,
                          tile: ""
                        },
                        on: { click: _vm.endRole }
                      },
                      [_vm._v(" Mettre fin ")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.item && _vm.form && _vm.itemReady
        ? _c("instance-roles-form", {
            attrs: {
              error: _vm.error,
              form: _vm.form,
              item: _vm.item,
              loading: _vm.loading,
              "show-instance": !_vm.instance,
              slug: _vm.slug
            },
            on: { input: _vm.syncItem }
          })
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "50vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }