var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    {
      class: ["mr-2", "warning-indicator", "warning-indicator--" + _vm.color],
      attrs: { label: "", outlined: _vm.outlined }
    },
    [
      _c("v-icon", { staticClass: "mr-2" }, [_vm._v(_vm._s(_vm.icon))]),
      _vm._v(" " + _vm._s(_vm.text) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }