











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class WarningIndicator extends Vue {
  @Prop({ required: false, type: String, default: 'yellow' }) color?: string;
  @Prop({ required: false, type: String, default: 'mdi-alert-outline' }) icon?: string;
  @Prop({ required: false, type: Boolean, default: false }) outlined!: boolean;
  @Prop({ required: true, type: String }) text!: string;
}
