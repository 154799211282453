
























































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import { PersistedUser } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsActionModal,
    QsButton,
  },
})
export default class QsNewDuplicateModal extends QsActionModal {
  @Prop({ required: true, type: Array }) items!: PersistedUser[];
  @Prop({ required: true }) target!: PersistedUser | null;

  agreed = false;

  emitSave() {
    this.agreed = false;
    this.$emit('click:save');
  }
}
