var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    attrs: { "content-class": "rocketchat-channels-form" },
    scopedSlots: _vm._u([
      {
        key: "navigation",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                staticClass: "mr-4",
                attrs: {
                  color: "primary",
                  icon: "",
                  link: "",
                  tile: "",
                  to: { name: "RocketchatChannelsList" }
                }
              },
              [
                _c("v-icon", { attrs: { large: "" } }, [
                  _vm._v("mdi-menu-left")
                ])
              ],
              1
            ),
            _c("span", { staticClass: "text-h6" }, [
              _vm._v("Retour à la liste")
            ])
          ]
        },
        proxy: true
      },
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "qs-card",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.item ? "#" + _vm.item.slug : "") +
                            " "
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm.item
                  ? _c("qs-form-builder", {
                      attrs: {
                        error: _vm.error,
                        form: _vm.form,
                        item: _vm.item,
                        "model-name": _vm.slug,
                        order: _vm.formOrder
                      },
                      on: { input: _vm.syncItem }
                    })
                  : _vm._e(),
                _c(
                  "qs-button",
                  {
                    attrs: { block: "", theme: "labase" },
                    on: {
                      click: function($event) {
                        _vm.filtersModal = true
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { small: "" } },
                      [_vm._v("mdi-filter")]
                    ),
                    _vm._v(" Modifier le filtre ")
                  ],
                  1
                ),
                _c(
                  "v-alert",
                  {
                    staticClass: "mt-4 caption",
                    attrs: {
                      border: "left",
                      icon: "mdi-information",
                      outlined: "",
                      tile: ""
                    }
                  },
                  [
                    _vm._v(
                      " Les participant·es aux canaux sont déterminés par le filtre et sont synchronisés quotidiennement avec Rocket Chat. "
                    )
                  ]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-list", {
              attrs: {
                "hide-selection": "",
                items: _vm.contactsData,
                loading: _vm.contactsLoading,
                "model-name": "contacts",
                theme: "labase",
                total: _vm.contactsTotal
              },
              on: { load: _vm.loadNextPage },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "text-h6" }, [
                        _vm._v("Aperçu des contacts")
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-card", {
                        attrs: { item: item, "show-checked": false }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm.item
              ? _c("qs-filter-modal", {
                  attrs: {
                    repository: "Contact",
                    filter: { filter: _vm.item.filter },
                    value: _vm.filtersModal
                  },
                  on: {
                    "update:value": function($event) {
                      _vm.filtersModal = $event
                    },
                    cancel: _vm.resetFilters,
                    input: function($event) {
                      _vm.filtersModal = $event
                    },
                    apply: _vm.updateItemFilter
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }