var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    scopedSlots: _vm._u([
      {
        key: "navigation",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                staticClass: "mr-4",
                attrs: {
                  color: "primary",
                  icon: "",
                  link: "",
                  tile: "",
                  to: { name: "Petitions" }
                }
              },
              [
                _c("v-icon", { attrs: { large: "" } }, [
                  _vm._v("mdi-menu-left")
                ])
              ],
              1
            ),
            _c("span", { staticClass: "text-h6" }, [
              _vm._v("Retour à la liste")
            ])
          ]
        },
        proxy: true
      },
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "qs-card",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Configuration ")]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _vm.item
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-card-text",
                          [
                            _c("qs-form-builder", {
                              attrs: {
                                error: _vm.error,
                                form: _vm.form,
                                item: _vm.item,
                                "model-name": _vm.slug,
                                order: ["name", "slug", "newmode"]
                              },
                              on: { input: _vm.syncItem }
                            })
                          ],
                          1
                        ),
                        _vm.item.image
                          ? _c(
                              "v-card-text",
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "body-2 qs-blue--text" },
                                      [_vm._v(" Image ")]
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "qs-orange--text",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function($event) {
                                            return _vm.syncItem(
                                              Object.assign({}, _vm.item, {
                                                image: null
                                              })
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Supprimer l'image ")]
                                    )
                                  ],
                                  1
                                ),
                                _c("v-img", {
                                  attrs: {
                                    "aspect-ratio": "1.9047",
                                    src: _vm.item.image.sizes.thumbnail
                                  }
                                })
                              ],
                              1
                            )
                          : _vm.itemReady
                          ? _c(
                              "v-card-text",
                              { staticClass: "mb-4" },
                              [
                                _c("v-file-input", {
                                  attrs: { "hide-details": "", label: "Image" },
                                  model: {
                                    value: _vm.file,
                                    callback: function($$v) {
                                      _vm.file = $$v
                                    },
                                    expression: "file"
                                  }
                                }),
                                _c(
                                  "qs-button",
                                  {
                                    attrs: {
                                      block: "",
                                      disabled: !_vm.file,
                                      theme: "labase"
                                    },
                                    on: { click: _vm.uploadImage }
                                  },
                                  [_vm._v(" Envoyer l'image ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-card-text",
                          [
                            _vm._l(_vm.item.tags, function(tag) {
                              return _c("qs-tag-chip", {
                                key: tag.id,
                                attrs: {
                                  close: !_vm.removePetitionTagLoading,
                                  disabled:
                                    _vm.removingPetitionTagId === tag.id,
                                  name: tag.name
                                },
                                on: {
                                  "click:close": function($event) {
                                    return _vm.removePetitionTag(tag)
                                  }
                                }
                              })
                            }),
                            _vm.item.tags.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-center align-center"
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "qs-red" } },
                                      [_vm._v("mdi-tag-off")]
                                    ),
                                    _vm._v(" Aucune étiquette ")
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("qs-tags-autocomplete", {
                              attrs: {
                                "allow-create": "",
                                filled: "",
                                loading:
                                  _vm.tagsLoading || _vm.addPetitionTagLoading
                              },
                              on: {
                                created: function($event) {
                                  return _vm.reloadDataRoutesData(
                                    ["tags"],
                                    true
                                  )
                                },
                                input: _vm.addPetitionTag
                              }
                            })
                          ],
                          2
                        ),
                        _c(
                          "v-card-text",
                          [
                            _vm._l(_vm.item.instances, function(instance) {
                              return _c(
                                "v-chip",
                                {
                                  key: instance.id,
                                  staticClass: "mb-1 mr-1",
                                  attrs: {
                                    color: "qs-green",
                                    close: "",
                                    dark: "",
                                    label: ""
                                  },
                                  on: {
                                    "click:close": function($event) {
                                      return _vm.removePetitionInstance(
                                        instance
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(instance.name) + " ")]
                              )
                            }),
                            !_vm.item.instances.length || _vm.instancesLoading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-center align-center"
                                  },
                                  [
                                    _vm.instancesLoading
                                      ? [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              color: "primary",
                                              indeterminate: ""
                                            }
                                          })
                                        ]
                                      : [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "qs-light-blue" }
                                            },
                                            [_vm._v("mdi-crosshairs-off")]
                                          ),
                                          _vm._v(" Aucune instance ")
                                        ]
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c("qs-relation-field", {
                              attrs: {
                                filled: "",
                                item: { instance: _vm.selectedInstance },
                                label: "Ajouter une instance",
                                name: "instance_id_id",
                                query: _vm.instanceQueryDefinition
                              },
                              on: { input: _vm.addPetitionInstance },
                              model: {
                                value: _vm.selectedInstance,
                                callback: function($$v) {
                                  _vm.selectedInstance = $$v
                                },
                                expression: "selectedInstance"
                              }
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _c("v-card-text", [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-center align-center",
                          staticStyle: { height: "20vh" }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { color: "primary", indeterminate: "" }
                          })
                        ],
                        1
                      )
                    ])
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _vm.item
              ? _c(
                  "qs-card",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v(" Composition ")]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      418649893
                    )
                  },
                  [
                    _c("qs-form-builder", {
                      attrs: {
                        form: _vm.contentForm,
                        item: _vm.item.content,
                        "model-name": _vm.slug,
                        order: ["title", "body", "callToAction", "buttonLabel"]
                      },
                      on: {
                        input: function($event) {
                          return _vm.syncItem(
                            Object.assign({}, _vm.item, { content: $event })
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function(ref) {
                              var item = ref.item
                              var update = ref.update
                              return [
                                _c("qs-html-editor", {
                                  attrs: { value: item.body },
                                  on: {
                                    input: function($event) {
                                      return update("body", $event)
                                    }
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "secondaryCallsToAction.title",
                            fn: function(ref) {
                              var item = ref.item
                              var index = ref.index
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.title
                                        ? item.title
                                        : "Appel à l'action #" + (index + 1)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3688028799
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }