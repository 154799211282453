var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "right-groups-form",
        dark: false,
        height: "80vh",
        "max-width": "800",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.itemReady
                ? _c("span", [_vm._v(_vm._s(_vm.$store.state.global.subtitle))])
                : _c("span", [_vm._v("Chargement...")])
            ]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$store.state.global.title || _vm.$route.meta.title
                  ) +
                  " "
              )
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    disabled:
                      (!_vm.isNew && !_vm.hasChanged) || !_vm.routeDataLoaded,
                    loading: _vm.loading,
                    large: "",
                    theme: "labase"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.itemReady
        ? _c(
            "v-form",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Nom" },
                        model: {
                          value: _vm.item["name"],
                          callback: function($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item['name']"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Description" },
                        model: {
                          value: _vm.item["description"],
                          callback: function($$v) {
                            _vm.$set(_vm.item, "description", $$v)
                          },
                          expression: "item['description']"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.item.rights.length) +
                                    " droits "
                                )
                              ]),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c("qs-combined-rights-table", {
                                    attrs: { rights: _vm.rights },
                                    model: {
                                      value: _vm.item["rights"],
                                      callback: function($$v) {
                                        _vm.$set(_vm.item, "rights", $$v)
                                      },
                                      expression: "item['rights']"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-skeleton-loader", {
            attrs: { type: "card-heading, image", tile: "" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }