









































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';

import { Form } from 'qs_vuetify/src/types/components';

import axios from 'qs_vuetify/src/plugins/axios';

@Component({
  components: {
    QsActionModal,
    QsFormBuilder,
  },
})
export default class VolunteersMassImportDialog extends mixins(ErrorMixin) {
  @Prop({ required: true, type: [String, Number] }) instanceId!: string | number;
  @Prop({ required: true, type: Boolean }) value!: boolean;

  data = {
    emails: '',
    end_at: null,
  };

  errors: string[] = [];
  description = 'Un courriel par ligne.';
  loading = false;

  get formValid(): boolean {
    if (!this.data.emails || !this.data.end_at) {
      return false;
    }

    return this.data.emails.split('\n').reduce((acc, e) => acc
      && (this.$helpers.isValidEmail(e) || e === ''), true);
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(val: boolean) {
    this.$emit('input', val);
  }

  get volunteersMassImportForm(): Form {
    return {
      message_1: {
        description: `Un rôle de militant sera créé pour chacune des adresses courriels ci-dessous. Assurez-vous
        que la personne a un compte sur La Base.`,
        type: 'paragraph',
      },
      emails: {
        label: 'Courriels',
        description: this.description,
        rules: {
          custom: (value: string) => {
            if (!value) {
              return true;
            }

            const parts = value.split('\n');
            const index: Record<string, boolean> = {};

            if (parts.length > 0) {
              for (let i = 0, len = parts.length; i < len; i += 1) {
                if (!this.$helpers.isValidEmail(parts[i].trim()) && parts[i].trim().length > 0) {
                  if (i !== 0 || parts[i].length > 5) {
                    return `Courriel invalide. ${this.description}`;
                  }
                }

                if (index[parts[i]]) {
                  return `Courriel en double. ${this.description}`;
                }

                index[parts[i]] = true;
              }
            }

            return true;
          },
        },
        type: 'textarea',
      },
      message_2: {
        class: 'mb-2',
        description: `Ce rôle de militant·e expirera à cette date. Pour gagner du temps,
        configurez la date de fin de permission par défaut dans la modale de paramètres.`,
        type: 'paragraph',
      },
      end_at: {
        label: 'Date de fin',
        rules: {
          required: true,
        },
        type: 'datetime',
      },
    };
  }

  async createVolunteers() {
    this.errors = [];
    this.loading = true;

    try {
      const emails = this.data.emails.split('\n').map((e) => e.trim());
      await Promise.all(emails.map(async (email) => {
        const { data: { data } } = await axios.get('users', {
          params: {
            email,
          },
        });

        if (data.length === 1) {
          await axios.post('instance_roles', {
            start_at: this.$dayjs().subtract(1, 'hour'),
            end_at: this.data.end_at,
            instance_role_type_id: 6,
            contact_id: data[0].id,
            instance_id: this.instanceId,
          });
        } else if (data.length === 0) {
          this.errors.push(`${email} introuvable.`);
        } else if (data.length > 1) {
          this.errors.push(`${email} ambigu: ajoutez ce·tte militant·e manuellement.`);
        }
      }));

      if (this.errors.length === 0) {
        this.visible = false;

        this.data.emails = '';

        if (this.$store.state.instancesView.defaultEndAt) {
          this.data.end_at = this.$store.state.instancesView.defaultEndAt;
        } else {
          this.data.end_at = null;
        }
      }
    } finally {
      this.loading = false;
    }
  }

  @Watch('visible')
  onVolunteersMassImportDialogVisibleChanged() {
    if (this.$store.state.instancesView.defaultEndAt
      && this.$dayjs(this.$store.state.instancesView.defaultEndAt).isAfter(this.$dayjs())) {
      this.data.end_at = this.$store.state.instancesView.defaultEndAt;
    } else {
      this.data.end_at = null;
    }
  }
}
