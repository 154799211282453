


























































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { InstanceEvent, PersistedInstanceEventType } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('instance_events');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceEventsDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter item!: InstanceEvent;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @Prop([String, Number]) id!: string | number;
  @Prop([String, Number]) eventId!: string | number;
  @Prop([String, Number]) section!: string | number;

  modalVisibility = false;

  mounted() {
    this.onRouteChanged();
    this.modalVisibility = true;
  }

  closeModal() {
    this.modalVisibility = false;
  }

  validate(): boolean {
    return (this.$refs.form as QsFormBuilder).validate();
  }

  get instanceEventType(): PersistedInstanceEventType | null {
    if (this.item.instance_event_type_id) {
      return this.$store.state.instance_event_types.data
        .find((t: PersistedInstanceEventType) => t.id === this.item.instance_event_type_id);
    }

    return null;
  }

  get instanceEventTypeHasForm(): boolean {
    if (this.instanceEventType && this.instanceEventType.form_definition) {
      return Object.keys(this.instanceEventType.form_definition).length > 0;
    }

    return false;
  }

  get itemReady(): boolean {
    if (this.eventId === 'new') {
      return !!this.item;
    }

    if (typeof this.eventId === 'string') {
      return this.item?.id === parseInt(this.eventId, 10);
    }

    return this.item?.id === this.eventId;
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      instance_events: {
        fields: '*,created_by_user.contact.full_name,instance_event_type.name',
        instance_id: this.id,
        order: '-date',
      },
    };
  }

  @Watch('item.instance_event_type_id')
  onInstanceEventTypeIdChanged() {
    this.syncItem({
      ...this.item,
      meta: {},
    });
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('itemReady')
  onItemReady(ready: boolean) {
    if (ready) {
      this.item.instance_id = parseInt(this.$route?.params?.id, 10);
      delete this.item.instance_event_type;
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalTitle();
  }

  @Watch('modalVisibility')
  onModalVisibilityChange(newModalVisibiliy: boolean) {
    if (!newModalVisibiliy) {
      this.$store.commit(`${this.slug}/item`, null);
      this.$router.push(`/instances/${this.$route?.params?.id || ''}/events`);
    }
  }

  setGlobalTitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit('global/subtitle', "Nouveau suivi d'instance");
    } else {
      this.$store.commit('global/subtitle', `Suivi d'instance ${this.item.id}`);
    }
    this.$emit('updateHead');
  }

  syncMeta(meta: any) {
    this.syncItem({
      ...this.item,
      meta,
    });
  }

  async submitForm() {
    if ((this.$refs.form as QsFormBuilder).validate()) {
      this.syncItem({
        ...this.item,
        instance_id: this.id,
      });

      await this.submit();

      this.reloadDataRoutesData(['instance_events.index'], true);

      this.closeModal();
    }
  }
}
