import {
  ActionTree,
  Module,
} from 'vuex';

import axios from 'qs_vuetify/src/plugins/axios';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import { InstanceUser } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { InstanceUsersRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const InstanceUsersState: InstanceUsersRestState = buildRestState<InstanceUser>('instance_users', {});

export const getters = buildRestGetters<InstanceUser, InstanceUsersRestState>();

export const actions: ActionTree<InstanceUsersRestState, RootState> = {
  ...buildRestActions<InstanceUser, InstanceUsersRestState>(),
  archive({ commit, state }, { id, instance_id: instanceId }: { id: number; instance_id: number }) {
    const ajax = axios.put(`${state.path}/${id}`, {
      id,
      instance_id: instanceId,
      end_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    }).finally(() => commit('ajax', null));
    commit('ajax', ajax);
    return ajax;
  },
};

export const mutations = buildRestMutations<InstanceUser, InstanceUsersRestState>();

export const instanceUsers: Module<InstanceUsersRestState, RootState> = {
  namespaced: true,
  state: InstanceUsersState,
  getters,
  actions,
  mutations,
};
