var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "3" } },
        [
          _vm.instanceUsersFiltersDefinition
            ? _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.instanceUsersParams,
                  dense: "",
                  "filters-definition": _vm.instanceUsersFiltersDefinition,
                  "model-name": "instance_users",
                  theme: "labase"
                },
                on: { input: _vm.updateFilters }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pl-1", attrs: { cols: "12", md: "9" } },
        [
          _c("qs-data-table", {
            attrs: {
              headers: _vm.tableHeaders,
              items: _vm.instanceUsers,
              loading: !_vm.routeDataLoaded || _vm.instanceUsersLoading,
              options: _vm.instanceUsersOptions,
              "set-options": _vm.setInstanceUsersOptions,
              total: _vm.instanceUsersTotal || 0
            },
            on: {
              edit: function($event) {
                return _vm.$emit("edit", $event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item.start_at",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.start_at)) + " ")
                  ]
                }
              },
              {
                key: "item.end_at",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.end_at)) + " ")
                  ]
                }
              },
              {
                key: "item.is_active",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("qs-boolean-indicator", {
                      attrs: { value: item.is_active }
                    })
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("edit", item)
                          }
                        }
                      },
                      [_vm._v(" mdi-pencil ")]
                    ),
                    item.is_active
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.emitArchive(item)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v(" mdi-archive ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_vm._v(" Mettre fin ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }