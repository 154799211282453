












































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import {
  InstanceEvent,
} from 'qs_vuetify/src/types/models';

import QsInstanceStatsCard from '@/components/QsInstanceStatsCard.vue';

const instances: any = namespace('instances');

@Component({
  components: {
    QsInstanceStatsCard,
  },
})
export default class InstancesForm extends Vue {
  @instances.Getter stats!: any;
  @instances.Getter statsLoaded!: boolean;

  get lastStatsEvent() {
    const validEvents = this.stats.last_events
      .filter((event: InstanceEvent) => event.meta?.contacts)
      .filter((event: InstanceEvent) => this.$dayjs(event.created_at) < this.$dayjs().subtract(1, 'day'));

    if (validEvents.length > 0) {
      return validEvents[0];
    }

    return null;
  }
}
