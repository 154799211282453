import axios from 'qs_vuetify/src/plugins/axios';
import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';
import { RocketchatChannel } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { RocketchatChannelsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

export const rocketchatChannelsState: RocketchatChannelsRestState = {
  ...buildRestState<RocketchatChannel>('rocketchat_channels', {}),
  contacts: [],
  contactsLoading: false,
  contactsTotal: null,
  destroying: false,
  filters: [],
  filtersLoaded: false,
  syncing: false,
};

export const getters: GetterTree<RocketchatChannelsRestState, RootState> = {
  ...buildRestGetters<RocketchatChannel, RocketchatChannelsRestState>(),
  contacts(state) {
    return state.contacts;
  },
  contactsLoading(state) {
    return state.contactsLoading;
  },
  contactsTotal(state) {
    return state.contactsTotal;
  },
  destroying(state) {
    return state.destroying;
  },
  filters(state) {
    return state.filters;
  },
  filtersLoaded(state) {
    return state.filtersLoaded;
  },
  syncing(state) {
    return state.syncing;
  },
};

export const actions: ActionTree<RocketchatChannelsRestState, RootState> = {
  ...buildRestActions<RocketchatChannel, RocketchatChannelsRestState>(),
  async contacts({ commit }, params) {
    commit('contactsLoading', true);

    const ajax = axios.get('/contacts', {
      params: {
        ...params,
      },
    });

    const {
      data: {
        data: contacts,
        total,
      },
    } = await ajax;

    commit('contacts', contacts);
    commit('contactsTotal', total);
    commit('contactsLoading', false);
  },
  async contactsLoadPage({ commit, state }, params) {
    commit('contactsLoading', true);

    const ajax = axios.get('/contacts', {
      params: {
        ...params,
      },
    });

    const {
      data: {
        data: contacts,
        total,
      },
    } = await ajax;

    commit('contacts', [
      ...state.contacts,
      ...contacts,
    ]);
    commit('contactsTotal', total);
    commit('contactsLoading', false);
  },
  async filters({ commit }, params) {
    commit('filtersLoaded', false);

    const ajax = axios.get('/filters', {
      params: {
        for: 'lists',
        per_page: '*',
        repository: 'RocketchatChannel',
        ...params,
      },
    });

    const {
      data: {
        data: filters,
      },
    } = await ajax;

    commit('filters', filters);
    commit('filtersLoaded', true);
  },
  async syncChannel({ commit }, id: number) {
    commit('setSyncing', true);

    try {
      await axios.put(`/rocketchat_channels/${id}/sync`);
    } catch (e) {
      Promise.reject(e);
    }

    commit('setSyncing', false);
  },
};

export const mutations: MutationTree<RocketchatChannelsRestState> = {
  ...buildRestMutations<RocketchatChannel, RocketchatChannelsRestState>(),
  contacts(state, contacts) {
    state.contacts = contacts;
  },
  contactsLoading(state, contactsLoading) {
    state.contactsLoading = contactsLoading;
  },
  contactsTotal(state, contactsTotal) {
    state.contactsTotal = contactsTotal;
  },
  setDestroying(state, value) {
    state.destroying = value;
  },
  filtersLoaded(state, filtersLoaded) {
    state.filtersLoaded = filtersLoaded;
  },
  filters(state, filters) {
    state.filters = filters;
  },
  setSyncing(state, value) {
    state.syncing = value;
  },
};

export const rocketchatChannels: Module<RocketchatChannelsRestState, RootState> = {
  namespaced: true,
  state: rocketchatChannelsState,
  getters,
  actions,
  mutations,
};
